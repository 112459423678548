import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosSDKConfig";
import { TOAST_SHOW } from "../actions/toastActions";

import { SDK_GET_GAME_TOKEN_SUCCESS,SDK_GET_GAME_TOKEN_FAILED, SDK_INIT_JS_SUCCESS, SDK_INIT_JS_FAILED } from "../actions/gameSdkActions";
 
 
export function* fetchGameSdkTokenSaga(action) {
	try{
		const response = yield call(axios.post,`/api/encryptdata`, action.payload);

		if(response?.status ===200){ 
			yield put({ type:SDK_GET_GAME_TOKEN_SUCCESS, payload: response?.data, gameId:action.payload?.appId })
		}
	} catch (e) {
		yield put({ type:TOAST_SHOW,payload:{message:"Something went wrong please try again",severity: "Danger",show: true }});
		yield put({ type: SDK_GET_GAME_TOKEN_FAILED })
	}
}

export function* initJsSDKSaga(action) {
	try{
		const response = yield call(axios.post,`/api/initjs`, action.payload);

		if(response?.status ===200){ 
			yield put({ type:SDK_INIT_JS_SUCCESS, payload: response?.data, gameId:action.payload?.appId })
		}
	} catch (e) {
		yield put({ type:TOAST_SHOW,payload:{message:"Something went wrong please try again",severity: "Danger",show: true }});
		yield put({ type: SDK_INIT_JS_FAILED })
	}
}