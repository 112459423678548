import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import loadable from "@loadable/component";

import { LoginAction } from "../../../redux/actions/authActions";

const Loader = loadable(() => import("../../../components/UI/Loader"));

const DiscordCallback = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		const handleRedirect = async () => {
			const { access_token } = window.location.hash
				.substring(1).split("&")
				.reduce((acc, item) => {
					const [key, value] = item.split("=");
					acc[key] = value;
					return acc;
				}, {});
			
			if (access_token) {
				await axios()
				.get("https://discordapp.com/api/users/@me", {headers: { Authorization: `Bearer ${access_token}` }})
				.then((userInfo) => {
					let payload = { profileImage: "",name: userInfo?.data?.global_name,verifier: "Discord",typeOfLogin: "akedo_social",verfierId: userInfo?.data?.id,email: userInfo?.user?.email,loginType: "discord" }; 
					dispatch(LoginAction(payload));
					navigate("/login");
				});
			}else{
				navigate("/login");
			}
		}
		handleRedirect();
	}, [dispatch, navigate]);

	return <Loader isTranserent={false} />;
};

export default React.memo(DiscordCallback);

