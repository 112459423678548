import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosConfig";
import { HOMEPAGE_DATA_SUCCESS,HOMEPAGE_DATA_ERROR, GET_SECTION_GAMES_SUCCESS, GET_SECTION_GAMES_ERROR} from "../actions/homeActions";
import { TOAST_SHOW } from "../actions/toastActions";


export function* loadHomepageDataSaga(action) {
  try {
    const response = yield call(axios.get, "home"); 
    if (response?.status === 200) {
      yield put({type: HOMEPAGE_DATA_SUCCESS,payload: response?.data});
    }
  } catch (e) {
    yield put({ type: TOAST_SHOW, payload: {message: "Something Went wrong please try again",severity: "Danger",show: true}});
    yield put({ type: HOMEPAGE_DATA_ERROR });
  }
}

export function* fetchSectionGamesSaga(action) {
  try { 
    const response = yield call(axios.post, `/section`, action.payload); 
    if (response?.status === 200) {  
      yield put({type: GET_SECTION_GAMES_SUCCESS,payload: response?.data, sectionId : action?.payload?.section_id});
    }
  } catch (e) {
    yield put({type: TOAST_SHOW,payload: {message: "Something Went wrong please try again",severity: "Danger",show: true}});
    yield put({ type: GET_SECTION_GAMES_ERROR });
  }
}
