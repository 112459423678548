import React from "react";
import { useSelector } from "react-redux";

import loadable from "@loadable/component";

const GameSection = loadable(() => import("../homepage/GameSection"));
const VideoBanner = loadable(() => import("../../components/VideoBanner"));
const CategoriesCarousel = loadable(() => import("../categories/CategoriesCarousel"));

const Dashboard = () => {
	const AuthState = useSelector((state) => state.Auth);
	const HomeState = useSelector((state) => state.Home);
	let isLoggedIn = AuthState?.user && AuthState?.token ? true : false;
  
  let bannerVideoDataNew;
	let lastCategoryIndex;
	let gameSectionsArr = HomeState?.sections ? HomeState?.sections : [];
	if (gameSectionsArr && gameSectionsArr.length > 0) {
		let lastCategoryData = gameSectionsArr[gameSectionsArr.length - 1];
		if (lastCategoryData) { lastCategoryIndex = lastCategoryData?.id; }
	}

	if (AuthState?.featured && AuthState?.featured.length > 0 ) { 
		bannerVideoDataNew = AuthState?.featured.filter( (el) => el?.app?.video !== "" ); 
	}

	return (
		<>
			<VideoBanner isLoggedIn={isLoggedIn} featured={bannerVideoDataNew} /> 
			{isLoggedIn && ( <GameSection key={`game_section_0`} sectionId={2} sectionTitle="Continue Playing" displayViewAll={false} /> )} 
			{gameSectionsArr && gameSectionsArr.length > 0 && gameSectionsArr.map((game, index) => <GameSection key={`game_section_${index + 1}`} sectionId={game.id} sectionTitle={game.title} displayViewAll={true} lastCategoryIndex={lastCategoryIndex} /> )} 
			<CategoriesCarousel />
		</>
	);
};

export default Dashboard;
