export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const RECENTLY_PLAYED_REQUEST = "RECENTLY_PLAYED_REQUEST";
export const RECENTLY_PLAYED_SUCCESS = "RECENTLY_PLAYED_SUCCESS";
export const RECENTLY_PLAYED_ERROR = "RECENTLY_PLAYED_ERROR";

export const vAKEDO_PRODUCTS_LIST_REQUEST = "vAKEDO_PRODUCTS_LIST_REQUEST";
export const vAKEDO_PRODUCTS_LIST_SUCCESS = "vAKEDO_PRODUCTS_LIST_SUCCESS";
export const vAKEDO_PRODUCTS_LIST_FAILED = "vAKEDO_PRODUCTS_LIST_FAILED";

export const PAYMENT_CHECKOUT_SESSION_REQUEST = 'PAYMENT_CHECKOUT_SESSION_REQUEST';
export const PAYMENT_CHECKOUT_SESSION_SUCCESS = 'PAYMENT_CHECKOUT_SESSION_SUCCESS';
export const PAYMENT_CHECKOUT_SESSION_FAILED = 'PAYMENT_CHECKOUT_SESSION_FAILED';

export const STRIPE_PAYMENT_CONFIRM_REQUEST = 'STRIPE_PAYMENT_CONFIRM_REQUEST';
export const STRIPE_PAYMENT_CONFIRM_SUCCESS = 'STRIPE_PAYMENT_CONFIRM_SUCCESS';
export const STRIPE_PAYMENT_CONFIRM_FAILED = 'STRIPE_PAYMENT_CONFIRM_FAILED';

export const UpdateUserAction = (data, callbackFun) => {
	return { type: UPDATE_USER_REQUEST, payload: data, callbackFun: callbackFun, };
};

export const fetchRecentlyPlayed = () => {
	return { type: RECENTLY_PLAYED_REQUEST };
};

export const fetchVAkedoProductsListAction = () => {
	return { type: vAKEDO_PRODUCTS_LIST_REQUEST  };
};

export const createCheckoutSessionAction = (data,callbackFun) =>{
	return { type: PAYMENT_CHECKOUT_SESSION_REQUEST, payload: data,callbackFun:callbackFun }; 
};

export const stripeCallbackAction = (data) => {
	return { type: STRIPE_PAYMENT_CONFIRM_REQUEST, payload: data };
};
