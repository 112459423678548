export const VERIFYTX_BNB_REQUEST = 'VERIFYTX_BNB_REQUEST';
export const VERIFYTX_BNB_SUCCESS = 'VERIFYTX_BNB_SUCCESS';
export const VERIFYTX_BNB_FAILED = 'VERIFYTX_BNB_FAILED';

export const VERIFYTX_MATIC_REQUEST = 'VERIFYTX_MATIC_REQUEST';
export const VERIFYTX_MATIC_SUCCESS = 'VERIFYTX_MATIC_SUCCESS';
export const VERIFYTX_MATIC_FAILED = 'VERIFYTX_MATIC_FAILED'; 

export const BNB_CHECK_BALANCE_REQUEST = 'BNB_CHECK_BALANCE_REQUEST';
export const BNB_CHECK_BALANCE_SUCCESS = 'BNB_CHECK_BALANCE_SUCCESS';
export const BNB_CHECK_BALANCE_FAILED = 'BNB_CHECK_BALANCE_FAILED';

export const MATIC_CHECK_BLANNCE_REQUEST = 'MATIC_CHECK_BLANNCE_REQUEST';
export const MATIC_CHECK_BLANNCE_SUCCESS = 'MATIC_CHECK_BLANNCE_SUCCESS';
export const MATIC_CHECK_BLANNCE_FAILED = 'MATIC_CHECK_BLANNCE_FAILED';

export const VERFIY_PURCHASE_REQUEST = 'VERFIY_PURCHASE_REQUEST';
export const VERFIY_PURCHASE_SUCCESS = 'VERFIY_PURCHASE_SUCCESS';
export const VERFIY_PURCHASE_FAILED = 'VERFIY_PURCHASE_FAILED';

export const CMC_CONVERSION_REQUEST = 'CMC_CONVERSION_REQUEST';
export const CMC_CONVERSION_SUCCESS = 'CMC_CONVERSION_SUCCESS';
export const CMC_CONVERSION_FAILED = 'CMC_CONVERSION_FAILED';


export const BNB_CONVERSION_REQUEST = 'BNB_CONVERSION_REQUEST';
export const BNB_CONVERSION_SUCCESS = 'BNB_CONVERSION_SUCCESS';
export const BNB_CONVERSION_FAILED = 'BNB_CONVERSION_FAILED';


export const PURCHASE_INTERVAL_VERIFY_REQUEST = 'PURCHASE_INTERVAL_VERIFY_REQUEST';
export const PURCHASE_INTERVAL_VERIFY_SUCCESS = 'PURCHASE_INTERVAL_VERIFY_SUCCESS';
export const PURCHASE_INTERVAL_VERIFY_FAILED = 'PURCHASE_INTERVAL_VERIFY_FAILED';


export const CLEAR_CONVERSION_DATA = 'CLEAR_CONVERSION_DATA'; 


export const verifyBNBTxAction = (data) =>{
    return { type: VERIFYTX_BNB_REQUEST, payload: data }
}

export const verifyMaticTxAction = (data) =>{
    return { type: VERIFYTX_MATIC_REQUEST, payload: data }
}

export const bnbCheckBalanceAction = (data) =>{
    return {type: BNB_CHECK_BALANCE_REQUEST, payload:data}
}

export const maticCheckBalanceAction = (data) =>{
    return {type: MATIC_CHECK_BLANNCE_REQUEST, payload:data}
}

export const purchaseVerifyAction = (data) =>{
    return { type:VERFIY_PURCHASE_REQUEST,payload: data }
}

export const cmcConversionAction = (data) =>{
    return { type:CMC_CONVERSION_REQUEST, payload:data }
}

export const bnbConversionAction = (data) =>{
    return { type:BNB_CONVERSION_REQUEST, payload:data }
}

export const purchaseIntervalVerifyAction = (data) =>{
    return { type: PURCHASE_INTERVAL_VERIFY_REQUEST, payload: data }
}

export const clearConversionData = () =>{
    return { type: CLEAR_CONVERSION_DATA }
}