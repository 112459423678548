import React, { useEffect, useRef } from "react";
import {
  //  colors,
   w3color 
} from "../../../utils/avatar_utils";

const createAvatar = (canvas, layerCode, width, height, source) => {
  if (canvas) {
    const context = canvas.getContext("2d");
    const allImages = [];
    const otherImages = [];
    const folders = [
      { parent: "ear", childs: ["1.png"] },
      { parent: "eye", childs: ["1.png", "2.png", "3.png", "4.png", "5.png"] },
      {
        parent: "eyebrow",
        childs: ["1.png", "2.png", "3.png", "4.png", "5.png"],
      },
      {
        parent: "hair",
        childs: [
          "1.png",
          "2.png",
          "3.png",
          "4.png",
          "5.png",
          "6.png",
          "7.png",
          "8.png",
          "9.png",
          "A.png",
          "B.png",
          "C.png",
          "D.png",
          "E.png",
          "F.png",
          "G.png",
        ],
      },
      {
        parent: "mouth",
        childs: [
          "1.png",
          "2.png",
          "3.png",
          "4.png",
          "5.png",
          "6.png",
          "7.png",
          "8.png",
          "9.png",
          "A.png",
        ],
      },
    ];

    let offsetY = 0; // Offset for the face image for assad
    if (source == '_assad') {
      offsetY = 3;
    }
 
    let parts = layerCode.split("_").slice(1); 

    /**START: Default Layercode set if - avatar code is old */
    if(parts && parts.length >= 7 ){
      layerCode = '#_e9967a-#_8b0000-4_ffd700-4_708090-5_8b008b-4_ffdead';
      parts = layerCode.split("_").slice(1);
    }
    /**END: Default Layercode set if - avatar code is old */

    let result = parts
      .map((part) => {
        let match = part.match(/(\w+-?\w*)/);
        return match ? match[1] : "";
      })
      .filter(Boolean)
      .map((item) => item.replace(/-$/, ""));

    // const getRandomElement = (arr) =>
    //     arr[Math.floor(Math.random() * arr.length)];
    // let randowColor = getRandomElement(colors);

    // Prepare base layers
    let baseColor1 = result[0];
    let baseColor2 = result[result.length - 1];
    const baseImages = [
      { src: `/avatars${source}/bg.png`, color: baseColor1 },
      { src: `/avatars${source}/face.png`, color: baseColor2 },
      { src: `/avatars${source}/ear/1.png`, color: baseColor2 },
    ];
    const defaultAssadUser = '/avatars/lion_user.jpeg'

    // Prepare other layers

    result?.map((layer, index) => {
      let color, fileName;
      
      // set default face in case it uses empty code
      if (source == '_assad' && index == 3 && !layer.includes("-")) {
        layer = layer + "-1";
      }
      if (source == '_assad' && index == 4) {
        if (layer.endsWith("-2") || layer.endsWith("-3")) {
          offsetY = 1;
        } else if (layer.endsWith("-4") || layer.endsWith("-5") || layer.endsWith("-6")) {
          offsetY = 4;
        }
      }      

      if (layer.includes("-")) {
        [color, fileName] = layer.split("-");
                
        const folder = folders[index];
        otherImages.push({
          src: `/avatars${source}/${folder?.parent}/${fileName}.png`,
          color,
        });
      }
      return layer;
    });
    const loadImage = (src, color) => {
      if (src && color) {
        return new Promise((resolve) => {
          const img = new Image();
          img.crossOrigin = "anonymous";
          img.src = src;
          img.onload = () => {
            // Apply color to image
            context.clearRect(0, 0, width, height);                        
            context.drawImage(img, 0, 0+offsetY, width, height+offsetY);
            if (color && source !== '_assad') {
              const w3Color = w3color("#" + color);
              //console.log("w3Color", w3Color);
              const imgData = context.getImageData(0, 0, width, height);
              const data = imgData.data;
              for (let i = 0; i < data.length; i += 4) {
                if (data[i + 3] > 0) {
                  // Check if the pixel is not transparent
                  data[i] = w3Color.red;
                  data[i + 1] = w3Color.green;
                  data[i + 2] = w3Color.blue;
                }
              }
              context.putImageData(imgData, 0, 0);
            }
            allImages.push(canvas.toDataURL("image/png", 1));
            resolve();
          };
        });
      }
    };

    function drawRoundRect(ctx, x, y, width, height, radius) {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
      
      // Fill the rectangle with white color
      ctx.fillStyle = 'white';
      ctx.fill();
    }

    (async () => {
      if (source === '_assad') {
          for (const { src, color } of otherImages) {
            await loadImage(src, color);
          }

          // Draw all images sequentially
          context.clearRect(0, 0, width, height);
          context.beginPath();
          drawRoundRect(context, 0, 0, width, height, 10);
          context.fillStyle = "white";
          context.fill();

          for (const imageData of allImages) {
            const img = new Image();
            img.src = imageData;
            await new Promise((resolve) => {
              img.onload = () => {
                context.drawImage(img, 0, 0, width, height);
                resolve();
              };
            });
          }
          
      } else {
        // Load and draw base layers first
        for (const { src, color } of baseImages) {
          await loadImage(src, color);
        }

        // Load and draw other layers
        for (const { src, color } of otherImages) {
          await loadImage(src, color);
        }

        // Draw all images sequentially
        context.clearRect(0, 0, width, height);
        context.beginPath();
        context.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2);
        // context.fillStyle = "blue"; // Change this to your preferred color
        context.fill();
        for (const imageData of allImages) {
          const img = new Image();
          img.src = imageData;
          await new Promise((resolve) => {
            img.onload = () => {
              context.drawImage(img, 0, 0, width, height);
              resolve();
            };
          });
        }
      }      
      
    })();
  }
};

const AvatarCanvas = ({
  onlyView,
  setSelectedAvatar,
  id,
  layerCode,
  width,
  height,
  className,
  source='',
}) => {
  const canvasRef = useRef(null); 

  useEffect(() => {
    createAvatar(canvasRef.current, layerCode, width, height, source);
  }, [layerCode, width, height]);

  return (
    <>
      {onlyView ? (
        <div className={className}>
          {" "}
          <canvas
            ref={canvasRef}
            id={id}
            width={width}
            height={height}
          ></canvas>
        </div>
      ) : (
        <div
          className={className}
          onClick={() => {
            setSelectedAvatar(layerCode);
          }}
        >
          {" "}
          <canvas
            ref={canvasRef}
            id={id}
            width={width}
            height={height}
          ></canvas>
        </div>
      )}
    </>
  );
};

export default AvatarCanvas;
