import { combineReducers } from "redux";

import { AuthReducer } from "./AuthReducer";
import { ToastReducer } from "./ToastReducer";
import { HomeReducer } from "./HomeReducer";
import { SettingsReducer } from "./SettingsReducer";
import { CategoryReducer } from "./CategoryReducer";
import { GameReducer } from "./GameReducer";
import { GameSdkReducer } from "./GameSdkReducer";
import { CryptoReducer } from "./CryptoReducer";
import { MessageReducer } from "./MessageReducer";
import { EventsReducer } from "./eventsReducer"
import { AssadMessageReducer } from "./assad_arcade/AssadMessageReducer"
import { AssadGameReducer } from "./assad_arcade/AssadGameReducer"

const rootReducer = combineReducers({
	Auth: AuthReducer,
	Home: HomeReducer,
	Toast: ToastReducer,
	Settings: SettingsReducer,
	Category: CategoryReducer,
	Game:GameReducer,
	GameSDK:GameSdkReducer,
	Crypto: CryptoReducer,
	Message: MessageReducer,
	Events: EventsReducer,
	AssadMessage: AssadMessageReducer,
	AssadGame: AssadGameReducer
});

export default rootReducer;
