import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"; 
import loadable from "@loadable/component";   
import { Helmet } from "react-helmet";

import "./App.css"; 
import AppRoutes from "./routes";
import MessagePopup from './components/UI/MessagePopup'

import { LoadHomDataAction } from "./redux/actions/homeActions";
import { LoginAction, LogoutAction } from "./redux/actions/authActions";
import { getAllCategoryAction } from "./redux/actions/categoryActions";
import { checkCookie, checkFunctionRateLimit } from "./utils/functions";

const Loader = loadable(() => import("./components/UI/Loader"));
const Toast = loadable(() => import("./components/UI/Toast"));
const EngagingLoader = loadable(() => import("./components/UI/EngagingLoader"));

const App = () => {
	const HomeState = useSelector((state) => state.Home);
	const AuthState = useSelector((state) => state.Auth);
	const ToastState = useSelector((state) => state.Toast);
	const MessageState = useSelector((state) => state.Message);

	const { showMessagePopup,popupMessage,userMessagesList } = MessageState;
	const { loaded: homeLoaded } = HomeState;
	const { loading: authLoading,token,user } = AuthState;
	const { open,message,severity } = ToastState;
	
	let isLoggedIn = Boolean(user && token); 
   
	const dispatch = useDispatch();  
	
	useEffect(() => { 
		const hash = window.location.hash.slice(1);
		const params = new URLSearchParams(hash);
		const accessToken = params.get('access_token');

		if (!checkFunctionRateLimit()) {
			return;
		}
		
		if (!homeLoaded && accessToken) { 
 			fetch(`https://discord.com/api/v9/users/@me?with_analytics_token=true`, { headers: { Authorization: `Bearer ${accessToken}` }})
			.then(response => response.json())
			.then(userInfo => {
				const payload = { profileImage: '', name: userInfo?.global_name, verifier: 'Discord', typeOfLogin: 'akedo_social', verifierId: userInfo?.id, email: userInfo?.email, loginType: 'discord',
				};				
				dispatch(LoginAction(payload));
			})
			.catch(error =>  console.log(error) );
		} else {
			dispatch(LoadHomDataAction());
			dispatch(getAllCategoryAction());
		}    
	}, [dispatch, homeLoaded, isLoggedIn]); 

	/**START: TO AUTO LOGOUT USER AFTER SPECIFIC TIME */
	useEffect(() => { 
		const sessionExists = checkCookie('sessionExists');
		const hasToken = localStorage.getItem('token'); 
		if (!sessionExists && hasToken) { 
			dispatch(LogoutAction()); 
		} 
	}, [dispatch]);
	/**END: TO AUTO LOGOUT USER AFTER SPECIFIC TIME */


	/**START: USED TO LOAD HOME PAGE AGAIN ON LOGIN LOGOUT */
	useEffect(()=>{
		dispatch(LoadHomDataAction());
	},[dispatch,token])
	/**END: USED TO LOAD HOME PAGE AGAIN ON LOGIN LOGOUT */
	
	useEffect(() =>{},[showMessagePopup]);  

	const structuredData = { "@context": "https://schema.org", "@type": "Games", "name": "Akedo Web Games", "url": "https://react.akedo.gg/", "logo": "https://react.akedo.gg/logo.png", "description": "Akedo | Ad-Free Web Games" };
	
	const checkTextInPathname=(pathstringtocheck)=> {
		return window.location.pathname.includes(pathstringtocheck);
	}
	
	return (
		<Suspense fallback={<>Loading...</>}>
		  	<Helmet> <script type="application/ld+json">{JSON.stringify(structuredData)}</script> </Helmet>
			{homeLoaded ? (
				<>
					{authLoading && <Loader isTranserent={true} />}
					<Toast show={open} message={message} severity={severity} /> 
					<AppRoutes /> 
					{showMessagePopup && popupMessage && <MessagePopup show={showMessagePopup} message={popupMessage} dayCount={userMessagesList? userMessagesList?.length:1} />}
				</>
			) : (
				/*<Loader isTranserent={false} />*/
				<EngagingLoader type="bubbles" color={checkTextInPathname('category-custom/')?`#F6D14B`:`#00e6f4`}  />
			)}
		</Suspense>
	);
};

export default App;