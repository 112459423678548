import {
	GET_CATEGORY_DETAIL_REQUEST,
	GET_CATEGORY_DETAIL_SUCCESS,
	GET_CATEGORY_DETAIL_ERROR,
	GET_ALL_CATEGORY_REQUEST,
	GET_ALL_CATEGORY_SUCCESS,
	GET_ALL_CATEGORY_ERROR,
	GET_CUSTOM_CATEGORY_DETAIL_REQUEST,
	GET_CUSTOM_CATEGORY_DETAIL_SUCCESS,
	GET_CUSTOM_CATEGORY_DETAIL_FAILED
} from "../actions/categoryActions";

const initialState = {
	loading: false,
	categoryViewDetails: {},
	load: false, //used for all category load request
	allCategoryData: [], //all category data.
	custom_category_loader:false,
	custom_category_data:{},
};

export const CategoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CATEGORY_DETAIL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_CATEGORY_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				categoryViewDetails: action?.payload,
			};
		case GET_CATEGORY_DETAIL_ERROR:
			return {
				...state,
				loading: false,
			};
		case GET_ALL_CATEGORY_REQUEST:
			return {
				...state,
				load: false,
			};
		case GET_ALL_CATEGORY_SUCCESS:
			return {
				...state,
				load: true,
				allCategoryData: action?.payload,
			};
		case GET_ALL_CATEGORY_ERROR:
			return {
				...state,
				load: false,
			};
		case GET_CUSTOM_CATEGORY_DETAIL_REQUEST:
			return {
				...state,
				custom_category_loader: true,
			};
		case GET_CUSTOM_CATEGORY_DETAIL_SUCCESS:
			return {
				...state,
				custom_category_loader: false,
				custom_category_data: action?.payload,
			};
		case GET_CUSTOM_CATEGORY_DETAIL_FAILED:
			return {
				...state,
				custom_category_loader: false,
				custom_category_data: {},
			};
		default:
			return state;
	}
};
