export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_FOUND = "GET_USER_FOUND";
export const GET_USER_NOT_FOUND = "GET_USER_NOT_FOUND";

export const TELEGRAM_SIGNIN_REQUEST = "TELEGRAM_SIGNIN_REQUEST";
export const TELEGRAM_SIGNIN_SUCCESS = "TELEGRAM_SIGNIN_SUCCESS";
export const TELEGRAM_SIGNIN_FAILED = "TELEGRAM_SIGNIN_FAILED";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const UPDATE_USER_AFTER_SETTING_REQUEST =
	"UPDATE_USER_AFTER_SETTING_REQUEST";
export const UPDATE_USER_AFTER_SETTING_SUCCESS =
	"UPDATE_USER_AFTER_SETTING_SUCCESS";
export const UPDATE_USER_AFTER_SETTING_ERROR =
	"UPDATE_USER_AFTER_SETTING_ERROR";


export const FETCH_BALANCE_REQUEST = "FETCH_BALANCE_REQUEST";
export const FETCH_BALANCE_SUCCESS = "FETCH_BALANCE_SUCCESS";
export const FETCH_BALANCE_FAILED = "FETCH_BALANCE_FAILED";

export const CONNECT_REQUEST = "CONNECT_REQUEST";
export const CONNECT_SUCCESS = "CONNECT_SUCCESS";
export const CONNECT_FAILED = "CONNECT_FAILED"; 

export const LoginAction = (data) => {
	return { type: GET_USER_REQUEST, payload: data };
};

export const LogoutAction = (successcallback) => {
	return { type: LOGOUT, successcallback: successcallback };
};

export const UpdateUserAuthAction = (data) => {
	return { type: UPDATE_USER_AFTER_SETTING_SUCCESS, payload: data };
};

export const fetchBalanceAuthAction = () => {
	return { type: FETCH_BALANCE_REQUEST };
};
 
export const ConnectAction = (data,callback) => {
	return { type: CONNECT_REQUEST, payload: data,successCallback:callback };
};

export const TelegramSignInAction = (data) => {
	return { type: TELEGRAM_SIGNIN_REQUEST,payload: data };
};