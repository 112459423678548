import React from "react";
import { Outlet } from "react-router-dom";
import loadable from "@loadable/component";

const Header = loadable(() => import("../components/Header"));
const Footer = loadable(() => import("../components/Footer"));

const AuthContainer = () => {
	return (
		<>
			<div className="layout_wrapper">
				<Header />
				<div className="panel_wrp">
					<Outlet />
				</div>
				<Footer />
			</div>
		</>
	);
};

export default AuthContainer;
