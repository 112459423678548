import { 
	GET_POPUP_MESSAGE_REQUEST,
	GET_POPUP_MESSAGE_FAILED,
	GET_POPUP_MESSAGE_SUCCESS,
	CLEAR_POPUP_MESSAGE_REQUEST,
	MESSAGE_READ_REQUEST,
	MESSAGE_READ_FAILED,
	MESSAGE_READ_SUCCESS
} from "../../actions/assad_arcade/AssadMessageActions";

const initialState = {
	loading: false,
	message: [],
	read_loading:false,
};

export const AssadMessageReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_POPUP_MESSAGE_REQUEST:
			return {
				...state,
				loading:true, 
			}
		case GET_POPUP_MESSAGE_SUCCESS:
			return {
				...state,
				loading:false, 
				message:action?.payload,
			}
		case GET_POPUP_MESSAGE_FAILED:
			return {
				...state,
				loading:false, 
				message:[]
			}
		case CLEAR_POPUP_MESSAGE_REQUEST:
			return {
				...state,
				loading: false,
				message: []
			};
		case MESSAGE_READ_REQUEST:
			return {
				...state,
				read_loading:true, 
			}
		case MESSAGE_READ_SUCCESS:
			return {
				...state,
				read_loading:false,
			};
		case MESSAGE_READ_FAILED:
			return {
				...state,
				read_loading:false,
			};
		default:
			return state;
	}
};
