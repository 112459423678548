import { call, put } from "redux-saga/effects";
import axios from "../../../config/axiosConfigAssad";

import {
	ADD_ASSAD_COIN_FAILED,
	ADD_ASSAD_COIN_SUCCESS,
} from "../../actions/assad_arcade/AssadGameAction";

export function* AddAssadCoinSaga(action) {
	try {
		const response = yield call(axios.post, `add_coins`, action.payload);

		if (response?.status === 200) {
			yield put({
                type: ADD_ASSAD_COIN_SUCCESS,
                payload: response?.data,
            });
		}
	} catch (e) {
		// yield put({
		// 	type: TOAST_SHOW,
		// 	payload: {
		// 		message: "Something went wrong, please try again",
		// 		severity: "danger",
		// 		show: true,
		// 	},
		// });
		yield put({ type: ADD_ASSAD_COIN_FAILED });
	}
}