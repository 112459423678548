import {
	GET_USER_FOUND,
	GET_USER_NOT_FOUND,
	GET_USER_REQUEST,
	LOGOUT_SUCCESS,
	LOGOUT,
	LOGOUT_ERROR,
	UPDATE_USER_AFTER_SETTING_SUCCESS,
	FETCH_BALANCE_SUCCESS,
	CONNECT_REQUEST,
	CONNECT_SUCCESS,
	CONNECT_FAILED,
	TELEGRAM_SIGNIN_REQUEST,
	TELEGRAM_SIGNIN_SUCCESS,
	TELEGRAM_SIGNIN_FAILED
} from "../actions/authActions";

import {
	HOMEPAGE_DATA_SUCCESS
} from "../actions/homeActions";

const initialState = {
	loading: false,
	user: {},
	token: "",
	loginDetails: {},
	featured: {},
	balance:0,
	web3Login:{},
	web3LoginLoading:false,
	isWeb3Login:false,
	sections:[]
};

export const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case HOMEPAGE_DATA_SUCCESS:
			return {
				...state,
				user: action?.payload?.user || state.user,
			};
		case GET_USER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_USER_FOUND:
			return {
				...state,
				user: action?.payload?.user,
				token: action?.token,
				loading: false,
				loginDetails: action?.payload,
				featured: action?.payload?.featured,
				sections: action?.payload?.sections,
				isWeb3Login:false,
			};
		case GET_USER_NOT_FOUND:
			return { ...initialState };
		case LOGOUT:
			return {
				...state,
				loading: true,
				isWeb3Login:false,
			};
		case LOGOUT_SUCCESS:
			localStorage.removeItem("token");
			localStorage.removeItem('web3-token');
			return {
				...state,
				loading: false,
				user: {},
				token: "",
				loginDetails: {},
				web3Login:{}
			};
		case LOGOUT_ERROR:
			return {
				...state,
				loading: false,
			};
		case UPDATE_USER_AFTER_SETTING_SUCCESS:
			let loginDetailsTemp = state.loginDetails;
			let userDetailsNew = action?.payload;
			let updatedUserDetailsObj = {
				...loginDetailsTemp,
				...userDetailsNew,
			};

			let userTemp = state.user;
			let updatedUserObj = {
				...userTemp,
				...userDetailsNew,
			};

			return {
				...state,
				user: updatedUserObj,
				loginDetails: updatedUserDetailsObj,
			};
		case FETCH_BALANCE_SUCCESS:
			let userBlncObj = state?.user;
			if(userBlncObj){
				userBlncObj.balance = action?.payload
			}
			return {
				...state,
				balance:action?.payload,
				user: userBlncObj
			};
		case CONNECT_REQUEST:
			return {
				...state,
				web3LoginLoading: true,
			};
		 
		case CONNECT_SUCCESS:
			localStorage.setItem('web3-token',true);
			return {
				...state,
				user: action?.payload,
				token: action?.payload?.accessToken,
				loading: false,
				loginDetails: action?.payload,
				featured: action?.payload?.featured,
				web3Login:action?.payload, 
				web3LoginLoading: false,
				isWeb3Login:true,
			};
		case CONNECT_FAILED:
			return {
				...state,
				web3Login:{}, 
				web3LoginLoading: false,
				isWeb3Login:false,
			};
		case TELEGRAM_SIGNIN_REQUEST:
			return {
				...state,
				loading: true,
			};
		case TELEGRAM_SIGNIN_SUCCESS:
			return {
				...state,
				user: action?.payload?.user,
				token: action?.token,
				loading: false,
				loginDetails: action?.payload,
				featured: action?.payload?.featured,
				sections: action?.payload?.sections,
				isWeb3Login:false,
			};
		case TELEGRAM_SIGNIN_FAILED:
			return { ...initialState };
		default:
			return state;
	}
};
