import React, { useEffect, useState } from "react";
//import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
///import { 
	//useWeb3Modal,
	//useWeb3ModalEvents,
	//useWeb3ModalState 
//} from "@web3modal/wagmi/react";
import { useAccount } from 'wagmi';
import loadable from '@loadable/component';

import googleLogo from "../../assets/images/google.png";
import facebookLogo from "../../assets/images/facebookLogo.webp";
import discordLogo from "../../assets/images/socialMediaIcons/discordLogo.png";
import coinbaseLogo from "../../assets/images/coinbaseLogosvg.svg";
import trustLogo from "../../assets/images/trustLogo.png";
import walletConnectlogo from "../../assets/images/walletConnect.png";
import bannerBgImg from "../../assets/images/login_bg.jpg";
import closeIcon from "../../assets/images/x_close.png"

import { ConnectAction } from "../../redux/actions/authActions";
import { LoadHomDataAction } from "../../redux/actions/homeActions";

const LoginOptions = loadable(() => import('./LoginOptions'));

const Login = () => {
	const [activeWallet,setActiveWallet] = useState('');
	const AuthState = useSelector((state) => state.Auth); 
	//let w3m = useAppKit();
	//const events = useWeb3ModalEvents();
	//const state = useWeb3ModalState();  

	const { address,
		 //isConnecting, isDisconnected 
	} = useAccount(); 
	//const {selectedNetworkId} = state; 
	const { user:authUser,token} = AuthState;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logSocial = [
		{ icon: googleLogo, title: "Google", type: "google" },
		{ icon: facebookLogo, title: "Facebook", type: "facebook" },
		{ icon: discordLogo, title: "Discord", type: "discord" },
	];

	const logWeb3 = [
		{ icon: coinbaseLogo, title: "Coinbase", type: "coinbase"},
		{ icon: trustLogo, title: "Trust", type: "trust"},
		{ icon: walletConnectlogo, title: "WalletConnect", type: "walletconnnect"},
	];

	const onCancelLogin = () =>  navigate(-1) ;

	useEffect(() => {
		if (authUser && token) { navigate("/"); } 
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [authUser,token, navigate]); 

	useEffect(()=>{
		let web3Token = localStorage.getItem('web3-token');
		if (address && !web3Token) {  
			let payload = { "token":"","wallet":address,"wallet_type":activeWallet,"extra":{} }
			const successCallback =()=>{
				dispatch(LoadHomDataAction());
				navigate("/");
			}
			dispatch(ConnectAction(payload,successCallback));
		}
	},[address,dispatch,navigate,activeWallet])

	// console.log(`log1----> temp-For-WEB3-testing state`,JSON.stringify(state, null, 2),JSON.stringify(events, null, 2));  
	// console.log(`log1----> temp-For-WEB3 isConnecting,isDisconnected,address`,isConnecting,isDisconnected,address); 

	return (
		<div className="loginPage flex items-center justify-center min-h-screen" style={{backgroundImage: `url(${bannerBgImg})`,backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center"}} > 
			<button className="closePopup fixed text-[#00e6f4] text-3xl left-5 top-2.5" onClick={onCancelLogin}>  <img src={closeIcon} alt="cancelIcon" width={42} height={42}  /> </button>  
			<div className="loginContent max-w-[820px] w-full">
				<h2 className="text-[44px] text-[#00e6f4] text-center login-content-title" style={{ marginTop: window.innerWidth <= 768 ? '60px' :'20px',marginBottom:'2px'}}>Play Ad-Free Games</h2>  
				{/* {selectedNetworkId && <button onClick={()=>{ w3m.open({ view: 'Account'   }) }}>open</button>} */}
				<div className="loginList text-[#fff]  md:flex block justify-between">
					<LoginOptions loginOptionsArray={logSocial} title={"Log in with your Socials"} />
					<LoginOptions loginOptionsArray={logWeb3} title={"Log in with WEB3"} setActiveWallet={setActiveWallet} />
				</div>
			</div>
		</div>
	);
};

export default React.memo(Login);
