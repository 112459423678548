export const CALL_EVENT_REQUEST = "CALL_EVENT_REQUEST";
export const CALL_EVENT_SUCCESS = "CALL_EVENT_SUCCESS";
export const CALL_EVENT_ERROR = "CALL_EVENT_ERROR";


export const FETCH_GAME_DATA_REQUEST = "FETCH_GAME_DATA_REQUEST";
export const FETCH_GAME_DATA_SUCCESS = "FETCH_GAME_DATA_SUCCESS";
export const FETCH_GAME_DATA_ERROR = "FETCH_GAME_DATA_ERROR";


export const GET_LEADERBOARD_REQUEST = "GET_LEADERBOARD_REQUEST";
export const GET_LEADERBOARD_SUCCESS = "GET_LEADERBOARD_SUCCESS";
export const GET_LEADERBOARD_ERROR = "GET_LEADERBOARD_ERROR";


export const GET_DEV_DETAIL_REQUEST = "GET_DEV_DETAIL_REQUEST";
export const GET_DEV_DETAIL_SUCCESS = "GET_DEV_DETAIL_SUCCESS";
export const GET_DEV_DETAIL_FAILED = "GET_DEV_DETAIL_FAILED";


export const GAME_RATING_REQUEST = "GAME_RATING_REQUEST";
export const GAME_RATING_SUCCESS = "GAME_RATING_SUCCESS";
export const GAME_RATING_FAILED = "GAME_RATING_FAILED";


export const GET_ACHIEVEMENTS_REQUEST = "GET_ACHIEVEMENTS_REQUEST";
export const GET_ACHIEVEMENTS_SUCCESS = "GET_ACHIEVEMENTS_SUCCESS";
export const GET_ACHIEVEMENTS_FAILED = "GET_ACHIEVEMENTS_FAILED";

export const FETCH_BALANCE_REQUEST = "FETCH_BALANCE_REQUEST";
export const FETCH_BALANCE_SUCCESS = "FETCH_BALANCE_SUCCESS";
export const FETCH_BALANCE_FAILED = "FETCH_BALANCE_FAILED";

export const CHECK_GAME_PARTICIPATION_REQUEST = "CHECK_GAME_PARTICIPATION_REQUEST";
export const CHECK_GAME_PARTICIPATION_SUCCESS = "CHECK_GAME_PARTICIPATION_SUCCESS";
export const CHECK_GAME_PARTICIPATION_FAILED = "CHECK_GAME_PARTICIPATION_FAILED";

export const GET_APP_BY_TAG_REQUEST = "GET_APP_BY_TAG_REQUEST";
export const GET_APP_BY_TAG_SUCCESS = "GET_APP_BY_TAG_SUCCESS";
export const GET_APP_BY_TAG_FAILED = "GET_APP_BY_TAG_FAILED";


export const GET_APP_PRODUCT_DETAIL_REQUEST = "GET_APP_PRODUCT_DETAIL_REQUEST";
export const GET_APP_PRODUCT_DETAIL_SUCCESS = "GET_APP_PRODUCT_DETAIL_SUCCESS";
export const GET_APP_PRODUCT_DETAIL_FAILED = "GET_APP_PRODUCT_DETAIL_FAILED"; 


export const GET_APP_PRODUCTS_REQUEST = "GET_APP_PRODUCTS_REQUEST";
export const GET_APP_PRODUCTS_SUCCESS = "GET_APP_PRODUCTS_SUCCESS";
export const GET_APP_PRODUCTS_FAILED = "GET_APP_PRODUCTS_FAILED";

export const GET_APP_USERRATED_DETAIL_REQUEST = 'GET_APP_USERRATED_DETAIL_REQUEST';
export const GET_APP_USERRATED_DETAIL_SUCCESS = 'GET_APP_USERRATED_DETAIL_SUCCESS';
export const GET_APP_USERRATED_DETAIL_FAILED = 'GET_APP_USERRATED_DETAIL_FAILED';

export const GAME_PURCHASE_REQUEST = "GAME_PURCHASE_REQUEST";
export const GAME_PURCHASE_SUCCESS = "GAME_PURCHASE_SUCCESS";
export const GAME_PURCHASE_FAILED = "GAME_PURCHASE_FAILED";

export const GAME_SCORE_SUBSTRACT_REQUEST = "GAME_SCORE_SUBSTRACT_REQUEST";
export const GAME_SCORE_SUBSTRACT_SUCCESS = "GAME_SCORE_SUBSTRACT_SUCCESS";
export const GAME_SCORE_SUBSTRACT_FAILED = "GAME_SCORE_SUBSTRACT_FAILED";

export const requestEventAction = (data,callback) => {
	return { type: CALL_EVENT_REQUEST, payload: data,callback };
}; 

export const fetchGameAppDataAction = (data,callback) => {
	return { type: FETCH_GAME_DATA_REQUEST, payload: data,callback };
};

export const fetchLeaderboardAction = (data) => {
	return { type: GET_LEADERBOARD_REQUEST, payload: data };
};

export const getDevDetailsAction = (data) => {
	return { type: GET_DEV_DETAIL_REQUEST, payload: data };
};

export const appRatingAction = (data,callbackFun) => {
	return { type: GAME_RATING_REQUEST, payload: data,callbackFun:callbackFun };
};

export const getAchievementsAction = (data) => {
	return { type: GET_ACHIEVEMENTS_REQUEST, payload: data };
};
  
export const fetchBalanceAction = () => {
 	return { type: FETCH_BALANCE_REQUEST };
}; 

export const checkGameParticipationAction = (data,callbackFun,blockParticipationCallbackFun) => {
	return { type: CHECK_GAME_PARTICIPATION_REQUEST, payload: data , callbackFun,blockParticipationCallbackFun };
};

export const fetchGameByTagAction = (data) => { 
	return { type: GET_APP_BY_TAG_REQUEST, payload: data };
};

export const getAppProductDetailAction = (data) => {
	return { type: GET_APP_PRODUCT_DETAIL_REQUEST, payload: data };
};

export const getAppProductsAction = (data) => {
	return { type: GET_APP_PRODUCTS_REQUEST, payload: data };
};

export const getGameUserRatedDetailAction = (data) => {
	return { type: GET_APP_USERRATED_DETAIL_REQUEST, payload: data };
};

export const gamePurchaseAction = (data) => {
	return { type: GAME_PURCHASE_REQUEST, payload: data };
};

export const substractScoreAction = (data) => {
	return { type: GAME_SCORE_SUBSTRACT_REQUEST, payload: data };
};