export const chunkArray = (array, chunkSize) => {
	// Validate input
	if (
		!Array.isArray(array) ||
		!Number.isInteger(chunkSize) ||
		chunkSize <= 0
	) {
		throw new Error(
			"Invalid input. Please provide a valid array and a positive chunk size."
		);
	}

	const result = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize));
	}

	return result;
};

export const getCloudFrontSourcePath = () => {
	return import.meta.env.VITE_APP_ENV === "development" ? `https://d161n2kbw2fw8g.cloudfront.net`: `https://d1n2mqts4lqg92.cloudfront.net` ; 
};

export const getCategoryDetailsById = (category_id, categoryArr = [], isFullObj = false) => {
	const category = categoryArr.find(cat => cat.id === category_id);
	return category ? (isFullObj ? category : category.name) : (isFullObj ? {} : "-");
}

export const formatGameDownloadNumber=(value)=> {
	if (value >= 1000) {
	  const suffixes = ['', 'k', 'M', 'B', 'T']; // Add more as needed
	  const suffixNum = Math.floor(('' + value).length / 3);
	  const shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2));
	  return shortValue + suffixes[suffixNum];
	}
	return value; // Return the original number if it's less than 1000
}

export const calculateAverageRatingStar = (ratings = [],isFixed = 0,withStat = false) => {
	let reviews = [];
	ratings.forEach((x, index) => {
		if (x) {
			for (let i = 1; i <= x; i++) {
				reviews.push({ id: reviews.length + 1, rating: index + 1 });
			}
		}
	});

	if (reviews && reviews.length > 0) {
		let averageRating =
			reviews.reduce((sum, review) => sum + review.rating, 0) /
			reviews.length;

		if (!withStat) {
			return Math.round(averageRating).toFixed(isFixed);
		}
		let ratingTableData = [
			{
				rating: 1,
				perentage: Math.round((ratings[0] * 100) / reviews.length, 2),
			},
			{
				rating: 2,
				perentage: Math.round((ratings[1] * 100) / reviews.length, 2),
			},
			{
				rating: 3,
				perentage: Math.round((ratings[2] * 100) / reviews.length, 2),
			},
			{
				rating: 4,
				perentage: Math.round((ratings[3] * 100) / reviews.length, 2),
			},
			{
				rating: 5,
				perentage: Math.round((ratings[4] * 100) / reviews.length, 2),
			},
		];

		let finalData = {
			averageRating: Math.round(averageRating).toFixed(isFixed),
			ratingStat: ratingTableData,
		};

		return finalData;
	} else {
		if (!withStat) {
			return 0;
		}else{
			let ratingTableData = [
				{
					rating: 1,
					perentage: 0,
				},
				{
					rating: 2,
					perentage: 0,
				},
				{
					rating: 3,
					perentage: 0,
				},
				{
					rating: 4,
					perentage: 0,
				},
				{
					rating: 5,
					perentage: 0,
				},
			];
			let finalData = {
				averageRating: Math.round(0).toFixed(isFixed),
				ratingStat: ratingTableData,
			};
			return finalData;

		}
	}
};



export const getDateDifference = (startDate, endDate)=> {
	// Convert both dates to milliseconds
	const startMillis = startDate.getTime();
	const endMillis = endDate.getTime();
  
	// Calculate the difference in milliseconds
	const diffMillis = Math.abs(endMillis - startMillis);
  
	// Calculate difference in days
	const daysDiff = Math.floor(diffMillis / (1000 * 60 * 60 * 24));
  
	// Calculate difference in weeks
	const weeksDiff = Math.floor(daysDiff / 7);
  
	// Calculate difference in minutes
	const minutesDiff = Math.floor(diffMillis / (1000 * 60));
  
	// Calculate difference in months
	const monthsDiff = Math.floor(daysDiff / 30); // Assuming a month has 30 days
  
	// Calculate difference in years
	const yearsDiff = Math.floor(daysDiff / 365); // Assuming a year has 365 days
  
	return {
	  days: daysDiff,
	  weeks: weeksDiff,
	  minutes: minutesDiff,
	  months: monthsDiff,
	  years: yearsDiff
	};
  }  



export const setCookie = (name, value, expirationTime)=> {
    const expires = "expires=" + expirationTime.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const removeCookie =(name)=> {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

//USED TO CHECK COOKIE EXISTS OR NOT.
export const checkCookie =(name)=>{
	// Split the document.cookie string into individual cookies
	const cookies = document.cookie.split(';');
  
	// Iterate through the cookies to find the one with the specified name
	for (let cookie of cookies) {
		// Trim any leading or trailing spaces from the cookie string
		cookie = cookie.trim();
		// Check if the cookie starts with the specified name followed by "="
		if (cookie.startsWith(name + '=')) {
			return true; // Cookie found
		}
	}
  
	return false; // Cookie not found
}

export const formatDate =(dateString)=>{
    // Parse the given string into a Date object
    const date = new Date(dateString);

    // Extract date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Construct the formatted date string
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export const formatCmpctNumber=(number)=>{
	const usformatter = Intl.NumberFormat("en-US", { notation: "compact",compactDisplay: "short"});
	return usformatter.format(number);
}

export function getPlayCountText(playedCount) {
	if (playedCount < 100) {
	  return "<100";
	} else if (playedCount < 1000) {
	  return "1K+";
	} else if (playedCount < 10000) {
	  return "10K+";
	} else if (playedCount < 100000) {
	  return "100K+";
	} else if (playedCount < 1000000) {
	  return "1M+";
	}
	const c = Math.floor(playedCount / 1000000);
	return c.toString() + "M+";
  }
  
const functionCallTimestamps = {};

/**
 * Utility function to check if the current function is called too often.
 * @param {number} timeout - The minimum time (in milliseconds) between successive calls.
 * @returns {boolean} - Returns `true` if the function should proceed, or `false` if it should be ignored.
 */
export function checkFunctionRateLimit(timeout = 1000) {
	const stack = new Error().stack.split("\n")[2].trim(); // Get the caller function information
	const functionName = stack.match(/at\s([^\s]+)/)?.[1] || "anonymous";

	const currentTime = Date.now();
	const lastCalledTime = functionCallTimestamps[functionName];

	if (lastCalledTime && currentTime - lastCalledTime < timeout) {
		// Function was called too recently
		return false;
	}

	// Update the timestamp for the function and allow execution
	functionCallTimestamps[functionName] = currentTime;
	return true;
}
  