import React, { useEffect } from "react";
import loadable from "@loadable/component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDevDetailsAction } from "../../../redux/actions/gameActions";
import { getCloudFrontSourcePath } from "../../../utils/functions";

import ic_siteImg from "../../../assets/images/ic_site.png";
import ic_emailImg from "../../../assets/images/ic_email.png";
import ic_privacyImg from "../../../assets/images/ic_privacy.png";
import ic_addressImg from "../../../assets/images/ic_address.png";
import gameImg1 from "../../../assets/images/games/samplegameImg.png";

const GameCard = loadable(() => import("../../../components/UI/GameCard"));
const Loader = loadable(() => import("../../../components/UI/Loader"));

const MoreFromDev = () => {
  const GameState = useSelector((state) => state.Game);
  const { moreFromGameDev, loading } = GameState;
  const dispatch = useDispatch();
  const { devId } = useParams();

  useEffect(() => {
    dispatch(getDevDetailsAction({ developer: devId }));
  }, [devId, dispatch]);

  let cloudFrontSourcePath = getCloudFrontSourcePath();

  return (
    <>
      {loading ? (
        <div style={{ minHeight: "70vh" }}>
          <Loader isTranserent={true} />
        </div>
      ) : (
        <>
          <section className="viewAllGame px-0 py-[30px]">
            <div className="container">
              <div className="text-center flex flex-col justify-center items-center p-[20px]">
                {moreFromGameDev?.developerInfo?.icon && (
                  <img
                    src={`${moreFromGameDev?.developerInfo?.icon}`}
                    alt="developerInfo"
                    width={70}
                    height={70}
                  />
                )}
                <h2 className="text-[#fff] text-[25px] w-100 ">
                  {moreFromGameDev?.developerInfo?.name}
                </h2>
              </div>

              <div className="gameCards">
                {moreFromGameDev?.apps &&
                  moreFromGameDev?.apps?.length > 0 &&
                  moreFromGameDev?.apps.map((val, index) => (
                    <GameCard
                      keyIndex={`game_id_${index}`}
                      img={
                        val?.icon
                          ? `${cloudFrontSourcePath}${val?.icon}`
                          : gameImg1
                      }
                      game={val}
                      key={`might_also_like_${index}`}
                    />
                  ))}
              </div>
            </div>
          </section>
          <section className="devDetailSection px-0 py-[30px]">
            <div className="container">
              <div className="devInfo flex flex-col gap-3">
                <h1 className="text-[#00e6f4] text-[24px]">
                  Developer <span className="text-white">Information</span>
                </h1>
                <h1 className="text-[#00e6f4] text-[17px]">
                  {moreFromGameDev?.developerInfo?.name}
                </h1>
                <h1 className="text-[#DADADA] text-[13px] text-semibold">
                  {moreFromGameDev?.developerInfo?.about}
                </h1>
              </div>
              <div className="devContactInfo flex flex-col gap-3 pt-3">
                <h1 className="text-white text-[18px]">Contacts</h1>
                <div className="devcontactItemParent">
                  <div className="devcontactItem flex flex-row gap-2 items-center">
                    <img
                      src={ic_siteImg}
                      alt="Website"
                      width={20}
                      height={20}
                    />
                    <h1 className="text-white text-[14px]">
                      {moreFromGameDev?.developerInfo?.website || "-"}
                    </h1>
                  </div>
                  <div className="devcontactItem flex flex-row gap-2 items-center">
                    <img
                      src={ic_privacyImg}
                      alt="Privacy"
                      width={20}
                      height={20}
                    />
                    <h1 className="text-white text-[14px]">
                      {moreFromGameDev?.developerInfo?.privacy || "-"}
                    </h1>
                  </div>
                  <div className="devcontactItem flex flex-row gap-2 items-center">
                    <img
                      src={ic_emailImg}
                      alt="Support"
                      width={20}
                      height={20}
                    />
                    <h1 className="text-white text-[14px]">
                      {moreFromGameDev?.developerInfo?.support || "-"}
                    </h1>
                  </div>
                  <div className="devcontactItem flex flex-row gap-2 items-center">
                    <img
                      src={ic_addressImg}
                      alt="Address"
                      width={20}
                      height={20}
                    />
                    <h1 className="text-white text-[14px]">
                      {moreFromGameDev?.developerInfo?.address || "-"}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MoreFromDev;
