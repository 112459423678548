import {
	HOMEPAGE_DATA_REQUEST,
	HOMEPAGE_DATA_SUCCESS,
	HOMEPAGE_DATA_ERROR,
	GET_SECTION_GAMES_REQUEST,
	GET_SECTION_GAMES_SUCCESS,
	GET_SECTION_GAMES_ERROR,
	ALL_CATEGORY_DATA_LOADED,
	HOMEPAGE_DATA_RESET
} from "../actions/homeActions";

const initialState = {
	loading: false,
	loaded: false, //To Check Home page Games listing data loaded or not.
	config: {},
	categoryList: [], //Game Category List Array for home page carousel only
	featured: [],
	sections: [], // To display games section wise on Home page
	activeSectionViewAll: [], //Used to display section games view all page games,
	allCategoryDataLoaded: false, // Used to restrict Categorywise API call multiple time
};

export const HomeReducer = (state = initialState, action) => {
	switch (action.type) {
		case HOMEPAGE_DATA_RESET:
			return {
				...state,
				loaded: false,
			};
		case HOMEPAGE_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case HOMEPAGE_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				categoryList: action?.payload?.categories,
				featured: action?.payload?.featured,
				sections: action?.payload?.sections,
				loaded: true,
				config: action?.payload?.config,
			};
		case HOMEPAGE_DATA_ERROR:
			return {
				...state,
				loading: false,
			};
		case GET_SECTION_GAMES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_SECTION_GAMES_SUCCESS:
			let sectionsItemArr = state?.activeSectionViewAll;
			let sectionDataIndex = sectionsItemArr.findIndex(
				(x) => x.id === Number(action?.sectionId)
			);
			if (sectionDataIndex && sectionDataIndex >= 0) {
				sectionsItemArr[sectionDataIndex] = action?.payload;
			} else {
				sectionsItemArr.push(action?.payload);
			}
			return {
				...state,
				loading: false,
				activeSectionViewAll: sectionsItemArr,
			};
		case GET_SECTION_GAMES_ERROR:
			return {
				...state,
				loading: false,
			};
		case ALL_CATEGORY_DATA_LOADED:
			return {
				...state,
				allCategoryDataLoaded: true,
			};
		default:
			return state;
	}
};
