import React,{ useCallback } from "react"; 
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

import { setUserMessageRead } from "../../redux/actions/messageActions";
import balanceIcon from "../../assets/images/ic_balance.png";
 
 
const MessagePopup = (props) => { 
	const { message,dayCount } = props;
	const dispatch = useDispatch(); 

	const onClose = useCallback(() => dispatch(setUserMessageRead({messageId: message?.id})), [dispatch, message?.id]);

	const extractNumber = (text) => { 
		const match = /\|(\d+)\s/.exec(text);
		return match ? parseInt(match[1], 10) : 0;
	}

	const renderDays = (day) => {
		let result;
		switch (day) {
			case 1:
				result = `${1}st Day`
				break;
				
			case 2:
				result = `${2}nd Day`
				break;
			case 3:
				result = `${3}rd Day`
				break;
			default:
				result = `${day}th Day`
				break;
		}

		return result;
	}

	const replaceText = (text) => text.replace(/\|([^|]+)\|/g, "|#####|");

	let points = extractNumber(message?.text);  
	let newMessage = replaceText(message?.text);
	newMessage = newMessage.replace('|#####|',`<span class="text-[#329fae]" >${points} vAkedo</span>`);	 
	newMessage = newMessage.replace('Earn',`<br/>Earn`);	 

	let extra = '';
	if (message?.type === 'Purchase') {
	} else {		
		extra = Array.from({ length: 5 }, (_, i) => (
			<li key={i} className={`${i + 1 === dayCount ? 'active' : ''}`}> 
				<span className="circle"><FaCheck /></span> {renderDays(i + 1)} 
			</li>
		));
	}	

	return (
		<>
			<Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered className="messageModel" >
				<div>
					<Modal.Body>
						<div>
							<button className="closeMessageModel" onClick={()=>onClose()}> <IoIosClose /> </button>
							<div className="text-center mb-2"> <img src={balanceIcon} alt="balanceIcon" className="d-inline-block"  /> </div>
							<h1>{message?.title}</h1>
							<p dangerouslySetInnerHTML={{ __html: newMessage }}></p>
							<ul>{extra}</ul>
						</div>
					</Modal.Body>
				</div>
			</Modal>
		</>
	);
};

export default MessagePopup;
