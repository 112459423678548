import React from 'react'
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';

const CustomCountdown = ({ date, type }) => {
    const { t } = useTranslation();

    const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <div className="flex justify-center gap-2 font-skia">
                {
                    type === "weekly" && (
                        <div className="h-[50px] w-[48px] rounded-lg p-[5px]" style={{ backgroundImage: `linear-gradient(0deg, #FCDF4C 8.63%, #EDCB40 11.44%, #D0A429 17.71%, #B98517 23.92%, #A96F0A 29.94%, #9F6203 35.72%, #9C5D00 40.95%, #A16305 45.34%, #B17315 50.99%, #CA8D2D 57.31%, #E6AA49 62.81%, #FCDF4C 76.52%, #CC9819 90.08%, #B57600 97.62%)` }}>
                            <div className="w-full h-full rounded-lg flex flex-col items-center justify-center text-[rgba(255,255,255,.6)] font-bold" style={{ backgroundImage: 'radial-gradient(70.77% 70.77% at 15.08% 6.94%, #353535 0%, #040404 100%)' }}>
                                <span className="text-white leading-none text-[18px]">{days}</span>
                                <span className="text-[10px]">{t('tournaments.days')}</span>
                            </div>
                        </div>
                    )
                }
                <div className="h-[50px] w-[48px] rounded-lg p-[5px]" style={{ backgroundImage: `linear-gradient(0deg, #FCDF4C 8.63%, #EDCB40 11.44%, #D0A429 17.71%, #B98517 23.92%, #A96F0A 29.94%, #9F6203 35.72%, #9C5D00 40.95%, #A16305 45.34%, #B17315 50.99%, #CA8D2D 57.31%, #E6AA49 62.81%, #FCDF4C 76.52%, #CC9819 90.08%, #B57600 97.62%)` }}>
                    <div className="w-full h-full rounded-lg flex flex-col items-center justify-center text-[rgba(255,255,255,.6)] font-bold" style={{ backgroundImage: 'radial-gradient(70.77% 70.77% at 15.08% 6.94%, #353535 0%, #040404 100%)' }}>
                        <span className="text-white leading-none text-[18px]">{hours}</span>
                        <span className="text-[10px]">{t('tournaments.hrs')}</span>
                    </div>
                </div>
                <div className="h-[50px] w-[48px] rounded-lg p-[5px]" style={{ backgroundImage: `linear-gradient(0deg, #FCDF4C 8.63%, #EDCB40 11.44%, #D0A429 17.71%, #B98517 23.92%, #A96F0A 29.94%, #9F6203 35.72%, #9C5D00 40.95%, #A16305 45.34%, #B17315 50.99%, #CA8D2D 57.31%, #E6AA49 62.81%, #FCDF4C 76.52%, #CC9819 90.08%, #B57600 97.62%)` }}>
                    <div className="w-full h-full rounded-lg flex flex-col items-center justify-center text-[rgba(255,255,255,.6)] font-bold" style={{ backgroundImage: 'radial-gradient(70.77% 70.77% at 15.08% 6.94%, #353535 0%, #040404 100%)' }}>
                        <span className="text-white leading-none text-[18px]">{minutes}</span>
                        <span className="text-[10px]">{t('tournaments.mins')}</span>
                    </div>
                </div>
                <div className="h-[50px] w-[48px] rounded-lg p-[5px]" style={{ backgroundImage: `linear-gradient(0deg, #FCDF4C 8.63%, #EDCB40 11.44%, #D0A429 17.71%, #B98517 23.92%, #A96F0A 29.94%, #9F6203 35.72%, #9C5D00 40.95%, #A16305 45.34%, #B17315 50.99%, #CA8D2D 57.31%, #E6AA49 62.81%, #FCDF4C 76.52%, #CC9819 90.08%, #B57600 97.62%)` }}>
                    <div className="w-full h-full rounded-lg flex flex-col items-center justify-center text-[rgba(255,255,255,.6)] font-bold" style={{ backgroundImage: 'radial-gradient(70.77% 70.77% at 15.08% 6.94%, #353535 0%, #040404 100%)' }}>
                        <span className="text-white leading-none text-[18px]">{seconds}</span>
                        <span className="text-[10px]">{t('tournaments.sec')}</span>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <Countdown
            // date={new Date('2024-09-25T00:00:00')}
            date={date}
            renderer={CountdownRenderer}
        />
    )
}

export default CustomCountdown