import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosConfig";
import { GET_ALL_CATEGORY_ERROR, GET_ALL_CATEGORY_SUCCESS, GET_CATEGORY_DETAIL_ERROR, GET_CATEGORY_DETAIL_SUCCESS,GET_CUSTOM_CATEGORY_DETAIL_SUCCESS,GET_CUSTOM_CATEGORY_DETAIL_FAILED } from "../actions/categoryActions";
import { TOAST_SHOW } from "../actions/toastActions";
import { GET_USER_FOUND } from "../actions/authActions";

export function* fetchCategoryDetails(action) {
	try {
		const response = yield call(axios.post, `api/category?t=${Date.now()}`, action.payload);

		if (response?.status === 200) {
			yield put({type: GET_CATEGORY_DETAIL_SUCCESS,payload: response?.data});
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again",severity: "Danger",show: true } });
		yield put({ type: GET_CATEGORY_DETAIL_ERROR });
	}
}

export function* fetchAllCategorySaga(action) {
	try {
		const response = yield call(axios.get, `api/categories?t=${Date.now()}`);

		if (response?.status === 200) {
			yield put({type: GET_ALL_CATEGORY_SUCCESS, payload: response?.data});
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger",show: true } });
		yield put({ type: GET_ALL_CATEGORY_ERROR });
	}
}


export function* getCustomCategoryDetailSaga(action) {
	try {
		const response = yield call(axios.post, `api/category?t=${Date.now()}`, action.payload);

		if (response?.status === 200) {
			yield put({type: GET_CUSTOM_CATEGORY_DETAIL_SUCCESS,payload: response?.data}); 
			if( response?.data?.user){ 
				let userObj =  { 
					user: response?.data?.user,
					token: localStorage.getItem("token"),
					loginDetails: response?.data?.user,
					featured: {},
					balance:response?.data?.user?.balance,
					web3Login:{},
					web3LoginLoading:false,
					isWeb3Login:false,
					sections:[]
				}
				yield put({
					type: GET_USER_FOUND,
					payload: userObj,
					token: localStorage.getItem("token"),
				});
			}
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again",severity: "Danger",show: true } });
		yield put({ type: GET_CUSTOM_CATEGORY_DETAIL_FAILED });
	}
}
