
import { confetti } from "@tsparticles/confetti";
// import dollarCoinImage from '../assets/images/confetti/dollarCoin.png';
// import friendsCoinImage from '../assets/images/confetti/friendsCoin.png';
import lionCoinImage from '../assets/images/confetti/lionCoin.png';
import tgLogoImage from '../assets/images/confetti/tgChannel.png';
import xSocialLogoImage from '../assets/images/confetti/xSocial.png';
// import aracdeCoinImage from '../assets/images/confetti/arcadeCoin.png';
// import friendsImage from '../assets/images/confetti/friends_noCoin.png';
// import aracdeImage from '../assets/images/confetti/arcade_noCoin.png';
import friendImage from '../assets/images/confetti/friend_noCoin.png';
import joystickImage from '../assets/images/confetti/joystick.png';
// import testCoin from '../assets/images/confetti/testcoin.png';




const fireConfetti = () => {
  confetti({
    zIndex: 999,
    spread: 360,
    ticks: 200,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: lionCoinImage,
        width: 32,
        height: 32,
      }],
    },
  });
}

const fireXsocialConfetti = () => {
  confetti({
    zIndex: 999,
    spread: 360,
    ticks: 200,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: lionCoinImage,
        width: 32,
        height: 32,
      },{
        src: xSocialLogoImage,
        width: 32,
        height: 32,
      }],
    },
  });
}

const fireTgChannelConfetti = () => {
  confetti({
    zIndex: 999,
    spread: 360,
    ticks: 200,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: lionCoinImage,
        width: 32,
        height: 32,
      },{
        src: tgLogoImage,
        width: 32,
        height: 32,
      }],
    },
  });
}

const fireFriendConfetti = () => {
  confetti({
    zIndex: 999,
    spread: 360,
    ticks: 200,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: lionCoinImage,
        width: 32,
        height: 32,
      },{
        src: friendImage,
        width: 32,
        height: 32,
      }],
    },
  });
}

const fireArcadeConfetti = () => {
  confetti({
    zIndex: 999,
    spread: 70,
    ticks: 150,
    gravity:0.5,
    decay: 0.94,
    startVelocity: 20,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: lionCoinImage,
        width:32,
      },{
        src: joystickImage,
        width: 82,
        height: 82,
      }],
    },
  });
}


export { fireConfetti , fireXsocialConfetti,fireTgChannelConfetti,fireFriendConfetti , fireArcadeConfetti };
