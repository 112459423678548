export const ARCADE_PLAY_CLICK = 'Arcade_Play_Click'
export const ARCADE_LEADERBOARD_CLICK = 'Arcade_Leaderboard_Click'
export const ARCADE_CAROUSEL_RARROW_CLICK = 'Arcade_Carousel_RArrow_Click'
export const ARCADE_CAROUSEL_LARROW_CLICK = 'Arcade_Carousel_LArrow_Click'
export const ARCADE_TRMNT_PLAY_CLICK = 'Arcade_TRMNT_Play_Click'
export const ARCADE_TRNMT_CAROUSEL_RARROW_CLICK = 'Arcade_TRNMT_Carousel_RArrow_Click'
export const ARCADE_TRNMT_CAROUSEL_LARROW_CLICK = 'Arcade_TRNMT_Carousel_LArrow_Click'


export const ArcadePlayClickAction = (data) => {
	return { type: ARCADE_PLAY_CLICK, payload: data };
}

export const ArcadeLeaderboardClickAction = (data) => {
	return { type: ARCADE_LEADERBOARD_CLICK, payload: data };
}

export const ArcadeCarouselRArrowClickAction = (data) => {
	return { type: ARCADE_CAROUSEL_RARROW_CLICK, payload: data };
}

export const ArcadeCarouselLArrowClickAction = (data) => {
	return { type: ARCADE_CAROUSEL_LARROW_CLICK, payload: data };
}

export const ArcadeTRMNTPlayClickAction = (data) => {
	return { type: ARCADE_TRMNT_PLAY_CLICK, payload: data };
}

export const ArcadeTRNMTCarouselRArrowClickAction = (data) => {
	return { type: ARCADE_TRNMT_CAROUSEL_RARROW_CLICK, payload: data };
}

export const ArcadeTRNMTCarouselLArrowClickAction = (data) => {
	return { type: ARCADE_TRNMT_CAROUSEL_LARROW_CLICK, payload: data };
}