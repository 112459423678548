import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import loadable from "@loadable/component";
//import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon"; 
import { FaArrowRightLong } from "react-icons/fa6";

import { fetchGameAppDataAction } from "../../redux/actions/gameActions";
//import { fetchBalanceAuthAction } from "../../redux/actions/authActions";
import {
  calculateAverageRatingStar,
  getCategoryDetailsById,
  getCloudFrontSourcePath,
} from "../../utils/functions";

import gameImg1 from "../../assets/images/games/samplegameImg.png";
import ic_siteImg from "../../assets/images/ic_site.png";
import ic_emailImg from "../../assets/images/ic_email.png";
import ic_privacyImg from "../../assets/images/ic_privacy.png";
import ic_addressImg from "../../assets/images/ic_address.png";

import GameBanner from './elements/GameBanner';
import GameBannerMobile from './elements/GameBannerMobile';

const TopUpModalComponent = loadable(() => import("../../components/UI/TopUpModalComponent"));
const StarRating = loadable(() => import("../../components/UI/StarRating"));
const GameCard = loadable(() => import("../../components/UI/GameCard"));
const Loader = loadable(() => import("../../components/UI/Loader"));
const Leaderboards = loadable(() => import("./elements/Leaderboards"));
const AppRatingModalComponent = loadable(() =>
  import("../../components/UI/AppRatingModalComponent")
);

// const GameBanner = loadable(() => import("./elements/GameBanner"));
// const GameBannerMobile = loadable(() => import("./elements/GameBannerMobile"));

const GameDetailPage = () => {
  const [showTopUp, setShowTopUp] = useState(false);
  const [rateModalVisible, setRateModalVisible] = useState(false);
  const GameState = useSelector((state) => state.Game);
  const AuthState = useSelector((state) => state.Auth);
  const HomeState = useSelector((state) => state.Home);
  const { gameId } = useParams();
  const { categoryList } = HomeState;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { gameData, loading, ratingLoader, gameVideoUrl } = GameState;
  let isLoggedIn = Boolean(AuthState?.user && AuthState?.token);

  useEffect(() => {
    dispatch(fetchGameAppDataAction({ app_id: gameId }));
  }, [gameId, dispatch]);

  // Scroll to top when loading is complete
  useEffect(() => {
    if (!loading) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [loading]);


  let cloudFrontSourcePath = getCloudFrontSourcePath();

  //gameData.desc = `Title: Marble Boom - A Colorful Puzzle Adventure\n\nDescription:\nGet ready to embark on a thrilling journey through a vibrant and challenging world of marbles in \"Marble Boom\"! This exciting puzzle game takes inspiration from the classic Zuma-style gameplay and adds its own unique twist to keep you engaged for hours of marble-matching fun.\n\n🌈 Match, Aim, and Explode:\nYour mission in Marble Boom is to eliminate a chain of colorful marbles before they reach their destination. Take control of a powerful cannon, loaded with an assortment of colorful balls, and aim precisely to create matches of three or more marbles of the same color. The more matches you make, the bigger your score and the more explosive power you unleash!\n\n💥 Power-Ups Galore:\nAs you progress through the game, you'll encounter a variety of exciting power-ups and bonuses. From laser beams that cut through marbles to multi-colored bombs that create epic chain reactions, these power-ups will help you clear levels with style. Strategically using these power-ups is the key to mastering Marble Boom.\n\nGet ready to experience the ultimate marble-shooting adventure! Marble Boom is the perfect blend of strategy, precision, and entertainment, designed to captivate players of all ages. Can you master the art of marble matching and save the colorful world from impending chaos? Play Marble Boom now and start your journey today!\n`;
  let gameDescHtml;
  if (gameData.desc) {
    gameDescHtml = gameData.desc.replace(/\n/g, "<br>");
  }

  // let moreByCategory = gameData?.more_by_category?.items;
  let moreByDeveloper = gameData?.more_by_developer?.items;

  const handleCategoryClick = () => {
    navigate(`/category/${gameData?.categoryId}`);
  };

  let ratingData;
  if (gameData?.rating) {
    ratingData = calculateAverageRatingStar(gameData?.rating, 1, true);
  }

  // const handleMoreByCategoryClick = () => {
  //   navigate(`/mightlike`);
  // };

  const handleDevdetailClick = () => {
    navigate(`/dev/${gameData?.developer_id}`);
  };

  const handleRateReviewClick = () => {
    setRateModalVisible(!rateModalVisible);
  };

  const handleParticipationReject = (res) => {
    //console.log(`log123----->handleParticipationReject`,res);
    setShowTopUp(true);
  };

  let isMobileView = window.innerWidth <= 768;

  let isFromCustomCategory =false;
	if(localStorage.getItem('isFromCustomCategory')){
		isFromCustomCategory = true;
	}

  let filterScreenShotArr = gameData?.screenshots?.filter(screenshot => screenshot !=="" && screenshot !==null);

  return (
    <div>
      {loading ? (
        <div className="w-full h-screen bg-gray-900 flex items-center justify-center">
          {/* This div acts as a placeholder taking the entire window size */}
          <Loader isTranserent={true} />
        </div>
      ) : (
        <>
          {isMobileView ? (
            <GameBannerMobile
              isLoggedIn={isLoggedIn}
              featuredUrl={gameVideoUrl}
              gameData={gameData}
              screenshots={filterScreenShotArr}
              hostUrl={cloudFrontSourcePath}
              averageRatingStar={parseFloat(ratingData?.averageRating).toFixed(
                1
              )}
              handleParticipationReject={handleParticipationReject}
            />
          ) : (
            <GameBanner
              isLoggedIn={isLoggedIn}
              featuredUrl={gameVideoUrl}
              gameData={gameData}
              screenshots={filterScreenShotArr}
              hostUrl={cloudFrontSourcePath}
              averageRatingStar={parseFloat(ratingData?.averageRating).toFixed(
                1
              )}
              handleParticipationReject={handleParticipationReject}
            />
          )}
          <div className="container pt-4 md:px-6 w-full">
            <Row xs={1} md={2}>
              <Col md={6}>
                <div className="flex flex-col aboutGameSection  gap-4">
                  <div className="flex flex-col md:pt-8 pt-0 gap-2">
                    <h1 className="text-white md:text-[35px] text-3xl pt-3"> About this game </h1>
                    <h2 className="text-white md:text-[20px] text-base pt-3 pb-1">
                      Categories / Tags
                    </h2>
                    <div
                      className="categoryBtn bg-[#1998a7] w-fit  rounded-[10px] text-white font-normal text-xs md:p-1.5  px-2.5 py-[5px] cursor-pointer pb-2"
                      onClick={handleCategoryClick}
                    >
                      {getCategoryDetailsById(
                        gameData?.categoryId,
                        categoryList
                      )}
                    </div>
                  </div>

                  {gameData?.id && (
                    <Leaderboards
                      gameId={gameData?.id}
                      isFromGameDetail={true}
                    />
                  )}

                  <div className="text-[#DADADA] text-[13px]">
                    <div dangerouslySetInnerHTML={{ __html: gameDescHtml }} />
                  </div>

                  <div className="text-white text-[14px]">
                    {" "}
                    Updated 1 week Ago{" "}
                  </div>

                  <div className="app-rating-section flex flex-column">
                    {!isMobileView && (
                      <div className="flex flex-row text-white justify-between items-center">
                        <h1 className="text-white text-[20px]">
                          {" "}
                          Ratings and reviews{" "}
                        </h1>
                        <button
                          className="bg-[#000] p-1 text-xs text-[#ffff]  w-[150px]  rounded-[20px] cursor-pointer hover:bg-[#ffff] hover:text-[#000] uppercase border border-solid border-[#1998a7] rate-review-button"
                          onClick={handleRateReviewClick}
                        >
                          <span className="star pr-2">&#9733;</span>
                          Rate & Reviews
                        </button>
                      </div>
                    )}

                    <div className="flex flex-row startRating gap-2 items-end">
                      <span className="text-[#f2994a] text-[52px]">
                        {" "}
                        {ratingData?.averageRating}{" "}
                      </span>
                      <StarRating ratings={ratingData?.averageRating} />
                      <span className="text-[#4d4d4d]  pb-2.5">
                        {gameData?.ratingCount} reviews
                      </span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <ul>
                        {ratingData?.ratingStat?.map((rate, index) => {
                          return (
                            <li
                              key={`app_rating_data_${index}`}
                              className="flex flex-row text-lg text-[#178d9c] gap-1 pr-[100px]"
                            >
                              <span className=""> {rate?.rating} </span>
                              <span className="star"> &#9733; </span>
                              <div className="w-full">
                                <div
                                  style={{
                                    width: `${rate.perentage}%`,
                                    height: "7px",
                                    backgroundColor: "#178d9c",
                                    marginTop: "11px",
                                    borderRadius: "10px",
                                  }}
                                ></div>
                              </div>
                              <span className="text-[#777]  pb-2.5">
                                {rate.perentage}%{" "}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    {isMobileView && (
                      <div className="flex flex-row text-white justify-between items-center">
                        <button
                          className="bg-[#000] p-1 text-xs text-[#ffff]  w-[150px]  rounded-[20px] cursor-pointer hover:bg-[#ffff] hover:text-[#000] uppercase border border-solid border-[#1998a7] rate-review-button"
                          onClick={handleRateReviewClick}
                        >
                          <span className="star pr-2"> &#9733; </span> Rate &
                          Reviews
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="app-rating-section flex flex-column gap-1 mb-20">
                    <h1 className="text-white md:text-[20px] text-[18px]">What's New ?</h1>
                    <div className="text-white text-[14px]">
                      {gameData?.versionComment}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="flex flex-col gap-3">
                  <h3 className="text-[24px] pt-4 text-[#ffff]">
                    {" "}
                    <span className="text-[#00e6f4]"> Developer </span>{" "}
                    Information{" "}
                  </h3>
                  <h3 className="text-[#00e6f4] text-[18px]    flex flex-row gap-1 justify-normal items-center">
                    {gameData?.developerIcon ? (
                      <img
                        src={`${gameData?.developerIcon}`}
                        alt="developerImg"
                        width={30}
                        height={20}
                      />
                    ) : (
                      <span className="ml-3"></span>
                    )}
                    {gameData?.developerName}
                  </h3>
                  <h3 className="text-[#DADADA] text-[13px]">
                    {gameData?.developerAbout}
                  </h3>

                  <div className="app-contact-info flex flex-col gap-2">
                    <h3 className="text-[17px] pt-2 text-[#ffff] pb-2">
                      {" "}
                      Contacts
                    </h3>
                    <div className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
                      <img
                        src={ic_siteImg}
                        alt="gameIcon1"
                        title=""
                        height={16}
                        width={16}
                        className="rounded-[10px]"
                      />
                      <h3 className="text-[14px]">
                        {gameData?.developerWebsite || "-"}
                      </h3>
                    </div>
                    <div className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
                      <img
                        src={ic_emailImg}
                        alt="gameIcon2"
                        title=""
                        height={16}
                        width={16}
                        className="rounded-[10px]"
                      />
                      <h3 className="text-[14px]">
                        {gameData?.developerSupport || "-"}
                      </h3>
                    </div>
                    <div className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
                      <img
                        src={ic_privacyImg}
                        alt="gameIcon3"
                        title=""
                        height={16}
                        width={16}
                        className="rounded-[10px]"
                      />
                      <h3 className="text-[14px]">
                        {gameData?.developerPrivacy || "-"}{" "}
                      </h3>
                    </div>
                    <div className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
                      <img
                        src={ic_addressImg}
                        alt="gameIcon4"
                        title=""
                        height={16}
                        width={16}
                        className="rounded-[10px]"
                      />
                      <h3 className="text-[14px]">
                        {gameData?.developerAddress || "-"}
                      </h3>
                    </div>
                  </div>

                  {!isFromCustomCategory && 
                    <div className="more_from_dev_section flex flex-col gap-2">
                      <h3 className="text-[28px] pt-1 text-[#ffff]">
                        <span className="text-[#00e6f4]"> More </span> from{" "}
                        {gameData?.developerName}
                      </h3>
                      <div className="gameDetailPageSection">
                        {moreByDeveloper &&
                          moreByDeveloper?.length > 0 &&
                          moreByDeveloper
                            .slice(0, 3)
                            .map((val, index) => (
                              <GameCard
                                keyIndex={index}
                                img={
                                  val?.icon
                                    ? `${cloudFrontSourcePath}${val?.icon}`
                                    : gameImg1
                                }
                                game={val}
                                key={`apppage_dev_more_gamesection_${index}`}
                              />
                            ))}
                      </div>

                      <div className="flex justify-center">
                        {moreByDeveloper && moreByDeveloper.length > 3 && (
                          <button
                            className="moreLoginOptionBtn flex items-center bg-[#00e5f3] hover:text-[#00e5f3] text-[15px] text-[#000] rounded-[20px] pt-1.5 pb-2 px-4 leading-[17px] hover:bg-[#2d2626] cursor-pointer h-10 uppercase max-w-[200px]"
                            onClick={handleDevdetailClick}
                          >
                            See All Games
                            <span className="ml-2">
                              {" "}
                              <FaArrowRightLong size={13} />
                {/* <FeatherIcon
                  icon="triangle"
                  className="rotate-90 fill-[#000] ml-1 mr-0 mt-0 mb-0 "
                  size={10}
                /> */}
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  }
                </div>
              </Col>
            </Row>

            {/* <section className="viewAllGame pt-4">
              {!isFromCustomCategory &&  
                <>
                  <div className="text-center flex justify-center">
                    <div
                      style={{
                        background:
                          "linear-gradient(90deg, rgba(0,0,0,1) 12%, rgba(3,10,11,1) 51%, rgba(5,16,17,1) 100%)",
                      }}
                      className="w-25"
                    ></div>
                    <h2 className="text-[#fff] text-[32px] w-100 p-[25px]   bg-gradient-to-t from-[#000000] bg-gradient-to-b to-[#08191c] ">
                      You <span className="text-[#00e6f4]">might</span> also like
                    </h2>
                    <div
                      className="w-25"
                      style={{
                        background:
                          "linear-gradient(90deg, rgba(5,16,17,1) 12%, rgba(3,10,11,1) 51%, rgba(0,0,0,1) 100%)",
                      }}
                    ></div>
                  </div>
                  <div className="gameCards">
                    {moreByCategory &&
                      moreByCategory?.length > 0 &&
                      moreByCategory.slice(0, 8).map((game, index) => {
                        return (
                          <GameCard
                            keyIndex={index}
                            img={
                              game?.icon
                                ? `${cloudFrontSourcePath}${game?.icon}`
                                : gameImg1
                            }
                            game={game}
                            key={`category_section_${index}`}
                            subtitle={gameData?.developer}
                          />
                        );
                      })}
                  </div>
                  {moreByCategory && moreByCategory?.length > 2 && (
                    <div className="flex justify-center">
                      <button
                        className="moreLoginOptionBtn flex items-center bg-[#00e5f3] text-[#000] text-[14px] rounded-[20px] pt-1.5 pb-2 px-4 leading-[17px] hover:bg-[#2d2626] hover:text-[#00e5f3] cursor-pointer h-10 uppercase max-w-[200px]"
                        onClick={handleMoreByCategoryClick}
                      >
                        See All Games{" "}
                        <span className="ml-1">
                          {" "}
                          <FaArrowRightLong size={13} />{" "}
                        </span>
                      </button>
                    </div>
                  )}
                </>
              }
            </section> */}
          </div>
          {rateModalVisible && (
            <AppRatingModalComponent
              gameImgUrl={`${cloudFrontSourcePath}${
                gameData?.icon ? gameData?.icon : gameData?.icon
              }`}
              gameRatingsArr={gameData?.rating}
              gameData={gameData}
              ratingLoader={ratingLoader}
              gameId={gameId}
              visible={rateModalVisible}
              title={`Rate and Review`}
              onClose={() => setRateModalVisible(false)}
            />
          )}
          {showTopUp && (
            <TopUpModalComponent show={showTopUp} onClose={setShowTopUp} />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(GameDetailPage);
