import { ANALYTICS_EVENT_FAILED,ANALYTICS_EVENT_SUCCESS,ANALYTICS_EVENT_REQUEST} from "../actions/eventsActions";

const initialState = {
	loading: false,
};

export const EventsReducer = (state = initialState, action) => { 
	switch (action.type) {
		case ANALYTICS_EVENT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case ANALYTICS_EVENT_SUCCESS: 
			return {
				...state,
				loading: false, 
			};
		case ANALYTICS_EVENT_FAILED:
			return {
				...state,
				loading: false
			};  
		default:
			return state;
	}
};
