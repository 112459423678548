import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosConfig";

import { VERIFYTX_BNB_FAILED,VERIFYTX_BNB_SUCCESS,BNB_CHECK_BALANCE_SUCCESS, BNB_CHECK_BALANCE_FAILED, MATIC_CHECK_BLANNCE_FAILED, MATIC_CHECK_BLANNCE_SUCCESS, VERFIY_PURCHASE_SUCCESS, VERFIY_PURCHASE_FAILED, CMC_CONVERSION_SUCCESS, CMC_CONVERSION_FAILED, VERIFYTX_MATIC_SUCCESS, VERIFYTX_MATIC_FAILED, PURCHASE_INTERVAL_VERIFY_SUCCESS, PURCHASE_INTERVAL_VERIFY_FAILED, BNB_CONVERSION_SUCCESS } from "../actions/CryptoActions";
import { TOAST_SHOW } from "../actions/toastActions"; 

export function* verifyBNBTxSaga(action) {
	try {
		const response = yield call(axios.post, `/bnb/verifyTx`, action.payload ); 
		if (response?.status === 200) {
			yield put({ type: VERIFYTX_BNB_SUCCESS, payload: response?.data });
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload:{ message: "Something Went wrong please try again", severity: "Danger", show: true}});
		yield put({ type: VERIFYTX_BNB_FAILED }); 
	}
}


export function* verifyMaticTxSaga(action) {
	try {
		const response = yield call(axios.post, `/matic/verifyTx`, action.payload ); 
		if (response?.status === 200) {
			yield put({ type: VERIFYTX_MATIC_SUCCESS, payload: response?.data });
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload:{ message: "Something Went wrong please try again", severity: "Danger", show: true}});
		yield put({ type: VERIFYTX_MATIC_FAILED }); 
	}
}


export function* bnbCheckBalanceSaga(action){
	try{
		const response = yield call(axios.post,'/bnb/check_balance',action?.payload);
		if(response?.status === 200){
			yield put({ type: BNB_CHECK_BALANCE_SUCCESS,payload:response?.data});
		}
	} catch(e){
		yield put({ type: TOAST_SHOW, payload: { message: 'Something went wrong please try again.',severity:'Danger',show:true } });
		yield put({ type: BNB_CHECK_BALANCE_FAILED });
	} 
}

export function* maticCheckBalanceSaga(action){   
	try{
		const response = yield call(axios.post,'/matic/check_balance',action?.payload);
		if(response?.status === 200){ 
			yield put({ type: MATIC_CHECK_BLANNCE_SUCCESS,payload:response?.data});
		}
	} catch(e){
		yield put({ type: TOAST_SHOW, payload: { message: 'Something went wrong please try again.',severity:'Danger',show:true } });
		yield put({ type: MATIC_CHECK_BLANNCE_FAILED });
	} 
}

export function* purchaseVerifySaga(action){
	try{
		const response = yield call(axios.post,'/purchase/verify',action?.payload);
		if(response?.status === 200){
			yield put({ type:VERFIY_PURCHASE_SUCCESS, payload: response?.data })
		}
	} catch(e){
		yield put({ type:TOAST_SHOW, payload:{ message:'Something went wrong please try again.',severity:'Danger',show:true } })
		yield put({ type: VERFIY_PURCHASE_FAILED })
	}
}


export function* purchaseIntervalVerifySaga(action){
	try{
		const response = yield call(axios.post,'/purchase/intervalverify',action?.payload);
		if(response?.status === 200){
			yield put({ type: PURCHASE_INTERVAL_VERIFY_SUCCESS, payload: response?.data })
		}
	} catch(e){
		yield put({ type: TOAST_SHOW, payload:{ message:'Something went wrong please try again.',severity:'Danger',show:true } })
		yield put({ type: PURCHASE_INTERVAL_VERIFY_FAILED })
	}
}

export function* cmcConversionSaga(action){
	try{
		const response = yield call(axios.post,'/cmc/conversion_rate',action?.payload);   
		if(response?.status ===200){
			let toCrypto = action?.payload?.to;
			let conversionData = response?.data; 
			conversionData.amountUSD = action?.payload?.amount;
			if(conversionData.price){ 
				conversionData.price = conversionData.price.toFixed(5)
			} 
			if(toCrypto==='BNB'){
				yield put({ type: BNB_CONVERSION_SUCCESS,payload:conversionData });
			}else{ 
				yield put({ type: CMC_CONVERSION_SUCCESS,payload:conversionData });
			}
		}
	} catch(e) {
		yield put({ type:TOAST_SHOW,payload:{ message:'Something went wrong please try again.',severity:'Danger',show:true} })
		yield put({ type: CMC_CONVERSION_FAILED})
	}
}


export function* bnbConversionSaga(action){
	try{
		const response = yield call(axios.post,'/cmc/conversion_rate',action?.payload);   
		if(response?.status ===200){
			let toCrypto = action?.payload?.to;
			let conversionData = response?.data;
			conversionData.amountUSD = action?.payload?.amount; 
			if(conversionData.price){ 
				conversionData.price = conversionData.price.toFixed(5)
			} 
			if(toCrypto==='BNB'){
				yield put({ type: BNB_CONVERSION_SUCCESS,payload:conversionData });
			}else{ 
				yield put({ type: CMC_CONVERSION_SUCCESS,payload:conversionData });
			}
		}
	} catch(e) {
		yield put({ type:TOAST_SHOW,payload:{ message:'Something went wrong please try again.',severity:'Danger',show:true} })
		yield put({ type: CMC_CONVERSION_FAILED})
	}
}