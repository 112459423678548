export const getTomorrow = () => {
    const now = new Date();
    const tomorrow = new Date(now);

    // Check if the current time is before or after 12:30 UTC
    if (now.getUTCHours() < 12 || (now.getUTCHours() === 12 && now.getUTCMinutes() < 30)) {
        // It's before 12:30, so today at 12:30 is the target time
        tomorrow.setUTCHours(12, 30, 0, 0);
    } else {
        // It's after 12:30, so tomorrow at 12:30 is the target time
        tomorrow.setUTCDate(now.getUTCDate() + 1);
        tomorrow.setUTCHours(12, 30, 0, 0);
    }
    return tomorrow;
};


export const getNextMonday = () => {
    const now = new Date();
    const dayOfWeek = now.getUTCDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
    const daysUntilMonday = (1 - dayOfWeek + 7) % 7 || 7; // Calculate days to next Monday
    let nextMonday = new Date(now);
   
    // Check if it's before or after 12:30 UTC on the next Monday
    if (now.getUTCDay() === 1 && (now.getUTCHours() < 12 || (now.getUTCHours() === 12 && now.getUTCMinutes() < 30))) {
        // It's Monday but before 12:30, so this Monday at 12:30 is the target time
        nextMonday.setUTCHours(12, 30, 0, 0);
    } else {
        nextMonday.setUTCDate(now.getUTCDate() + daysUntilMonday);
        nextMonday.setUTCHours(12, 30, 0, 0);
    }

    return nextMonday;
};

