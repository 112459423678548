import { all, takeEvery } from "redux-saga/effects";

import { CONNECT_REQUEST, FETCH_BALANCE_REQUEST, GET_USER_REQUEST, LOGOUT, TELEGRAM_SIGNIN_REQUEST } from "../actions/authActions";
import { TOAST_INIT } from "../actions/toastActions";
import { GET_SECTION_GAMES_REQUEST, HOMEPAGE_DATA_REQUEST } from "../actions/homeActions"; 
import { PAYMENT_CHECKOUT_SESSION_REQUEST, RECENTLY_PLAYED_REQUEST, STRIPE_PAYMENT_CONFIRM_REQUEST, UPDATE_USER_REQUEST, vAKEDO_PRODUCTS_LIST_REQUEST} from "../actions/settingsActions";
import { GET_ALL_CATEGORY_REQUEST, GET_CATEGORY_DETAIL_REQUEST, GET_CUSTOM_CATEGORY_DETAIL_REQUEST } from "../actions/categoryActions";
import { CALL_EVENT_REQUEST, CHECK_GAME_PARTICIPATION_REQUEST, FETCH_GAME_DATA_REQUEST, GAME_PURCHASE_REQUEST, GAME_RATING_REQUEST, GAME_SCORE_SUBSTRACT_REQUEST, GET_ACHIEVEMENTS_REQUEST, GET_APP_BY_TAG_REQUEST, GET_APP_PRODUCTS_REQUEST, GET_APP_PRODUCT_DETAIL_REQUEST, GET_APP_USERRATED_DETAIL_REQUEST, GET_DEV_DETAIL_REQUEST, GET_LEADERBOARD_REQUEST } from "../actions/gameActions";
import { GET_POPUP_MESSAGE_REQUEST, MESSAGE_READ_REQUEST } from "../actions/assad_arcade/AssadMessageActions";
import { ADD_ASSAD_COIN_REQUEST } from "../actions/assad_arcade/AssadGameAction"

import { ConnectSaga, LoginSaga, LogoutSaga, TelegramSignSaga, fetchBalanceSaga } from "./AuthSaga";
import { ToastSaga } from "./ToastSaga";
import { fetchSectionGamesSaga, loadHomepageDataSaga } from "./HomeSaga";
import { UpdateUserSaga, createCheckoutSessionSaga, fetchRecentlyPlayedSaga, fetchVAkedoProductsListSaga, stripeCallbackSaga } from "./SettingSaga"; 
import { fetchCategoryDetails, fetchAllCategorySaga, getCustomCategoryDetailSaga } from "./CategorySaga";
import { appRatingSaga, checkGameParticipationSaga, fetchGameAppDataSaga, fetchGameByTagSaga, fetchLeaderboardSaga, gamePurchaseSaga, getAchievementsSaga, getAppProductDetailSaga, getAppProductsSaga, getDevDetailsSaga, getGameUserRatedDetailSaga, substractScoreSaga, triggerEventsSaga } from "./GameSaga";
import { SDK_GET_GAME_TOKEN_REQUEST, SDK_INIT_JS_REQUEST } from "../actions/gameSdkActions";
import { fetchGameSdkTokenSaga, initJsSDKSaga } from "./GameSdkSaga";
import { BNB_CHECK_BALANCE_REQUEST, BNB_CONVERSION_REQUEST, CMC_CONVERSION_REQUEST, MATIC_CHECK_BLANNCE_REQUEST, PURCHASE_INTERVAL_VERIFY_REQUEST, VERFIY_PURCHASE_REQUEST, VERIFYTX_BNB_REQUEST, VERIFYTX_MATIC_REQUEST } from "../actions/CryptoActions";
import { bnbCheckBalanceSaga, bnbConversionSaga, cmcConversionSaga, maticCheckBalanceSaga, purchaseIntervalVerifySaga, purchaseVerifySaga, verifyBNBTxSaga, verifyMaticTxSaga } from "./CryptoSaga";
import { GET_ALL_USER_MESSAGES_REQUEST, GET_USER_MESSAGES_REQUEST, SET_USER_MESSAGE_REQUEST, USER_MESSAGE_READ_REQUEST } from "../actions/messageActions";
import { getAllUserMessagesSaga, getUserMessagesSaga, setUserMessageReadSaga, setUserMessageSaga } from "./MessageSaga";
import { ANALYTICS_EVENT_REQUEST } from "../actions/eventsActions";
import { triggerAnalyticsEventsSaga } from "./EventsSaga";
import { getPopupMessageSaga, readMessageSaga } from "./assad_arcade/AssadMessageSaga";
import { AddAssadCoinSaga } from "./assad_arcade/AssadGameSaga"

function* rootSagas() {
	yield all([
		takeEvery(GET_USER_REQUEST, LoginSaga),
		takeEvery(LOGOUT, LogoutSaga),
		takeEvery(TOAST_INIT, ToastSaga),
		takeEvery(HOMEPAGE_DATA_REQUEST, loadHomepageDataSaga),
		takeEvery(GET_SECTION_GAMES_REQUEST, fetchSectionGamesSaga),
		takeEvery(UPDATE_USER_REQUEST, UpdateUserSaga),
		takeEvery(GET_CATEGORY_DETAIL_REQUEST, fetchCategoryDetails),
		takeEvery(GET_ALL_CATEGORY_REQUEST, fetchAllCategorySaga),
		takeEvery(RECENTLY_PLAYED_REQUEST, fetchRecentlyPlayedSaga),
		takeEvery(CALL_EVENT_REQUEST, triggerEventsSaga),
		takeEvery(FETCH_GAME_DATA_REQUEST, fetchGameAppDataSaga),
		takeEvery(GET_LEADERBOARD_REQUEST, fetchLeaderboardSaga),
		takeEvery(GET_DEV_DETAIL_REQUEST,getDevDetailsSaga),
		takeEvery(GAME_RATING_REQUEST,appRatingSaga),
		takeEvery(GET_ACHIEVEMENTS_REQUEST,getAchievementsSaga),
		takeEvery(CHECK_GAME_PARTICIPATION_REQUEST,checkGameParticipationSaga),
		takeEvery(GET_APP_BY_TAG_REQUEST,fetchGameByTagSaga), 
		takeEvery(GET_APP_PRODUCT_DETAIL_REQUEST,getAppProductDetailSaga), 
		takeEvery(GET_APP_PRODUCTS_REQUEST,getAppProductsSaga), 
		takeEvery(GET_APP_USERRATED_DETAIL_REQUEST,getGameUserRatedDetailSaga),
		takeEvery(GAME_PURCHASE_REQUEST,gamePurchaseSaga),
		takeEvery(GAME_SCORE_SUBSTRACT_REQUEST,substractScoreSaga),
		takeEvery(FETCH_BALANCE_REQUEST,fetchBalanceSaga),
		takeEvery(vAKEDO_PRODUCTS_LIST_REQUEST,fetchVAkedoProductsListSaga), 
		takeEvery(PAYMENT_CHECKOUT_SESSION_REQUEST,createCheckoutSessionSaga),
		takeEvery(STRIPE_PAYMENT_CONFIRM_REQUEST,stripeCallbackSaga),
		takeEvery(SDK_GET_GAME_TOKEN_REQUEST,fetchGameSdkTokenSaga),
		takeEvery(SDK_INIT_JS_REQUEST,initJsSDKSaga),
		takeEvery(VERIFYTX_BNB_REQUEST,verifyBNBTxSaga), 
		takeEvery(BNB_CHECK_BALANCE_REQUEST,bnbCheckBalanceSaga), 
		takeEvery(MATIC_CHECK_BLANNCE_REQUEST,maticCheckBalanceSaga),
		takeEvery(VERFIY_PURCHASE_REQUEST,purchaseVerifySaga), 
		takeEvery(CMC_CONVERSION_REQUEST,cmcConversionSaga),
		takeEvery(BNB_CONVERSION_REQUEST,bnbConversionSaga),
		takeEvery(VERIFYTX_MATIC_REQUEST,verifyMaticTxSaga), 
		takeEvery(PURCHASE_INTERVAL_VERIFY_REQUEST,purchaseIntervalVerifySaga),
		takeEvery(CONNECT_REQUEST,ConnectSaga),
		takeEvery(SET_USER_MESSAGE_REQUEST,setUserMessageSaga),
		takeEvery(USER_MESSAGE_READ_REQUEST,setUserMessageReadSaga),
		takeEvery(GET_USER_MESSAGES_REQUEST,getUserMessagesSaga),
		takeEvery(GET_ALL_USER_MESSAGES_REQUEST,getAllUserMessagesSaga),
		takeEvery(ANALYTICS_EVENT_REQUEST,triggerAnalyticsEventsSaga),
		takeEvery(TELEGRAM_SIGNIN_REQUEST,TelegramSignSaga),
		takeEvery(GET_CUSTOM_CATEGORY_DETAIL_REQUEST,getCustomCategoryDetailSaga),
		takeEvery(GET_POPUP_MESSAGE_REQUEST, getPopupMessageSaga),
		takeEvery(MESSAGE_READ_REQUEST, readMessageSaga),
		takeEvery(ADD_ASSAD_COIN_REQUEST, AddAssadCoinSaga)
	]);
}
export default rootSagas;