import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosConfig";
import { TOAST_SHOW } from "../actions/toastActions";

import { CALL_EVENT_SUCCESS, CALL_EVENT_ERROR, FETCH_GAME_DATA_SUCCESS, FETCH_GAME_DATA_ERROR, GET_LEADERBOARD_SUCCESS, GET_LEADERBOARD_ERROR, GET_DEV_DETAIL_SUCCESS, GET_DEV_DETAIL_FAILED, GAME_RATING_SUCCESS, GAME_RATING_FAILED, GET_ACHIEVEMENTS_SUCCESS, GET_ACHIEVEMENTS_FAILED, CHECK_GAME_PARTICIPATION_SUCCESS, CHECK_GAME_PARTICIPATION_FAILED, GET_APP_BY_TAG_SUCCESS, GET_APP_BY_TAG_FAILED, GET_APP_PRODUCT_DETAIL_SUCCESS, GET_APP_PRODUCT_DETAIL_FAILED, GET_APP_PRODUCTS_SUCCESS, GET_APP_PRODUCTS_FAILED, GET_APP_USERRATED_DETAIL_SUCCESS, GET_APP_USERRATED_DETAIL_FAILED, GAME_PURCHASE_SUCCESS, GAME_PURCHASE_FAILED, GAME_SCORE_SUBSTRACT_SUCCESS, GAME_SCORE_SUBSTRACT_FAILED, FETCH_BALANCE_FAILED, FETCH_BALANCE_SUCCESS } from "../actions/gameActions";
import { getCloudFrontSourcePath } from "../../utils/functions";
 
export function* triggerEventsSaga({ payload, callback }) { 
	const url = `/api/events?t=${Date.now()}`; 
	try {
	  const { data, status } = yield call(axios.post, url, payload);
	  if (status === 200) {
		yield put({ type: CALL_EVENT_SUCCESS, payload: data, gameId: payload?.event_value });
		if (callback) callback(true);
	  }
	} catch {
	  yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true, }});
	  yield put({ type: CALL_EVENT_ERROR });
	}
}
export function* fetchGameAppDataSaga({ payload,callback }) {
	const url = `/api/app?t=${Date.now()}`;
	try {
		const response = yield call(axios.post, url, payload); 
		if (response.status === 200) {
			const { data } = response; 
			let cloudFrontSourcePath = getCloudFrontSourcePath(); 
			let gameVideoUrl;
			let isMobileView = window.innerWidth <= 768;
			if (isMobileView && data?.portrait_video) {
				gameVideoUrl = `${cloudFrontSourcePath}${data?.portrait_video}`
			} else if(data?.video){				
				gameVideoUrl = `${cloudFrontSourcePath}${data?.video}`
			} else{
				gameVideoUrl = `https://d1n2mqts4lqg92.cloudfront.net/assets/featured_akedo_s.mp4`;
			}
			yield put({ type: FETCH_GAME_DATA_SUCCESS, payload: data,gameVideoUrl:  gameVideoUrl });
			if (callback) callback();
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} }); 
		yield put({ type: FETCH_GAME_DATA_ERROR });
	}
}

export function* fetchLeaderboardSaga(action) {
	const { payload } = action; 
	const url = `/api/leaderboard?t=${Date.now()}`;
	try {
		const response = yield call( axios.post, url , payload ); 
		if (response?.status === 200) {
			const { data } = response;
			yield put({ type: GET_LEADERBOARD_SUCCESS,  payload: data });
		}
	} catch (e) {
		yield put({  type: TOAST_SHOW,  payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} }); 
		yield put({  type: GET_LEADERBOARD_ERROR });
	}
}

export function* getDevDetailsSaga({ payload, callback }) {
	const url = `/api/devList?t=${Date.now()}`;
	try {
		const response = yield call( axios.post, url, payload );  
		if (response?.status === 200) {
			const { data } = response;
			yield put({ type: GET_DEV_DETAIL_SUCCESS, payload: data}); 
			if (callback) callback(true); 
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true}}); 
		yield put({ type: GET_DEV_DETAIL_FAILED });
	}
}

export function* appRatingSaga({ payload, callbackFun }) { 
	let updateGameDetail;
	const url  = `/api/app/rating?t=${Date.now()}`;
	try {
		const response = yield call( axios.post, url , payload ); 
		if (response?.status === 200) {
			const { data } = response;
			yield put({ type: GAME_RATING_SUCCESS, payload: data });  
			updateGameDetail=true;
		}
	} catch (e) {
		if(e?.response?.status ===400){
			yield put({ type: TOAST_SHOW, payload: { message: e?.response?.data , severity: "Danger", show: true } }); 
		}else{
			yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true } }); 
		}
		yield put({ type: GAME_RATING_FAILED });
		updateGameDetail=false;
	}
	if (callbackFun) callbackFun(updateGameDetail);

}

export function* getAchievementsSaga({ payload }) {  
	const url  =  `/api/get_all_achievement?t=${Date.now()}`;
	try {
		const response = yield call(axios.post, url , payload); 
		if (response?.status === 200) {
			const { data } = response;
			yield put({ type:GET_ACHIEVEMENTS_SUCCESS, payload: data }); 
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true } });
		yield put({ type: GET_ACHIEVEMENTS_FAILED });
	}
}

 
export function* fetchBalanceSagaNotInUse(action) {  
	const url = `/api/balance?t=${Date.now()}`;
	try {
		const response = yield call(axios.get, url); 
		if (response?.status === 200) {
			const { data } = response ;
			yield put({ type:FETCH_BALANCE_SUCCESS, payload: data }); 
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true } });
		yield put({ type: FETCH_BALANCE_FAILED });
	}
}


export function* checkGameParticipationSaga({payload,callbackFun,blockParticipationCallbackFun}) { 
	const url = `/api/check_participation?t=${Date.now()}`;
	try {
		const response = yield call( axios.post, url, payload );  

		if (response?.status === 200) {
			const { data } = response;
			yield put({ type: CHECK_GAME_PARTICIPATION_SUCCESS, payload: data });  
			
			if(callbackFun && data?.valid){
				callbackFun();
			}else{
				if(blockParticipationCallbackFun){
					blockParticipationCallbackFun(data);
				}
			} 
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true }});
		yield put({ type: CHECK_GAME_PARTICIPATION_FAILED });
	}
}

export function* fetchGameByTagSaga({ payload }) {
	const url = `/api/tagapps?t=${Date.now()}`; 
	try {
		const response = yield call(axios.post, url ,payload); 
		if (response.status === 200) {
			const { data } = response;
			yield put({type: GET_APP_BY_TAG_SUCCESS,payload: data });
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: {message: "Something Went wrong please try again",severity: "Danger",show: true}});
		yield put({ type: GET_APP_BY_TAG_FAILED });
	}
}

export function* getAppProductDetailSaga({ payload }) {
	const url  = `/api/appProduct?t=${Date.now()}`;
	try {
		const response = yield call(axios.post, url ,payload); 
		if (response?.status === 200) {
			const { data } = response;
			yield put({type: GET_APP_PRODUCT_DETAIL_SUCCESS,payload: data});
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: {message: "Something Went wrong please try again",severity: "Danger",show: true}});
		yield put({ type: GET_APP_PRODUCT_DETAIL_FAILED });
	}
}

export function* getAppProductsSaga({ payload }) {
	const url = `/api/appProducts?t=${Date.now()}`;
	try {
		const response = yield call(axios.post, url , payload); 
		if (response?.status === 200) {
			const { data } = response;
			yield put({type: GET_APP_PRODUCTS_SUCCESS,payload: data});
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again",severity: "Danger",show: true }});
		yield put({ type: GET_APP_PRODUCTS_FAILED });
	}
}

export function* getGameUserRatedDetailSaga({ payload }) {
	const url = `/api/app/user_rated?t=${Date.now()}`;
	try {
		const response = yield call( axios.post, url , payload);
		if (response.status === 200) {
			const { data } = response;
			yield put({ type: GET_APP_USERRATED_DETAIL_SUCCESS, payload: data });
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something went wrong please try again", severity: "Danger", show: true } });
		yield put({ type: GET_APP_USERRATED_DETAIL_FAILED });
	}
}

export function* gamePurchaseSaga({ payload }) { 
	const url = `/api/purchase?t=${Date.now()}`;
	try {
		const response = yield call(axios.post, url , payload);
		if (response?.status === 200) {
			const { data } = response;
			yield put({ type: GAME_PURCHASE_SUCCESS, payload: data });
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something went wrong please try again", severity: "Danger", show: true } });
		yield put({ type: GAME_PURCHASE_FAILED });
	}
}

export function* substractScoreSaga({ payload }) {
	const url = `/api/substract`;
	try{
		const response = yield call(axios.post, url, payload);
		if(response.status ===200){
			const { data } = response;
			yield put({ type:GAME_SCORE_SUBSTRACT_SUCCESS, payload: data })
		}
	} catch (e) {
		yield put({ type:TOAST_SHOW,payload:{message:"Something went wrong please try again",severity: "Danger",show: true }});
		yield put({ type: GAME_SCORE_SUBSTRACT_FAILED })
	}
}

