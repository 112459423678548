import { useState, useEffect } from 'react';

const IconComponent = ({ imgSrc, altText }) => {
  const [iconSrc, setIconSrc] = useState(null); 

  useEffect(() => {
    const loadIcon = async () => {
      try {
        const icon = await import(/* @vite-ignore */ `${imgSrc}`);
        setIconSrc(icon);
      } catch (err) {
        console.error('err icon load', err);
      }
    };

    loadIcon();
  }, [imgSrc]); 
  
  return (
    <>
      {iconSrc ? (
        <img src={iconSrc} alt={altText} className="max-w-[20px]"  onLoad={() => {} } />
      ) : null }
    </>
  );
};

export default IconComponent;
