import {
	CALL_EVENT_REQUEST,
	CALL_EVENT_ERROR,
	CALL_EVENT_SUCCESS,
	FETCH_GAME_DATA_SUCCESS,
	FETCH_GAME_DATA_ERROR,
	FETCH_GAME_DATA_REQUEST,
	GET_LEADERBOARD_REQUEST,
	GET_LEADERBOARD_SUCCESS,
	GET_LEADERBOARD_ERROR ,
	GET_DEV_DETAIL_SUCCESS,
	GET_DEV_DETAIL_FAILED,
	GET_DEV_DETAIL_REQUEST,
	GAME_RATING_FAILED,
	GAME_RATING_REQUEST,
	GAME_RATING_SUCCESS,
	GET_ACHIEVEMENTS_FAILED,
	GET_ACHIEVEMENTS_SUCCESS,
	GET_ACHIEVEMENTS_REQUEST,
	FETCH_BALANCE_FAILED,
	FETCH_BALANCE_REQUEST,
	FETCH_BALANCE_SUCCESS,
	CHECK_GAME_PARTICIPATION_FAILED,
	CHECK_GAME_PARTICIPATION_REQUEST,
	CHECK_GAME_PARTICIPATION_SUCCESS,
	GET_APP_BY_TAG_REQUEST,
	GET_APP_BY_TAG_SUCCESS,
	GET_APP_BY_TAG_FAILED,
	GET_APP_PRODUCT_DETAIL_REQUEST,
	GET_APP_PRODUCT_DETAIL_SUCCESS,
	GET_APP_PRODUCT_DETAIL_FAILED,
	GET_APP_PRODUCTS_FAILED,
	GET_APP_PRODUCTS_REQUEST,
	GET_APP_PRODUCTS_SUCCESS,
	GET_APP_USERRATED_DETAIL_REQUEST,
	GET_APP_USERRATED_DETAIL_SUCCESS,
	GET_APP_USERRATED_DETAIL_FAILED,
	GAME_PURCHASE_REQUEST,
	GAME_PURCHASE_SUCCESS,
	GAME_PURCHASE_FAILED,
	GAME_SCORE_SUBSTRACT_REQUEST,
	GAME_SCORE_SUBSTRACT_SUCCESS,
	GAME_SCORE_SUBSTRACT_FAILED
} from "../actions/gameActions";

const initialState = {
	loading: false,
	events:false, //Events API successful or note flag
	gameData :{}, //game details object,
	gameId:null, // CurrentGame ID
	leaderBoardData:{}, // Leaderboard Data,
	leaderboardLoader:false,
	moreFromGameDev:{}, //Dev details and more games from same dev,
	ratingLoader:false, //app-game rating loader,
	achievements :{}, // achiements
	balance:0, // user balance,
	checkParticipationData:{},
	gameDataByTag:{}, //Game by tag data
	appProductDetails:{},
	appProductDetailsLoader:false,
	appProducts:{},
	appUserRatedDetails:{},
	substractValue:{},
	gameVideoUrl: null
};

export const GameReducer = (state = initialState, action) => { 
	switch (action.type) {
		case CALL_EVENT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CALL_EVENT_SUCCESS: 
			return {
				...state,
				loading: false,
				events:true,
				gameId:action?.gameId
			};
		case CALL_EVENT_ERROR:
			return {
				...state,
				loading: false,
				events:false
			}; 

		case FETCH_GAME_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_GAME_DATA_SUCCESS:
			return {
				...state,  
				gameData:action?.payload,
				loading: false,
				gameVideoUrl : action?.gameVideoUrl, 
			};
		case FETCH_GAME_DATA_ERROR:
			return {
				...state,
				loading: false,
				events:false,
				gameData:{} 
			}; 
		case GET_LEADERBOARD_REQUEST:
			return {...state,leaderboardLoader:true }; 
		case GET_LEADERBOARD_SUCCESS:
			return {...state,leaderboardLoader:false ,leaderBoardData:action?.payload}; 
		case GET_LEADERBOARD_ERROR:
			return { ...state,  leaderboardLoader:false , leaderBoardData:{} };
		case GET_DEV_DETAIL_REQUEST:
			return {
				...state, 
				loading:true,
				moreFromGameDev:{}
			};
		case GET_DEV_DETAIL_SUCCESS:
			return {
				...state, 
				loading:false,
				moreFromGameDev:action?.payload
			};
		case GET_DEV_DETAIL_FAILED:
			return {
				...state, 
				loading:false, 
			};
		case GAME_RATING_REQUEST:
			return {
				...state, 
				ratingLoader:true, 
			};
		case GAME_RATING_SUCCESS:
			return {
				...state, 
				ratingLoader:false, 
			};
		case GAME_RATING_FAILED:
			return {
				...state, 
				ratingLoader:false, 
			};
		case GET_ACHIEVEMENTS_REQUEST:
			return {
				...state, 
				loading:true, 
			};
		case GET_ACHIEVEMENTS_SUCCESS:
			return {
				...state, 
				loading:false, 
				achievements:action?.payload 
			};
		case GET_ACHIEVEMENTS_FAILED:
			return {
				...state, 
				loading:false, 
			};
		case FETCH_BALANCE_REQUEST:
			return {
				...state, 
				loading:true, 
			};
		case FETCH_BALANCE_SUCCESS:
			return {
				...state, 
				loading:false,
				balance:action?.payload 
			};
		case FETCH_BALANCE_FAILED:
			return {
				...state, 
				loading:false, 
			};  
		case CHECK_GAME_PARTICIPATION_REQUEST:
			return {
				...state, 
				loading:true, 
			};
		case CHECK_GAME_PARTICIPATION_SUCCESS:
			return {
				...state, 
				loading:false, 
				checkParticipationData:action?.payload
			};
		case CHECK_GAME_PARTICIPATION_FAILED:
			return {
				...state, 
				loading:false, 
				checkParticipationData: {}
			};  
		case GET_APP_BY_TAG_REQUEST:
			return {
				...state, 
				loading:true, 
			};
		case GET_APP_BY_TAG_SUCCESS:
			return {
				...state, 
				loading:false, 
				gameDataByTag:action?.payload
			};
		case GET_APP_BY_TAG_FAILED:
			return {
				...state, 
				loading:false, 
				gameDataByTag: {}
			};
		case GET_APP_PRODUCT_DETAIL_REQUEST:
			return {
				...state, 
				appProductDetailsLoader:true
			};
		case GET_APP_PRODUCT_DETAIL_SUCCESS:
			return {
				...state, 
				appProductDetailsLoader:false,
				appProductDetails:action?.payload
			};
		case GET_APP_PRODUCT_DETAIL_FAILED:
			return {
				...state, 
				appProductDetailsLoader:false,
				appProductDetails:{}
			};
		case GET_APP_PRODUCTS_REQUEST:
			return {
				...state, 
				loading:true,  
			};
		case GET_APP_PRODUCTS_SUCCESS:
			return {
				...state, 
				loading:false, 
				appProducts:action?.payload 
			};
		case GET_APP_PRODUCTS_FAILED:
			return {
				...state, 
				loading:false,  
			};
		case GET_APP_USERRATED_DETAIL_REQUEST:
			return {
				...state, 
				loading:true,  
			};
		case GET_APP_USERRATED_DETAIL_SUCCESS:
			return {
				...state, 
				loading:false,
				appUserRatedDetails:action?.payload
			};
		case GET_APP_USERRATED_DETAIL_FAILED:
			return {
				...state, 
				loading:false,  
			};
		case GAME_PURCHASE_REQUEST:
			return {
				...state,
				loading:true
			};
		case GAME_PURCHASE_SUCCESS:
			return {
				...state,
				loading:false
			};
		case GAME_PURCHASE_FAILED:
			return {
				...state,
				loading:false
			};
		case GAME_SCORE_SUBSTRACT_REQUEST:
			return {
				...state,
				loading:true,
				substractValue:{} 
			};
		case GAME_SCORE_SUBSTRACT_SUCCESS:
			return {
				...state,
				loading:false,
				substractValue:action?.payload
			};
		case GAME_SCORE_SUBSTRACT_FAILED:
			return {
				...state,
				loading:false,
				substractValue:{} 
			};
		default:
			return state;
	}
};
