import {
    ADD_ASSAD_COIN_REQUEST,
    ADD_ASSAD_COIN_SUCCESS,
    ADD_ASSAD_COIN_FAILED,
} from "../../actions/assad_arcade/AssadGameAction";

const initialState = {
    add_loading: false,
};
  
export const AssadGameReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ASSAD_COIN_REQUEST:
            return {
                ...state,
                add_loading: true,
            };
        case ADD_ASSAD_COIN_SUCCESS:
            return {
                ...state,
                add_loading: false,
            };
        case ADD_ASSAD_COIN_FAILED:
            return {
                ...state,
                add_loading: false,
            };
        default:
            return state;
    }
};
  