import React from "react"; 
import { useSelector } from "react-redux";
import loadable from "@loadable/component";

import { getCloudFrontSourcePath } from "../../../utils/functions"; 
import gameImg1 from "../../../assets/images/games/samplegameImg.png";

const GameCard = loadable(() => import("../../../components/UI/GameCard"));

const GameMightlike = () => {
	const GameState = useSelector((state) => state.Game); 
	const { gameData } = GameState; 
	 
	let moreByCategory = gameData?.more_by_category?.items; 
	 
	let cloudFrontSourcePath = getCloudFrontSourcePath();
	let isMobileView = window.innerWidth <= 768;

	return (
		<> 
			 
			<section className="viewAllGame px-0 py-[30px]">
				<div className="container">
					{
						isMobileView ?
						<div className="text-center miniLayoutTilte"> <h2 className="text-[#fff] text-[28px] font-semibold  view-all-title"> You might also like </h2> </div> 

						:
						<div className="text-center  flex justify-center"> 
							<div   style={{ background:'linear-gradient(90deg, rgba(0,0,0,1) 12%, rgba(3,10,11,1) 51%, rgba(5,16,17,1) 100%)' }} className="w-25" > 
							
							</div>
							<h2 className="text-[#fff] text-[28px] w-100 p-[25px]   bg-gradient-to-t from-[#000000] bg-gradient-to-b to-[#08191c] ">
								You <span className="text-[#00e6f4]">might</span>{" "}
								also like
							</h2>
							<div className="w-25"  style={{ background:'linear-gradient(90deg, rgba(5,16,17,1) 12%, rgba(3,10,11,1) 51%, rgba(0,0,0,1) 100%)' }} ></div>
						</div>

					} 


					<div className="gameCards">
						{moreByCategory.map((val, index) => (
							<GameCard keyIndex={`game_id_${index}`} img={ val?.icon ? `${cloudFrontSourcePath}${val?.icon}` : gameImg1 } game={val} key={`might_also_like_${index}`} />
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default GameMightlike;
