import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import loadable from "@loadable/component";
import back_buttonImg from '../../assets/images/back_button.png';
import green_logoImg from '../../assets/images/green_logo.png';
import { checkGameParticipationAction, fetchGameAppDataAction } from "../../redux/actions/gameActions";
import { fetchBalanceAuthAction } from "../../redux/actions/authActions";

const Loader = loadable(() => import("../../components/UI/Loader"));
const GamePlayIFrameComponent = loadable(() => import("./GamePlayIFrameComponent"));

const Play = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const GameSDKState = useSelector((state) => state.GameSDK);
    const AuthState = useSelector((state) => state.Auth);
    const GameState = useSelector((state) => state.Game);

    const { initJsResponse, token: sdkToken, loading: sdkLoading } = GameSDKState;
    const { gameData } = GameState;

    const { gameId } = useParams();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setupGame = async () => {
            // Skip if gameData is already available
            if (gameData && Object.keys(gameData).length > 0) {
                setLoading(false);
                return;
            }

            try {
                // Step 1: Fetch game details
                await new Promise((resolve) => {
                    dispatch(fetchGameAppDataAction({ app_id: gameId }, resolve));
                });

                // Step 2: Check participation
                await new Promise((resolve, reject) => {
                    const handleParticipationSuccess = resolve;
                    const handleParticipationReject = () => {
                        navigate(-1); // Go back to the previous component in the stack
                        reject();
                    };
                    dispatch(checkGameParticipationAction({ app_id: gameId }, handleParticipationSuccess, handleParticipationReject));
                });

                setLoading(false);
            } catch (error) {
                console.error("Error during game setup:", error);
            }
        };

        setupGame();
    }, [dispatch, gameId, gameData, navigate]);

    useEffect(() => {
        // Fetch balance when component unmounts
        return () => {
            if (AuthState?.token) {
                dispatch(fetchBalanceAuthAction());
            }
        };
    }, [dispatch, AuthState?.token]);

    const goBack = () => navigate(-1);

    if (loading || sdkLoading) {
        return <Loader isTranserent={true} />;
    }

    return (
        <div style={{ width: "100%", height: "100vh" }}>
            <GamePlayIFrameComponent
                appId={gameData?.id}
                gameRoute={gameData?.game}
                token={initJsResponse?.token || sdkToken}
                authToken={AuthState?.token}
            />
            <div
                className="flex flex-row fixed z-[999] left-[15px] top-[50px] opacity-50 cursor-pointer"
                onClick={goBack}
            >
                <img src={back_buttonImg} alt="Back" />
                <img src={green_logoImg} alt="Logo" className="w-[30px] h-[25px]" />
            </div>
        </div>
    );
};

export default React.memo(Play);
