import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import loadable from "@loadable/component";

const Footer = loadable(() => import("../components/Footer"));
const MiniHeader = loadable(() => import("../components/MiniHeader"));


const MinimalHeaderContainer = () => {
	const location = useLocation();
	const keyword = "/category/";

	let showCategoryBanner = false;
	if (location.pathname.includes(keyword)) {
		showCategoryBanner = true;
		if (location.pathname.includes("/games")) {
			showCategoryBanner = false;
		}
	}
	let isMobileView = window.innerWidth <= 768; 
	return (
		<>
			<div className="layout_wrapper min-h-screen flex flex-col">
				{!showCategoryBanner && <MiniHeader />}
				<div
					className={`panel_wrp flex-grow  ${
						showCategoryBanner ? "" : "md:pt-[80px] pt-[20px]"
					}`}
				>
					<Outlet />
				</div>
				{!isMobileView && <Footer />}
			</div>
		</>
	);
};

export default MinimalHeaderContainer;
