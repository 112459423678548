import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { ControlBar, Player } from "video-react";

import gameImg1 from "../../../assets/images/games/samplegameImg.png";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import participationIcon from "../../../assets/images/logo_vector.png";

import {
	formatCmpctNumber, 
	//formatGameDownloadNumber
} from "../../../utils/functions";
import { checkGameParticipationAction } from "../../../redux/actions/gameActions";
import { requestAnalyticsEventAction } from "../../../redux/actions/eventsActions";
import loadable from "@loadable/component";

const StarRating = loadable(() => import("../../../components/UI/StarRating"))

const GameBannerMobile = ({
	isLoggedIn = false,
	featuredUrl,
	gameData,
	screenshots,
	hostUrl,
	averageRatingStar,
	handleParticipationReject
}) => {
	const [isDiplayScreenshot, setIsDiplayScreenshot] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const checkLoginNrediect = () => {
		if (isLoggedIn) {
			dispatch(requestAnalyticsEventAction({eventType:"request",event:"pageview",event_value:`Game_Play_GPage_${gameData.name}`,event_parent:"page",desc:"Analytics Page View"}));      
			const callbackFun = () => {
				navigate(`/play/${gameData?.id}`);
			};
			dispatch(
				checkGameParticipationAction(
					{ app_id: gameData?.id },
					callbackFun,handleParticipationReject
				)
			);
		} else {
			localStorage.setItem("lastPlayReqGameId", gameData?.id);
			navigate(`/login`);
		}
	};

	const updateBannerScreenShot = (index) => {
		setIsDiplayScreenshot(true);
		setActiveIndex(index + 2);
	};

	return (
		<main>
			<div>
				<div className="overflow-hidden min-h-[100dvh] relative pt-16 flex items-end">
					{isDiplayScreenshot &&
					screenshots &&
					screenshots.length > 0 ? (
						<div className="flex flex-row justify-center items-center absolute inset-0">
							<img
								src={
									screenshots[activeIndex - 2]
										? `${hostUrl}${
												screenshots[activeIndex - 2]
										  }`
										: gameImg1
								}
								alt="gameIcon"
								title=""
								height="500"
								width="300"
								className="absolute top-0  w-full h-full object-cover"
							/>
						</div>
					) : (
						<video
							autoPlay={true}
							muted
							loop
							playsInline
							height="500"
							className="absolute h-full w-full object-cover left-0 top-0"
							poster={`${hostUrl}${gameData?.image}`}
						>
							<source src={featuredUrl} type="video/mp4" />
						</video>
						// <Player autoPlay muted  loop  playsInline={false} fluid={true} preload="auto"  src={featuredUrl}   className="absolute"  >
						// 	<ControlBar disableCompletely={true} ></ControlBar>
						// </Player>
					)}

					<div className="gamePagwbanner-overlay flex flex-col justify-between w-full py-2 container">
						<h1 className="text-[28px] text-white mb-2 relative black-drop-shadow">
							{gameData?.name}
						</h1>
						<div className="left-text max-w-[450px] w-full relative flex items-start gap-2 black-drop-shadow">
							<div className="rounded-xl overflow-hidden w-[70px] aspect-square">
								<img
									src={
										gameData?.icon
											? `${hostUrl}${gameData?.icon}`
											: gameImg1
									}
									alt="gameIcon"
									title=""
									className="h-full w-full bg-gray-950 object-cover"
									height={120}
									width={150}
								/>
							</div>
							<div className="flex-1">
								<div className="gamePage-ratingrow py-1 text-white items-center">
									<span className="block text-sm mb-2">
										<strong className="pr-1">
											<span className="text-[#00e6f4]">
												{formatCmpctNumber(gameData?.play_count)}
											</span>
											{gameData?.play_count ?<span>+</span>: ""}
										</strong>
										PLAYS
									</span>
									<h2 className="flex justify-between mb-2">
										<span className="ratingstar mr-2 startRating">
											{" "}
											<StarRating
												size={`text-xl`}
												ratings={averageRatingStar}
											/>
										</span>
										<span className="text-xs">
											Rating
											<span className="ratingcounter ml-2">
												{averageRatingStar}
												<span> / 5</span>
											</span>
										</span>
									</h2>
								</div>
								<div className="flex py-1 text-white items-center">
									<div className="devname text-[10px]">
										<strong>Developer :</strong>{" "}
										{gameData?.developer}
									</div>
								</div>
							</div>
						</div>
						<div className="right-text rounded-[15px] flex-column relative py-2 black-drop-shadow">
							<ul className="slider-img flex gap-2 pt-2 screenshots">
								<li
									className={`${
										activeIndex === 0 ? `active` : ``
									}`}
									onClick={() => {
										setIsDiplayScreenshot(false);
										setActiveIndex(0);
									}}
								>
									<img
										src={
											gameData?.image
												? `${hostUrl}${gameData?.image}`
												: gameImg1
										}
										alt="game-icon"
										title=""
									/>
								</li>
								{screenshots &&
									screenshots.length > 0 &&
									screenshots.map((ss, i) => {
										return (
											<li
												onClick={() =>
													updateBannerScreenShot(i)
												}
												className={`${
													activeIndex === i + 2
														? `active`
														: ``
												}`}
												key={`screenshot_${i}`}
											>
												<img
													src={
														ss
															? `${hostUrl}${ss}`
															: gameImg1
													}
													alt="game-screenshot"
													title=""
													width={90}
												/>
											</li>
										);
									})}
							</ul>
							<button
								className="bg-[#00e6f4] py-[10px] px-[15px]  flex flex-col items-center  rounded-[20px] cursor-pointer w-full mt-3"
								onClick={checkLoginNrediect}
							>
								<div className="flex flex-row items-center uppercase gap-4">
									<div className="flex gap-1 items-center">
										<h1>Play</h1>
										<div>
											<FeatherIcon
												icon="triangle"
												className="rotate-90 fill-[#000] ml-1 mr-0 mb-0 "
												size={10}
											/>
										</div>
									</div>
									{
										gameData?.user?.id != '' && gameData?.participation !== 0 && (
											<span className="gameParticipationSection flex items-center text-[11px] bg-[#ffff] text-[#000] rounded-[20px] gap-2" style={{padding:"6px 12px"}}>
												<img src={participationIcon} alt="gameIcon" width={20} height={20} style={{borderRadius:"50%"}} />
												{gameData?.participation}
											</span>
										)
									}
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default GameBannerMobile;
