export const GET_CATEGORY_DETAIL_REQUEST = "GET_CATEGORY_DETAIL_REQUEST";
export const GET_CATEGORY_DETAIL_SUCCESS = "GET_CATEGORY_DETAIL_SUCCESS";
export const GET_CATEGORY_DETAIL_ERROR = "GET_CATEGORY_DETAIL_ERROR";

export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST";
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
export const GET_ALL_CATEGORY_ERROR = "GET_ALL_CATEGORY_ERROR";


export const GET_CUSTOM_CATEGORY_DETAIL_REQUEST = "GET_CUSTOM_CATEGORY_DETAIL_REQUEST";
export const GET_CUSTOM_CATEGORY_DETAIL_SUCCESS = "GET_CUSTOM_CATEGORY_DETAIL_SUCCESS";
export const GET_CUSTOM_CATEGORY_DETAIL_FAILED = "GET_CUSTOM_CATEGORY_DETAIL_FAILED";

export const gateCategoryDetailAction = (data) => {
	return { type: GET_CATEGORY_DETAIL_REQUEST, payload: data };
};

export const getAllCategoryAction = () => {
	return { type: GET_ALL_CATEGORY_REQUEST };
}; 

export const getCustomCategoryDetailAction = (data) => {
	return { type: GET_CUSTOM_CATEGORY_DETAIL_REQUEST, payload: data };
};