import { ChevronLeft, ChevronUp } from 'feather-icons-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const HeaderWithBackTopArrow = () => {
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <header className='bg-transparent py-3 fixed top-0 w-full z-10 sm:hidden'>
            <div className='container'>
                <div className='flex justify-between black-drop-shadow'>
                    <ChevronLeft size="35" color="#00e6f4" onClick={() => navigate(-1)} />
                    <ChevronUp size="35" color="#00e6f4" onClick={scrollToTop} />
                </div>
            </div>
        </header>
    )
}

export default HeaderWithBackTopArrow