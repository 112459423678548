import { useRoutes,useNavigate } from "react-router-dom";

import { AuthRoutes, Routes ,MinimalHeaderRoute,FullScreenLayoutRoute } from './allRoutes';
import GetAllowedRoutes from './GetAllowedRoutes'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserMessages } from "../redux/actions/messageActions";
// import { TelegramSignInAction } from "../redux/actions/authActions";

const AppRoutes = () => {
    const AuthState = useSelector((state) => state.Auth);
	  const dispatch = useDispatch(); 
    const navigate = useNavigate();


    /** START: Cookiebot - YD-34 -  GDPR Popup for all pages*/
    /*useEffect(() => { 
        const script = document.createElement('script');  
        script.src = 'https://consent.cookiebot.com/uc.js'; 
        script.id = 'Cookiebot';
        script.dataset.cbid = 'ea3f351a-481c-4cb2-aa23-8bfa7f5e18ed';
        script.dataset.blockingmode = 'auto';
        script.type = 'text/javascript'; 
        document.head.appendChild(script);   
        return () => {
        document.head.removeChild(script);
        };
    }, []);  */
    /** END: Cookiebot - YD-34 -  GDPR Popup for all pages*/ 

      
    //   const initializeTelegramWebApp = () => {  
    //     $(document).ready(function () {
    //       if (!localStorage.getItem('token')) {
    //         // Init TWA
    //         try {
    //           window.Telegram.WebApp.ready();
    //           window.Telegram.WebApp.setHeaderColor("#000000", true);
    //           if(window.Telegram){
    //             const user = window.Telegram.WebApp.initDataUnsafe?.user; 
    //             if (user && window.Telegram.WebApp.initData) {
    //               //const { first_name, last_name, username, id } = user; 
    //               //window.Telegram.WebApp.initData
    //               //const theUrl = `https://client-api.akedo.gg/tg_login?initData=${window.Telegram.WebApp.initData}`;
    //               console.log(`369---->call api TelegramSignInAction`,window.Telegram.WebApp.initData);
    //               dispatch(TelegramSignInAction({initData:window.Telegram.WebApp.initData}));  
    //             }
    //           }
    //         } catch (err) {
    //           console.log(`369---->err`,err);
    //           //document.getElementById("connected").innerText = err.message;
    //         } 
    //       }
    //     });
    //   };
    
    //   useEffect(() => {
    //      // Load Telegram WebApp script
    //      const script = document.createElement('script');
    //      script.src = 'https://telegram.org/js/telegram-web-app.js';
    //      script.async = true;
    //      script.onload = () => initializeTelegramWebApp();
    //      document.body.appendChild(script);
     
    //      // Load Eruda script for console debugging on mobile browsers
    //      /*const erudaScript = document.createElement('script');
    //      erudaScript.src = 'https://cdn.jsdelivr.net/npm/eruda';
    //      erudaScript.async = true;
    //      erudaScript.onload = () => window.eruda.init();
    //      document.body.appendChild(erudaScript);*/
    //   }, []);
    
    
    

    useEffect(() => { 
        if(AuthState?.token && !localStorage.getItem('isFromCustomCategory')){
            dispatch(getUserMessages());
        }
    }, [AuthState?.token,dispatch,navigate]);  

    const routes = useRoutes(GetAllowedRoutes([...Routes,...AuthRoutes,...MinimalHeaderRoute,...FullScreenLayoutRoute]));
    return routes;
};

export default AppRoutes;