export const SDK_GET_GAME_TOKEN_REQUEST = "SDK_GET_GAME_TOKEN_REQUEST";
export const SDK_GET_GAME_TOKEN_SUCCESS = "SDK_GET_GAME_TOKEN_SUCCESS";
export const SDK_GET_GAME_TOKEN_FAILED = "SDK_GET_GAME_TOKEN_FAILED"; 


export const SDK_INIT_JS_REQUEST = "SDK_INIT_JS_REQUEST";
export const SDK_INIT_JS_SUCCESS = "SDK_INIT_JS_SUCCESS";
export const SDK_INIT_JS_FAILED = "SDK_INIT_JS_FAILED"; 

export const fetchGameSdkTokenAction = (data) => {
	return { type: SDK_GET_GAME_TOKEN_REQUEST, payload: data };
};

export const initJsSDKAction = (data) => {
	return { type: SDK_INIT_JS_REQUEST, payload: data };
};