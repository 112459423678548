import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import loadable from "@loadable/component";
// import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";

import {
	ConnectAction,
	fetchBalanceAuthAction,
} from "../../redux/actions/authActions";
//import { stripeCallbackAction } from "./redux/actions/settingsActions";

const VideoBannerComponent = loadable(() =>
	import("../../components/VideoBanner")
);
const GameSectionComponent = loadable(() => import("./GameSection"));
const CategoriesCarousel = loadable(() =>
	import("../categories/CategoriesCarousel")
);
//import GameSectionCarousel from "./GameSectionCarousel";

const HomepagetTemp = () => {
	const AuthState = useSelector((state) => state.Auth);
	const HomeState = useSelector((state) => state.Home);
	let isLoggedIn = AuthState?.user && AuthState?.token ? true : false;
	// const state = useWeb3ModalState();
	const {
		address,
		//isConnecting, isDisconnected
	} = useAccount();

	// const { selectedNetworkId } = state;

	let gameSectionsArr = HomeState?.sections ? HomeState?.sections : [];
	let lastCategoryIndex;
	if (gameSectionsArr && gameSectionsArr.length > 0) {
		let lastCategoryData = gameSectionsArr[gameSectionsArr.length - 1];
		if (lastCategoryData) {
			lastCategoryIndex = lastCategoryData?.id;
		}
	}
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (
			HomeState?.loaded &&
			window.location?.search &&
			window.location?.search === "?dl=profile" &&
			isLoggedIn
		) {
			dispatch(fetchBalanceAuthAction({}));
			//dispatch(stripeCallbackAction({}));
			navigate(`/`);
		}
	}, [dispatch, navigate, HomeState?.loaded, isLoggedIn]);

	useEffect(() => {
		if (address) {
			let payload = {
				token: "",
				wallet: address,
				wallet_type: "metamask",
				extra: {},
			};
			const successCallback = () => {
				navigate("/");
			};
			dispatch(ConnectAction(payload, successCallback));
		}
	}, [selectedNetworkId, address, dispatch, navigate]);

	useEffect(() => {
		// Create a new script element
		const script = document.createElement('script');
		
		// Set the content of the script
		// script.textContent = ` 
		//   console.log('Inline script executed');
		//   ! function() {
		// 	window.semaphore = window.semaphore || [], window.ketch = function() {
		// 		window.semaphore.push(arguments)
		// 	};
		// 	var e = new URLSearchParams(document.location.search),
		// 		o = e.has("property") ? e.get("property") : "website_smart_tag",
		// 		n = document.createElement("script");
		// 	n.type = "text/javascript", n.src = "https://global.ketchcdn.com/web/v2/config/akedo/".concat(o, "/boot.js"), n.defer = n.async = !0, document.getElementsByTagName("head")[0].appendChild(n)
		// }();`; 

		script.src = 'https://consent.cookiebot.com/uc.js'; 
		script.id = 'Cookiebot';
		script.dataset.cbid = 'ea3f351a-481c-4cb2-aa23-8bfa7f5e18ed';
		script.dataset.blockingmode = 'auto';
		script.type = 'text/javascript';
	 
		
		// Append the script to the <head> element of the document
		document.head.appendChild(script);
		
		// Clean up function to remove the script when the component unmounts
		return () => {
		  document.head.removeChild(script);
		};
	  }, []); 

	let bannerVideoDataNew;
	if (isLoggedIn && AuthState?.featured && AuthState?.featured.length > 0) {
		bannerVideoDataNew = AuthState?.featured.filter(
			(el) => el?.app?.video !== ""
		);
	} else {
		bannerVideoDataNew = HomeState?.featured.filter(
			(el) => el?.app?.video !== ""
		);
	}

	let isMobileView = window.innerWidth <= 768;

	return (
		<>
			<VideoBannerComponent
				isLoggedIn={isLoggedIn}
				featured={bannerVideoDataNew}
			/>
			
			{gameSectionsArr &&
				gameSectionsArr.length > 0 &&
				gameSectionsArr.map((game, index) => (
					<GameSectionComponent
						key={`game_section_${index + 1}`}
						sectionId={game.id}
						sectionTitle={game.title}
						displayViewAll={true}
						lastCategoryIndex={lastCategoryIndex}
						isMobileView={isMobileView}
					/>
				))}
			<CategoriesCarousel />
		</>
	);
};

export default React.memo(HomepagetTemp);

