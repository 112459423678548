import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import loadable from "@loadable/component";

import gameImg1 from "../../assets/images/games/samplegameImg.png";
import { GetSectionGamesAction } from "../../redux/actions/homeActions";
import { getCloudFrontSourcePath } from "../../utils/functions";

const Loader = loadable(() => import("../../components/UI/Loader"));
const GameCard = loadable(() => import("../../components/UI/GameCard"));

const ViewAllGames = () => {
  const HomeState = useSelector((state) => state.Home);
  const dispatch = useDispatch();
  const { sectionId } = useParams();
  const { activeSectionViewAll, loading } = HomeState;

  useEffect(() => {
    dispatch(GetSectionGamesAction({ section_id: sectionId }));
  }, [sectionId, dispatch]);

  let sectionGamesList = [],
    sectionData = [];
  let sectionDataIndex = activeSectionViewAll.findIndex(
    (x) => x.id === Number(sectionId)
  );
  if (sectionDataIndex >= 0) {
    sectionData = activeSectionViewAll[sectionDataIndex];
    if (sectionData) {
      sectionGamesList = sectionData?.items ? sectionData?.items : [];
    }
  }
  let cloudFrontSourcePath = getCloudFrontSourcePath();

  return (
    <>
      {loading && <Loader isTranserent={true} />}
      <section className="viewAllGame px-0 py-[30px]">
        <div className="container">
          <div className="text-center miniLayoutTilte">
            {" "}
            <h1 className="text-[#fff] text-[32px] tracking-wide   view-all-title">
              {" "}
              {sectionData?.title}{" "}
            </h1>{" "}
          </div>
          <div className="gameCards">
            {sectionGamesList?.map((val, index) => (
              <GameCard
                keyIndex={`game_id_${index}`}
                img={
                  val?.icon ? `${cloudFrontSourcePath}${val?.icon}` : gameImg1
                }
                game={val}
                key={`view_allgames_${index}`}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(ViewAllGames);
