import axios from 'axios';

export const Analytics = {
  trackConnectClicked(origin) {
    this._send('connect', `click-${origin}`);
  },

  trackConnectCompleted(userType) {
    this._send('logged', 'successful');
    if (userType) {
      userType = `_${userType}`;
    }
    this.trackPage(`Wallet_Connect_Confirmed${userType}`);
  },

  trackLogout() {
    this._send('logged', 'out');
  },

  trackSeeAllGames() {
    this._send('all', 'games');
  },

  trackPlayButtonClicked() {
    this._send('play', 'click');
  },

  trackCategoryClick(name) {
    this._send(name, 'click');
  },

  trackPage(pageTitle) {
    console.log(`Tracking page: ${pageTitle}`);
    // Send to GTM
    if (typeof window.page === 'function') {
      window.page(pageTitle); // Calls the page function from index.html
    }

    // Send to your server
    this._sendEvent(pageTitle, 'page');
  },

  _send(event, category) {
    if (typeof window.track === 'function') {
      window.track(event, category, 'not set'); // Calls the track function from index.html
    }
    this._sendEvent(event, category);
  },

  _sendEvent(event, category) {
    // const timestamp = Date.now();
    // const payload = {
    //   timestamp,
    //   eventType: 'request',
    //   event: 'pageview',
    //   event_value: event,
    //   event_parent: category,
    //   desc: 'Analytics Page View',
    // };

    // axios.post('/api/events', payload).catch((error) => {
    //   console.error('Failed to send analytics event:', error);
    // });
  },
};

export default Analytics;
