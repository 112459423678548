import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import loadable from "@loadable/component";
import { useNavigate } from "react-router-dom";

//import sampleAvtar from "../../assets/images/sampleAvtar.png";
import PaymentSuccessPopup from "../../components/UI/PaymentSuccessPopup";
import AvatarCanvas from "./elements/AvatarCanvas";
import balanceIcon from "../../assets/images/ic_balance_outline.png";

const TopUpModalComponent = loadable(() =>
  import("../../components/UI/TopUpModalComponent")
);
const Loader = loadable(() => import("../../components/UI/Loader"));
const ProfileInfoForm = loadable(() => import("./elements/ProfileInfoForm"));
const RecentlyPlayedGames = loadable(() =>
  import("./elements/RecentlyPlayedGames")
);

const Settings = () => {
  const [isVisiblePaymentSuccessPoup, setIsVisiblePaymentSuccessPoup] =
    useState(false);
  const [activeTab, setActiveTab] = useState("account");
  const [showTopUp, setShowTopUp] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const AuthState = useSelector((state) => state.Auth);
  const SettingState = useSelector((state) => state.Settings);
  const { user, loginDetails } = AuthState;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onPopupClose = () => {
    setIsVisiblePaymentSuccessPoup(false);
  };

  useEffect(() => {}, [activeTab, user, loginDetails]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [dispatch, navigate]);

  return (
    <div className="py-5">
      {SettingState.loading && <Loader isTranserent={true} />}

      <div className="contentWrapper mx-2">
        <div className="innerSetting text-[#fff] text-center max-w-[550px] mx-auto my-5 mx-auto">
          <AvatarCanvas
            key={""}
            onlyView={true}
            className="h-[120px] w-[120px] object-cover rounded-[50%] mx-[auto] flex justify-center items-center "
            id={`avatarCanvas-${""}`}
            layerCode={selectedAvatar ? selectedAvatar : user.avatar}
            width={100}
            height={100}
            setSelectedAvatar={setSelectedAvatar}
          />
          <h4 className="text-[20px] text-[#fff] mb-[15px]">{user?.username}</h4>

          <div className="topUpButton bg-[#00e6f4] min-w-[170px] inline-flex rounded-[40px] items-center p-0.5 justify-between">
            <span className="d-flex mt-[1px] ml-[10px] text-[#000]">
            <img
                  src={balanceIcon}
                  alt="numberIMg"
                  title="numberIMg"
                  className="w-[20px] h-[18px] mt-[2px] mr-[5px] aspect-square"
                />
              {user?.balance}
            </span>
            
            <button
              className="bg-black text-white text-xs w-20 px-2.5 py-[11px] rounded-[18px] cursor-pointer"
              onClick={() => setShowTopUp(!showTopUp)}
            >
              TOP UP
            </button>
          </div>
          <div className="flex text-white justify-between w-full mt-4 mb-3 mx-0 px-[50px]">
            <span
              className={`${
                activeTab === "account" ? "text-[#00e6f4]" : ""
              } cursor-pointer`}
              onClick={() => setActiveTab("account")}
            >
              {" "}
              Account Settings{" "}
            </span>
            <span
              className={`${
                activeTab === "played" ? "text-[#00e6f4]" : ""
              } cursor-pointer`}
              onClick={() => setActiveTab("played")}
            >
              {" "}
              Played Games{" "}
            </span>
          </div>
          {activeTab && activeTab === "account" ? (
            <ProfileInfoForm
              setSelectedAvatar={setSelectedAvatar}
              selectedAvatar={selectedAvatar}
            />
          ) : (
            <RecentlyPlayedGames />
          )}
        </div>
        <TopUpModalComponent
          show={showTopUp}
          onClose={setShowTopUp}
          isVisiblePaymentSuccessPoup={isVisiblePaymentSuccessPoup}
          setIsVisiblePaymentSuccessPoup={setIsVisiblePaymentSuccessPoup}
        />

        {isVisiblePaymentSuccessPoup && (
          <PaymentSuccessPopup
            show={isVisiblePaymentSuccessPoup}
            onPopupClose={onPopupClose}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(Settings);
