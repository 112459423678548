import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import loadable from "@loadable/component";
import { useDispatch, useSelector } from "react-redux";

import { useAccount } from "wagmi"; 
import { useNavigate } from "react-router-dom";
import { LoadHomDataAction } from "../redux/actions/homeActions";
import { ConnectAction } from "../redux/actions/authActions";
// import HeaderGameDetail from "../components/HeaderGameDetail";
import UserMenuElement from "../components/UI/UserMenuElement";
import HeaderWithBackTopArrow from "../components/HeaderWithBackTopArrow";

// import back_buttonImg from '../assets/images/back_button.png';
// import green_logoImg from '../assets/images/green_logo.png';


const Footer = loadable(() => import("../components/Footer"));
//const HeaderGameDetail = loadable(() => import("../components/HeaderGameDetail"));

const GameDetailContainer = () => {  
	const [showOverlay, _] = useState(false); // eslint-disable-line no-unused-vars
	const AuthState = useSelector((state) => state.Auth); 
	const { user } = AuthState;

	const dispatch = useDispatch();
	const navigate = useNavigate();
 
	const {
		address,
		//isConnecting, isDisconnected
	} = useAccount();
 

	useEffect(() => {
		let web3Token = localStorage.getItem('web3-token');
		if (address && !web3Token) {   
			let payload = {
				token: "",
				wallet: address,
				wallet_type: "metamask",
				extra: {},
			};
			const successCallback =()=>{
				dispatch(LoadHomDataAction()); 
				navigate("/");
			}
			dispatch(ConnectAction(payload, successCallback));
		}
	}, [address, dispatch, navigate]);

	let isFromCustomCategory =false;
	if(localStorage.getItem('isFromCustomCategory')){
		isFromCustomCategory = true;
	}

	//const goBack =()=>  navigate(-1);

	if(isFromCustomCategory){
		return (
			<>
				<div className="layout_wrapper gameDetailContainer"> 
						{/* <div className="flex flex-row fixed z-[999] left-[15px] top-[50px] opacity-50 cursor-pointer" onClick={goBack}>
							<image src={back_buttonImg} alt=""  />
							<img src={green_logoImg} alt="playBtnIcon" className="w-[30px] h-[25px]"  />
						</div>  */}
					<div className="panel_wrp">
						<Outlet />
					</div>
				</div>
			</>
		);
	}
	return (
		<>
			<div className="layout_wrapper gameDetailContainer">
				{/* <HeaderGameDetail showOverlay={showOverlay} setShowOverlay={setShowOverlay}  /> */}
				<HeaderWithBackTopArrow />
				<div className="panel_wrp">
					<Outlet />
				</div>
				<Footer />
			</div>
			{showOverlay &&  <UserMenuElement user={user} /> }
		</>
	);
};

export default GameDetailContainer;