import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import loadable from "@loadable/component";
//import FeatherIcon from "feather-icons-react/build/FeatherIcon";
//import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { FaArrowRightLong } from "react-icons/fa6";
//import { FaArrowRight } from "react-icons/fa";

import { fetchGameAppDataAction } from "../../redux/actions/gameActions";
//import { fetchBalanceAuthAction } from "../../redux/actions/authActions";
import {
	calculateAverageRatingStar,
	//getCategoryDetailsById,
	getCloudFrontSourcePath,
} from "../../utils/functions";

import { IoIosArrowBack } from "react-icons/io"; 
import ic_siteImg from "../../assets/images/ic_site.png";
import ic_emailImg from "../../assets/images/ic_email.png";
import ic_privacyImg from "../../assets/images/ic_privacy.png";
import ic_addressImg from "../../assets/images/ic_address.png";
import GameBanner from "./elements/GameBanner";
//import GameBannerMobile from "./elements/GameBannerMobile";
import CustomCategoryGameBannerMobile from "./elements/CustomCategoryGameBannerMobile";

const StarRating = loadable(() => import("../../components/UI/StarRating"));
const TopUpModalComponent = loadable(() => import("../../components/UI/TopUpModalComponent"));
const Loader = loadable(() => import("../../components/UI/Loader"));
const CustomCategoryLeaderboards = loadable(() => import("./elements/CustomCategoryLeaderboards"));
const AppRatingModalComponent = loadable(() => import("../../components/UI/AppRatingModalComponent"));

// const GameBanner = loadable(() => import("./elements/GameBanner"));
// const GameBannerMobile = loadable(() => import("./elements/GameBannerMobile"));

const CustomCategoryGameDetailPage = () => {
	const [showTopUp, setShowTopUp] = useState(false);
	const [rateModalVisible, setRateModalVisible] = useState(false);
	const GameState = useSelector((state) => state.Game);
	const AuthState = useSelector((state) => state.Auth);
	//const HomeState = useSelector((state) => state.Home);
	const { gameId } = useParams();
	//const { categoryList } = HomeState;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { gameData, loading, ratingLoader, gameVideoUrl } = GameState;
	let isLoggedIn = Boolean(AuthState?.user && AuthState?.token);

	useEffect(() => {
		dispatch(fetchGameAppDataAction({ app_id: gameId }));
	}, [gameId, dispatch]);

	let cloudFrontSourcePath = getCloudFrontSourcePath();

	//gameData.desc = `Title: Marble Boom - A Colorful Puzzle Adventure\n\nDescription:\nGet ready to embark on a thrilling journey through a vibrant and challenging world of marbles in \"Marble Boom\"! This exciting puzzle game takes inspiration from the classic Zuma-style gameplay and adds its own unique twist to keep you engaged for hours of marble-matching fun.\n\n🌈 Match, Aim, and Explode:\nYour mission in Marble Boom is to eliminate a chain of colorful marbles before they reach their destination. Take control of a powerful cannon, loaded with an assortment of colorful balls, and aim precisely to create matches of three or more marbles of the same color. The more matches you make, the bigger your score and the more explosive power you unleash!\n\n💥 Power-Ups Galore:\nAs you progress through the game, you'll encounter a variety of exciting power-ups and bonuses. From laser beams that cut through marbles to multi-colored bombs that create epic chain reactions, these power-ups will help you clear levels with style. Strategically using these power-ups is the key to mastering Marble Boom.\n\nGet ready to experience the ultimate marble-shooting adventure! Marble Boom is the perfect blend of strategy, precision, and entertainment, designed to captivate players of all ages. Can you master the art of marble matching and save the colorful world from impending chaos? Play Marble Boom now and start your journey today!\n`;
	let gameDescHtml;
	if (gameData.desc) {
		gameDescHtml = gameData.desc.replace(/\n/g, "<br>");
	}

	// const handleCategoryClick = () => {
	// 	navigate(`/category/${gameData?.categoryId}`);
	// };

	let ratingData;
	if (gameData?.rating) {
		ratingData = calculateAverageRatingStar(gameData?.rating, 1, true);
	}

	const handleRateReviewClick = () => {
		setRateModalVisible(!rateModalVisible);
	};

	const handleParticipationReject = (res) => {
		//console.log(`log123----->handleParticipationReject`,res);
		setShowTopUp(true);
	};

	const goBack=()=>{
        navigate(-1);
    }

	let isMobileView = window.innerWidth <= 768; 
 
	let filterScreenShotArr = gameData?.screenshots?.filter(screenshot => screenshot !=="" && screenshot !==null);
 
	return (
		<div>
			{loading ? (
				<Loader isTranserent={true} />
			) : (
				<>	
					<div className="header flex items-center justify-between min-h-[60px] px-4">
						<button className="flex items-center text-white text-xs p-2" onClick={goBack}> 
							<IoIosArrowBack size={32} color="#00e6f4" />
						</button>
					</div>
					{isMobileView ? (
						<CustomCategoryGameBannerMobile
							isLoggedIn={isLoggedIn}
							featuredUrl={gameVideoUrl}
							gameData={gameData}
							screenshots={filterScreenShotArr}
							hostUrl={cloudFrontSourcePath}
							averageRatingStar={parseFloat(
								ratingData?.averageRating
							).toFixed(1)}
							handleParticipationReject={
								handleParticipationReject
							}
						/>
					) : (
						<GameBanner
							isLoggedIn={isLoggedIn}
							featuredUrl={gameVideoUrl}
							gameData={gameData} 
							screenshots={filterScreenShotArr}
							hostUrl={cloudFrontSourcePath}
							averageRatingStar={parseFloat(
								ratingData?.averageRating
							).toFixed(1)}
							handleParticipationReject={
								handleParticipationReject
							}
						/>
					)}
					<div className="container pt-4 customCategoryGameDetailMobile ">
						<Row xs={1} md={2}>
							<Col md={6}>
								<div className="flex flex-col aboutGameSection">
									<h1 className="text-white text-[36px]">
										{" "}
										About this game{" "}
									</h1>
									<div> 
										<div
											className="text-[#B0B0B0] text-[14px] "
											dangerouslySetInnerHTML={{
												__html: gameDescHtml,
											}}
										/>

										<div className="text-white text-[14px] mt-2 mb-[38px]">
											Updated on{" "}
											<span className="text-[#B0B0B0]">
												{" "} 
												{gameData?.versionDate ? new Date(gameData?.versionDate).toDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' }) :''}
											</span>
										</div>
									</div>
									{/* <div className="flex flex-col md:pt-8 pt-0 gap-3">
											<h2 className="text-white text-[18px]">
											Categories / Tags
											</h2>                    
											<div
											className="categoryBtn bg-[#1998a7] w-fit  rounded-[10px] text-white font-normal text-[15px] md:p-1.5  px-2.5 py-[5px] cursor-pointer"
											onClick={handleCategoryClick}
											>
											{getCategoryDetailsById(
												gameData?.categoryId,
												categoryList
											)}
											</div>
										</div> */}

									{/* <h2 className="text-white text-[18px] py-5">
										Playgame Screenshots
									</h2>
									<ul className="flex gap-3 mb-7">
									{gameData?.screenshots &&
										gameData?.screenshots.length > 0 &&
										gameData?.screenshots.map((ss, i) => {
											return (
												<li className="h-[58px] w-[58px] rounded-[6px] overflow-hidden">
													<img
														src={`${cloudFrontSourcePath}${ss}`}
														alt="game-screenshot"
														title=""
														className="w-full h-full"
													/>
												</li>
											);
										})} 
									</ul> */}
									
									<div className="pb-5">
										{gameData?.id && (
											<CustomCategoryLeaderboards
												gameId={gameData?.id}
												isFromGameDetail={true}
												isFromCustomCategory={true}
											/>
										)}
									</div>
									

									<div className="app-rating-section flex flex-column">
										<h2 className="text-white text-[18px] py-2">
											Ratings and reviews
										</h2>
										{!isMobileView && (
											<div className="flex flex-row text-white justify-between items-center">
												<h1 className="text-white text-[20px]">
													{" "}
													Ratings and reviews{" "}
												</h1>
												<button
													className="bg-[#000] p-1 text-xs text-[#ffff]  w-[150px]  rounded-[20px] cursor-pointer hover:bg-[#ffff] hover:text-[#000] uppercase border border-solid border-[#1998a7] rate-review-button"
													onClick={
														handleRateReviewClick
													}
												>
													<span className="star pr-2">
														&#9733;
													</span>
													Rate & Reviews
												</button>
											</div>
										)}

										<div className="flex flex-row startRating gap-2 items-end">
											<span className="text-[#f2994a] text-[52px]">
												{" "}
												{ratingData?.averageRating}{" "}
											</span>
											<StarRating
												ratings={
													ratingData?.averageRating
												}
											/>
											<span className="text-[#4d4d4d]  pb-2.5">
												{gameData?.ratingCount} reviews
											</span>
										</div>
										<div className="flex flex-col gap-2">
											<ul>
												{ratingData?.ratingStat?.map(
													(rate, index) => {
														return (
															<li
																key={`app_rating_data_${index}`}
																className="flex flex-row text-lg text-[#178d9c] gap-1"
															>
																<span className="">
																	{" "}
																	{
																		rate?.rating
																	}{" "}
																</span>
																<span className="star">
																	{" "}
																	&#9733;{" "}
																</span>
																<div className="w-full">
																	<div
																		style={{
																			width: `${rate.perentage}%`,
																			height: "7px",
																			backgroundColor:
																				"#178d9c",
																			marginTop:
																				"11px",
																			borderRadius:
																				"10px",
																		}}
																	></div>
																</div>
																<span className="text-[#777]  pb-2.5">
																	{
																		rate.perentage
																	}
																	%{" "}
																</span>
															</li>
														);
													}
												)}
											</ul>
										</div>

										{isMobileView && (
											<div className="flex flex-row text-white justify-between items-center pb-[30px] mb-[30px] border-b-[#777777] border-b border-solid mt-3">
												<button
													className="bg-[#000] text-xs text-[#ffff]  text-[16px] rounded-[20px] cursor-pointer hover:bg-[#ffff] hover:text-[#000] uppercase border border-solid border-[#1998a7] rate-review-button px-[14px] flex items-center"
													onClick={
														handleRateReviewClick
													}
												>
													<span className="star mr-1 text-[#F2994A] text-[24px]">
														&#9733;
													</span>
													Rate & Reviews
												</button>
											</div>
										)}
 
										<div className="mb-10">
											<ul className="flex gap-1">
												<li>
													<span className="star mr-1 text-[#F2994A] text-[24px]">
														&#9733;
													</span>
												</li>
												<li>
													<span className="star mr-1 text-[#F2994A] text-[24px]">
														&#9733;
													</span>
												</li>
												<li>
													<span className="star mr-1 text-[#F2994A] text-[24px]">
														&#9733;
													</span>
												</li>
												<li>
													<span className="star mr-1 text-[#F2994A] text-[24px]">
														&#9733;
													</span>
												</li>
												<li>
													<span className="star mr-1 text-[#B0B0B0] text-[24px]">
														&#9733;
													</span>
												</li>
											</ul>
											<p className="text-[12px] text-[#B0B0B0]">
												The game is fantastic, the only
												thing about the game that I have
												been experiencing are issues
												when playing the sniper
												character. For some reason the
												aim and firing don't seem to
												work well. The rest of the
												gameplay is workable.{" "}
											</p>
											<h4 className="text-[#fff] mt-[15px]">
												Gven Stefany{" "}
												<span className="text-[12px] text-[#B0B0B0] pl-2">
													17 Oct 2022
												</span>
											</h4>
										</div>

										<div className="mb-[33px]">
											<button className="text-[#00E6F4] flex gap-2 items-center">
												See all reviews{" "}
												<FaArrowRightLong />{" "}
											</button>
										</div>
									</div>

									<div>
										<h2 className="text-white text-[18px] pb-3">
											What's New ?
										</h2>
										<p className="text-white text-[12px]">
											{gameData?.versionComment}
										</p>
									</div>

									<div className="my-10">
										<h3 className="text-[24px] text-[#1998A7] mb-[29px]">
											Developer <span className="text-[#fff]">Information</span>
										</h3>

										<div className="flex text-[16px] text-[#1998A7] gap-4 items-center mb-6">
											<img
												src={`${gameData?.developerIcon}`}
												alt="developerImg" 
												style={{width:'95px'}}
											/>
											{gameData?.developerName}
										</div>
										<p className="text-[#B0B0B0]">{gameData?.developerAbout ? gameData?.developerAbout: '-'}</p>
									</div>

									 

									<div className="app-contact-info flex flex-col gap-2">
										<h3 className="text-[17px] pt-2 text-[#ffff] pb-2">
											{" "}
											Contacts
										</h3>
										<div className="flex flex-col gap-3">
											<div className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
												<img
													src={ic_siteImg}
													alt="gameIcon1"
													title=""
													// height={16}
													// width={16}
													// className="rounded-[10px]"
												/>
												<h3 className="text-[14px]">
													{gameData?.developerWebsite ||
														"-"}
												</h3>
											</div>
											<div className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
												<img
													src={ic_emailImg}
													alt="gameIcon2"
													title=""
													// height={16}
													// width={16}
													// className="rounded-[10px]"
												/>
												<h3 className="text-[14px]">
													{gameData?.developerSupport ||
														"-"}
												</h3>
											</div>
											<div className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
												<img
													src={ic_privacyImg}
													alt="gameIcon3"
													title=""
													// height={16}
													// width={16}
													// className="rounded-[10px]"
												/>
												<h3 className="text-[14px]">
													{gameData?.developerPrivacy ||
														"-"}{" "}
												</h3>
											</div>
											<div className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
												<img
													src={ic_addressImg}
													alt="gameIcon4"
													title=""
													// height={16}
													// width={16}
													// className="rounded-[10px]"
												/>
												<h3 className="text-[14px]">
													{gameData?.developerAddress ||
														"-"}
												</h3>
											</div>
										</div>

									</div>

									 
								</div>
							</Col>
							 
						</Row>

						<section className="viewAllGame pt-4"></section>
					</div>
					{rateModalVisible && (
						<AppRatingModalComponent
							gameImgUrl={`${cloudFrontSourcePath}${
								gameData?.icon ? gameData?.icon : gameData?.icon
							}`}
							gameRatingsArr={gameData?.rating}
							gameData={gameData}
							ratingLoader={ratingLoader}
							gameId={gameId}
							visible={rateModalVisible}
							title={`Rate and Review`}
							onClose={() => setRateModalVisible(false)}
						/>
					)}
					{showTopUp && (
						<TopUpModalComponent
							show={showTopUp}
							onClose={setShowTopUp}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default React.memo(CustomCategoryGameDetailPage);
