import { VERIFYTX_BNB_REQUEST,VERIFYTX_BNB_FAILED,VERIFYTX_BNB_SUCCESS, BNB_CHECK_BALANCE_FAILED,BNB_CHECK_BALANCE_SUCCESS,BNB_CHECK_BALANCE_REQUEST, MATIC_CHECK_BLANNCE_REQUEST, MATIC_CHECK_BLANNCE_SUCCESS, MATIC_CHECK_BLANNCE_FAILED, VERFIY_PURCHASE_FAILED, VERFIY_PURCHASE_REQUEST, VERFIY_PURCHASE_SUCCESS, CMC_CONVERSION_REQUEST, CMC_CONVERSION_SUCCESS, CMC_CONVERSION_FAILED, VERIFYTX_MATIC_REQUEST, VERIFYTX_MATIC_SUCCESS, VERIFYTX_MATIC_FAILED, PURCHASE_INTERVAL_VERIFY_REQUEST, PURCHASE_INTERVAL_VERIFY_SUCCESS, PURCHASE_INTERVAL_VERIFY_FAILED, BNB_CONVERSION_SUCCESS, CLEAR_CONVERSION_DATA, BNB_CONVERSION_REQUEST, BNB_CONVERSION_FAILED } from "../actions/CryptoActions";

const initialState = {
	loading: false,
	cmc_loading: false,
	bnb_loading: false,
	bnbTransaction:{},
	bnbConversionRes:{},
	maticConversionRes:{}
};

export const CryptoReducer = (state = initialState, action) => {
	switch (action.type) {
		case VERIFYTX_BNB_REQUEST:
			return { ...state, loading: true }; 
		case VERIFYTX_BNB_SUCCESS:
			return { ...state, loading: false,  bnbTransaction:action?.payload }; 
		case VERIFYTX_BNB_FAILED:
			return { ...state, loading: false }; 
		case BNB_CHECK_BALANCE_REQUEST:
			return { ...state, loading: true }; 
		case BNB_CHECK_BALANCE_SUCCESS:
			return { ...state, loading: false }; 
		case BNB_CHECK_BALANCE_FAILED:
			return { ...state,loading:false };
		case MATIC_CHECK_BLANNCE_REQUEST:
			return { ...state, loading: true }; 
		case MATIC_CHECK_BLANNCE_SUCCESS:
			return { ...state, loading: false }; 
		case MATIC_CHECK_BLANNCE_FAILED:
			return { ...state,loading:false };
		case VERFIY_PURCHASE_REQUEST:
			return { ...state,loading:true };
		case VERFIY_PURCHASE_SUCCESS:
			return { ...state,loading:false };
		case VERFIY_PURCHASE_FAILED:
			return { ...state,loading:false }; 
		case VERIFYTX_MATIC_REQUEST:
			return { ...state, loading: true }; 
		case VERIFYTX_MATIC_SUCCESS:
			return { ...state, loading: false }; 
		case VERIFYTX_MATIC_FAILED:
			return { ...state, loading: false };
		case PURCHASE_INTERVAL_VERIFY_REQUEST:
			return {...state,loading:true}; 
		case PURCHASE_INTERVAL_VERIFY_SUCCESS:
			return {...state,loading:false};
		case PURCHASE_INTERVAL_VERIFY_FAILED:
			return {...state,loading:false};
		case CMC_CONVERSION_REQUEST:
			return { ...state,cmc_loading:true };
		case CMC_CONVERSION_SUCCESS: 
			return { ...state,cmc_loading:false,maticConversionRes:action?.payload };
		case BNB_CONVERSION_REQUEST:
			return { ...state,bnb_loading:true };
		case BNB_CONVERSION_SUCCESS:
			return { ...state,bnb_loading:false,bnbConversionRes:action?.payload };
		case BNB_CONVERSION_FAILED:
			return { ...state,bnb_loading:false,bnbConversionRes: {} };
		case CMC_CONVERSION_FAILED:
			return { ...state,cmc_loading:false };
		case CLEAR_CONVERSION_DATA:
			return { ...state,loading:false,bnbConversionRes:{},maticConversionRes:{}};
		default:
			return state;
	}
};