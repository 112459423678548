import React from "react"; 
import { createAppKit } from '@reown/appkit/react'
import { arbitrum, mainnet, polygon} from '@reown/appkit/networks'
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { defineChain } from "@reown/appkit/networks";
import { WagmiProvider  } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

/**START: WEB3 CONFIG */
// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId from https://cloud.reown.com
const projectId = `${import.meta.env.VITE_APP_PROJECT_ID}`;
if (!projectId) {
	throw new Error("Please provide PROJECT ID");
} 

// 2. Create a metadata object - optional
const metadata = {
	name: "Akedo Web Games" ,
	description: "Akedo Web Games",
	url: "https://react.akedo.gg",
	icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const bnb = defineChain({
	id: 56, // Binance Smart Chain Mainnet Chain ID
	caipNetworkId: "eip155:56", // CAIP-2 compliant network ID
	chainNamespace: "eip155", // EVM-compatible namespace
	name: "Binance Smart Chain",
	nativeCurrency: {
		decimals: 18,
		name: "Binance Coin",
		symbol: "BNB",
	},
	rpcUrls: {
		default: {
			http: ["https://bsc-dataseed.binance.org/"], // Public RPC endpoint
			webSocket: ["wss://bsc-ws-node.nariox.org"], // Public WebSocket endpoint
		},
	},
	blockExplorers: {
		default: {
			name: "BscScan",
			url: "https://bscscan.com/",
		},
	},
	contracts: {
		// Add any relevant contracts here (optional)
	},
});

// 3. Set the networks
const networks = [
	mainnet,
	arbitrum,
	polygon,
	bnb,
];

// 4. Create Wagmi Adapter
const wagmiAdapter = new WagmiAdapter({
	networks,
	projectId,
	ssr: true
});

// 5. Create modal
createAppKit({
	adapters: [wagmiAdapter],
	networks,
	projectId,
	metadata,
	features: {
		analytics: true,
		email: false, // default to true
		socials: [],
		emailShowWallets: true, // default to true
	  },		
  })

/**END: WEB3 CONFIG */

export function Web3ModalProvider({ children }) {   
	console.log(children); 
    return (
        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
			<QueryClientProvider client={queryClient}>
          		{children}
		  </QueryClientProvider>
        </WagmiProvider>
    );
}