import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import loadable from '@loadable/component'


import gameImg1 from "../../assets/images/games/samplegameImg.png";
import categoryBannerImg from "../../assets//images/category-1.jpg"; 


import { gateCategoryDetailAction } from "../../redux/actions/categoryActions";
import { getCloudFrontSourcePath } from "../../utils/functions";

const Loader = loadable(() => import('../../components/UI/Loader'));
const CategoriesCarousel = loadable(() => import('./CategoriesCarousel'));
const GameCard = loadable(() => import('../../components/UI/GameCard'));

const CategoryGamesListing = () => {
	const CategoryState = useSelector((state) => state.Category);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { categoryid } = useParams();
	const { categoryViewDetails, loading } = CategoryState;

	const handleCategoryViewAllClick = () => {
		navigate(`/category/${categoryid}/games`);
	};

	useEffect(() => {
		dispatch(gateCategoryDetailAction({ cat_id: categoryid }));
	}, [categoryid, dispatch]);

	let gameData = categoryViewDetails?.apps ? categoryViewDetails?.apps : [];

	let cloudFrontSourcePath = getCloudFrontSourcePath();
	let backgroundImageUrl;
	if (categoryViewDetails?.background_image || categoryViewDetails?.image) {
		backgroundImageUrl = `${cloudFrontSourcePath}${
			categoryViewDetails?.background_image
				? categoryViewDetails?.background_image
				: categoryViewDetails?.image
		}`;
	}

	return (
		<>
			{loading 
			?
				<Loader isTranserent={true} />
			:		
				<>
					<section
						className="categoryBanner bg-[#d9e1ec] min-h-[350px] flex items-center"
						// style={{
						// 	backgroundImage: `url(${
						// 		loading
						// 			? categoryBannerImg
						// 			: backgroundImageUrl
						// 			? backgroundImageUrl
						// 			: categoryBannerImg
						// 	})`,
						// }}
					>
						<div className="container">
							<Row className="items-center flex">
								<Col className="absolute top-1/2 -translate-y-1/2 md:relative md:top-0 md:translate-y-0 px-[20px]">
									<div className="md:bg-[#d9e1ec] md:p-2 max-w-full">
										<h1 className="text-[35px] md:text-[40px] leading-[50px]">
											{" "}
											{categoryViewDetails?.name}{" "}
										</h1>
										<div className="content max-w-[510px]">
											{" "}
											<p>
												{categoryViewDetails?.description}
											</p>{" "}
										</div>
									</div>
								</Col>
								<Col className="px-0 md:!px-[15px]">
									<div
										className="h-[350px] bg-[100%] category-game-listing-image"
										style={{
											backgroundSize: "100%",
											backgroundRepeat: "no-repeat",
											backgroundImage: `url(${
												loading
													? categoryBannerImg
													: backgroundImageUrl
													? backgroundImageUrl
													: categoryBannerImg
											})`,
										}}
									/>
								</Col>
							</Row>
						</div>
					</section>
					<section className="categoryPage viewAllGame px-0 py-4">
						<div className="container" >
							<div className="gameCards">
								{gameData.map((game, index) => {
									return (
										<GameCard
											keyIndex={index}
											img={
												game?.icon
													? `${cloudFrontSourcePath}${game?.icon}`
													: gameImg1
											}
											game={game}
											key={`category_section_${index}`}
											subtitle={categoryViewDetails?.name}
										/>
									);
								})}
							</div>
							<div className="text-center mt-4">
								<button
									className="inline-flex items-center text-sm text-white uppercase rounded-full text-lg ml-auto border border-solid border-white px-3 py-1 categoryAllbtn"
									onClick={handleCategoryViewAllClick}
								>
									<span>See All</span>
									<FeatherIcon
										icon="arrow-right"
										className="icon-dual text-white mr-[5px] text-[14px] font-semibold w-5"
										size={14}
									/>
								</button>
							</div>
						</div>
					</section>

					<CategoriesCarousel isCategoryListing={true} />
				</>
			} 
		</>
	);
};

export default React.memo(CategoryGamesListing);
