import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosConfig";

import { GET_ALL_USER_MESSAGES_ERROR, GET_ALL_USER_MESSAGES_SUCCESS, GET_USER_MESSAGES_ERROR, GET_USER_MESSAGES_SUCCESS, SET_USER_MESSAGE_ERROR, SET_USER_MESSAGE_SUCCESS, USER_MESSAGE_READ_ERROR, USER_MESSAGE_READ_SUCCESS } from "../actions/messageActions";
import { TOAST_SHOW } from "../actions/toastActions";
import { checkFunctionRateLimit } from "../../utils/functions";
 
export function* setUserMessageSaga({ payload }) {
	const endpoint = '/api/setusermessage';
	try {
		const { data } = yield call(axios.post, endpoint, payload);
		yield put({ type: SET_USER_MESSAGE_SUCCESS, payload: data });
	} catch {
		yield put({ type: TOAST_SHOW, payload: { message: 'Something went wrong, please try again', severity: 'danger', show: true } });
		yield put({ type: SET_USER_MESSAGE_ERROR });
	}
}

export function* setUserMessageReadSaga({ payload }) {
	if (!checkFunctionRateLimit()) {
		return;
	}

	const endpoint = `/api/userreadmessage`;
	try {
		// Call the API to mark the message as read
		const { data } = yield call(axios.post, endpoint, payload);
		yield put({ type: USER_MESSAGE_READ_SUCCESS, payload: data });

		// After success, call getUserMessagesSaga
		yield call(getUserMessagesSaga);
	} catch {
		// Handle errors
		yield put({
			type: TOAST_SHOW,
			payload: { message: 'Something went wrong, please try again', severity: 'danger', show: true },
		});
		yield put({ type: USER_MESSAGE_READ_ERROR });
	}
}

export function* getUserMessagesSaga(){
	if (!checkFunctionRateLimit()) {
		return;
	}
	// let isFromCustomCategory = localStorage.getItem('isFromCustomCategory');

	// if(!isFromCustomCategory){ 
		const endpoint = `/api/getusermessages`;
		try{
			const { data } = yield call(axios.get,endpoint); 
			yield put({ type: GET_USER_MESSAGES_SUCCESS, payload: data });
		} catch {
			yield put({ type: TOAST_SHOW, payload: { message: 'Something went wrong, please try again', severity: 'danger', show: true } });
			yield put({ type: GET_USER_MESSAGES_ERROR });
		}
	// }else{
	// 	yield put({ type: GET_USER_MESSAGES_ERROR });
	// }
}

export function* getAllUserMessagesSaga({payload}){
	const endpoint = `/api/getallusermessages`;
	try{
		const { data } = yield call(axios.post,endpoint,payload);
		yield put({ type: GET_ALL_USER_MESSAGES_SUCCESS, payload: data });
	} catch {
		yield put({ type: TOAST_SHOW, payload: { message: 'Something went wrong, please try again', severity: 'danger', show: true } });
		yield put({ type: GET_ALL_USER_MESSAGES_ERROR });
	}
}