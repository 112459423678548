import React, { useEffect, useState } from 'react';
import loadable from "@loadable/component";
import { useDispatch, useSelector } from 'react-redux';
import GameBannerMobile from './elements/GameBannerMobile';
import GameBanner from './elements/GameBanner'; 
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";

import { fetchGameAppDataAction } from '../../redux/actions/gameActions';
import { calculateAverageRatingStar, getCategoryDetailsById, getCloudFrontSourcePath } from '../../utils/functions';

import gameImg1 from "../../assets/images/games/samplegameImg.png";
import ic_siteImg from "../../assets/images/ic_site.png";
import ic_emailImg from "../../assets/images/ic_email.png";
import ic_privacyImg from "../../assets/images/ic_privacy.png";
import ic_addressImg from "../../assets/images/ic_address.png";

const Loader = loadable(() => import("../../components/UI/Loader"));
const Leaderboards = loadable(() => import("./elements/Leaderboards"));
const StarRating = loadable(() => import("../../components/UI/StarRating"));
const GameCard = loadable(() => import("../../components/UI/GameCard"));
const AppRatingModalComponent = loadable(() => import("../../components/UI/AppRatingModalComponent"));

const Bannercomponent = ({isMobileView,isLoggedIn,featuredUrl,gameData,cloudFrontSourcePath,ratingData}) => { 
	return isMobileView ? 
	<GameBannerMobile isLoggedIn={isLoggedIn} featuredUrl={featuredUrl} gameData={gameData} screenshots={gameData?.screenshots} hostUrl={cloudFrontSourcePath} averageRatingStar={parseInt( ratingData?.averageRating )}  />
	: 
	<GameBanner isLoggedIn={isLoggedIn} featuredUrl={featuredUrl} gameData={gameData} screenshots={gameData?.screenshots} hostUrl={cloudFrontSourcePath} averageRatingStar={parseInt(ratingData?.averageRating)} />;
}



const DevloperSection = ({ gameData, moreByDeveloper, cloudFrontSourcePath,navigate }) => {
	const {
	  developerIcon, developerName, developerAbout, developerWebsite, developerSupport, developerPrivacy, developerAddress,
	} = gameData || {};

	const handleDevdetailClick = () => {
		navigate(`/dev/${gameData?.developer_id}`);
	};

	const developerContacts = [developerWebsite, developerSupport, developerPrivacy, developerAddress].filter(Boolean);
	const topGames = moreByDeveloper?.slice(0, 3);
	const hasMoreGames = moreByDeveloper?.length > 3;
	
	return (
	  <div className="flex flex-col gap-3">
		<h3 className="text-[24px] pt-2 text-[#ffff]"><span className="text-[#00e6f4]"> Developer </span> Information</h3>
		<h3 className="text-[#00e6f4] text-[18px] flex flex-row gap-1 justify-normal items-center">
		  {developerIcon && <img src={`${developerIcon}`} alt="developerIconImage" width={30} height={20}  />}
		  {developerName}
		</h3>
		<h3 className="text-[#ffff] text-[16px]">{developerAbout}</h3>
		{developerContacts.length > 0 && (
		  <div className="app-contact-info flex flex-col gap-2">
			<h3 className="text-[20px] pt-2 text-[#ffff] pb-2">Contacts</h3>
			{developerContacts.map((contact, index) => (
			  <div key={index} className="flex flex-row text-[#ffff] text-[16px] gap-2 items-center">
				<img src={contact === developerWebsite ? ic_siteImg : contact === developerSupport ? ic_emailImg : contact === developerPrivacy ? ic_privacyImg : ic_addressImg}
				  alt={`developerContacts_${index}`} title="" height={16} width={16} className="rounded-[30px]"   />
				<h3>{contact}</h3>
			  </div>
			))}
		  </div>
		)}
		{topGames?.length > 0 && (
		  <div className="more_from_dev_section flex flex-col gap-2">
			<h3 className="text-[26px] pt-1 text-[#ffff]"><span className="text-[#00e6f4]"> More </span> from {developerName}</h3>
			<div className="gameDetailPageSection">
			  {topGames.map((game, index) => (
				<GameCard key={`apppage_dev_more_gamesection_${index}`} img={game?.icon ? `${cloudFrontSourcePath}${game?.icon}` : gameImg1} game={game} />
			  ))}
			</div>
			{hasMoreGames && (
			  <div className="flex justify-center">
				<button className="moreLoginOptionBtn flex items-center bg-[#00e5f3] hover:text-[#00e5f3] text-[#000] rounded-[20px] pt-1.5 pb-2 px-4 leading-[17px] hover:bg-[#2d2626] cursor-pointer h-10 uppercase max-w-[200px]"
				  onClick={handleDevdetailClick}>
				  See All Games
				  <span className="ml-[auto]"><FaArrowRightLong size={13} /></span>
				</button>
			  </div>
			)}
		  </div>
		)}
	  </div>
	);
};
 
  

const YouMightAlsoLike = ({gameData,moreByCategory,cloudFrontSourcePath,navigate})=>{
	const handleMoreByCategoryClick = () => {
		navigate(`/mightlike`);
	};
	return(
		<section className="viewAllGame pt-4"> 
				<div className="text-center  flex justify-center">
					<div style={{ background: "linear-gradient(90deg, rgba(0,0,0,1) 12%, rgba(3,10,11,1) 51%, rgba(5,16,17,1) 100%)", }} className="w-25" > 
					</div>
					<h2 className="text-[#fff] text-[28px] w-100 p-[25px] font-semibold  bg-gradient-to-t from-[#000000] bg-gradient-to-b to-[#08191c] ">
						You{" "}<span className="text-[#00e6f4]">might</span> also like
					</h2>
					<div className="w-25" style={{background:"linear-gradient(90deg, rgba(5,16,17,1) 12%, rgba(3,10,11,1) 51%, rgba(0,0,0,1) 100%)"}} ></div>
				</div>
				<div className="gameCards">
					{moreByCategory &&
						moreByCategory?.length > 0 &&
						moreByCategory
							.slice(0, 8)
							.map((game, index) => {
								return (
									<GameCard keyIndex={index} img={game?.icon? `${cloudFrontSourcePath}${game?.icon}`: gameImg1} game={game} key={`category_section_${index}`} subtitle={gameData?.developer} />
								);
							})}
				</div>
				{moreByCategory && moreByCategory?.length > 2 && (
					<div className="flex justify-center"> 
						<button className="moreLoginOptionBtn flex items-center bg-[#00e5f3] text-[#000] rounded-[20px] pt-1.5 pb-2 px-4 leading-[17px] hover:bg-[#2d2626] hover:text-[#00e5f3] cursor-pointer h-10 uppercase max-w-[200px]" onClick={handleMoreByCategoryClick} >
							See All Games <span className="ml-[auto]"> {" "} <FaArrowRightLong size={13} />{" "}</span>
						</button>
					</div>
				)}
			</section>
	)
}

const GameDetailContent = ({gameData,isMobileView,cloudFrontSourcePath,rateModalVisible,setRateModalVisible,navigate}) => {
	const HomeState = useSelector((state) => state.Home);
	const { categoryList } = HomeState; 

	let moreByCategory = gameData?.more_by_category?.items;
	let moreByDeveloper = gameData?.more_by_developer?.items;

	const { desc: gameDescHtml = "", rating, versionComment, categoryId } = gameData ?? {};
	const ratingData = rating && calculateAverageRatingStar(rating, 1, true);
	const categoryName = getCategoryDetailsById(categoryId, categoryList);
	
	const handleRateReviewClick = () => setRateModalVisible(!rateModalVisible);

	const handleCategoryClick = () => {
		navigate(`/category/${categoryId}`)
	};


	return (
		<div className="container pt-4 ">
			<Row xs={1} md={2}>
				<Col md={6}>
				<div className="flex flex-col aboutGameSection gap-4">
							<h1 className="text-white text-[45px]">About this game</h1>
							<div className="flex flex-col md:pt-8 pt-0 gap-3">
							<h1 className="text-white text-[20px]">Categories / Tags</h1>
							<div className="categoryBtn bg-[#1998a7] w-fit rounded-[10px] text-white font-normal text-[15px] md:p-1.5 px-2.5 py-[5px]" 
								onClick={handleCategoryClick}>{categoryName}</div>
							</div>
					
							{gameData?.id && <Leaderboards gameId={gameData.id} isFromGameDetail />}
					
							<div className="text-white">
								<div dangerouslySetInnerHTML={{ __html: gameDescHtml }} />
							</div>
					
							<div className="text-white">Updated 1 week Ago</div>
					
							
							<div className="app-rating-section flex flex-column">
								{!isMobileView && (
									<div className="flex flex-row text-white justify-between items-center">
										<h1 className="text-white text-[20px]"> Ratings and reviews </h1>
										<button className="bg-[#000] p-1 text-xs text-[#ffff]  w-[150px]  rounded-[20px] cursor-pointer hover:bg-[#ffff] hover:text-[#000] uppercase border border-solid border-[#1998a7] rate-review-button" onClick={ handleRateReviewClick } >
											<span className="star pr-2">
												&#9733;
											</span>
											Rate & Reviews
										</button>
									</div>
								)}

								<div className="flex flex-row startRating gap-2 items-end">
									<span className="text-[#f2994a] text-[52px]"> {ratingData?.averageRating} </span>
									<StarRating ratings={ ratingData?.averageRating } />
									<span className="text-[#4d4d4d]  pb-2.5">
										{gameData?.ratingCount} reviews
									</span>
								</div>
								<div className="flex flex-col gap-2">
									<ul>
										{ratingData?.ratingStat?.map( (rate, index) => {
												return (
													<li key={`app_rating_data_${index}`} className="flex flex-row text-lg text-[#178d9c] gap-1 pr-[100px]" >
														<span className=""> {rate?.rating } </span>
														<span className="star"> {" "} &#9733; </span>
														<div className="w-full"> 
															<div style={{ width: `${rate.perentage}%`, height: "7px", backgroundColor: "#178d9c", marginTop: "11px", borderRadius: "10px", }} ></div>
														</div>
														<span className="text-[#777]  pb-2.5">{rate.perentage }% </span>
													</li>
												);
										})}
									</ul>
								</div>

								{isMobileView && (
									<div className="flex flex-row text-white justify-between items-center">
										<button className="bg-[#000] p-1 text-xs text-[#ffff]  w-[150px]  rounded-[20px] cursor-pointer hover:bg-[#ffff] hover:text-[#000] uppercase border border-solid border-[#1998a7] rate-review-button" onClick={ handleRateReviewClick } >
											<span className="star pr-2"> &#9733; </span> Rate & Reviews
										</button>
									</div>
								)}
							</div>
							<div className="app-rating-section flex flex-column gap-3">
								<h1 className="text-white text-[20px]">What's New ?</h1>
								<div className="text-white text-[14px]">{versionComment}</div>
							</div>
						</div>
				</Col>
				<Col md={1}></Col>
				<Col md={5}>
					<DevloperSection gameData={gameData} moreByDeveloper={moreByDeveloper} cloudFrontSourcePath={cloudFrontSourcePath} navigate={navigate} />
				</Col>
			</Row>
			<YouMightAlsoLike gameData={gameData} moreByCategory={moreByCategory} cloudFrontSourcePath={cloudFrontSourcePath} navigate={navigate} />
		</div>
	); 
};


const GameDetailPageOptimized = () => { 
	const [rateModalVisible, setRateModalVisible] = useState(false);

	const GameState = useSelector((state) => state.Game);
	const AuthState = useSelector((state) => state.Auth); 
	const { gameData, loading, ratingLoader } = GameState; 
	const gameVideoUrl = GameState?.gameVideoUrl; 
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { gameId } = useParams();
	
	let isLoggedIn = Boolean(AuthState?.user && AuthState?.token);  
	let cloudFrontSourcePath = getCloudFrontSourcePath();
	

    useEffect(() => { 
		if(gameId){
			dispatch(fetchGameAppDataAction({ app_id: gameId })); 
		}
	}, [gameId, dispatch]);

	const { rating } = gameData ?? {};
	const ratingData = rating && calculateAverageRatingStar(rating, 1, true);
	const isMobileView = window.innerWidth <= 768;

	return (
		<div>
			{
				loading 
				? 
					<Loader isTranserent={true} />
				: 
				<>
					<Bannercomponent gameData={gameData} featuredUrl={gameVideoUrl} isMobileView={isMobileView} isLoggedIn={isLoggedIn} cloudFrontSourcePath={cloudFrontSourcePath} navigate={navigate} ratingData={ratingData} />
					<GameDetailContent gameData={gameData} isMobileView={isMobileView} cloudFrontSourcePath={cloudFrontSourcePath} rateModalVisible={rateModalVisible} setRateModalVisible={setRateModalVisible} navigate={navigate} /> 
					{rateModalVisible && (
						<AppRatingModalComponent gameImgUrl={`${cloudFrontSourcePath}${gameData?.icon ? gameData?.icon : gameData?.icon }`} gameRatingsArr={gameData?.rating} gameData={gameData} ratingLoader={ratingLoader} gameId={gameId} visible={rateModalVisible} title={`Rate and Review`} onClose={() => setRateModalVisible(false)} />
					)}
				</>
			} 
		</div>
	);
};

export default React.memo(GameDetailPageOptimized);
