import { configureStore, Tuple } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import rootSagas from './sagas';
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from './reducers';
import logger from 'redux-logger'

const sagaMiddleware = createSagaMiddleware(); // creates redux saga middleware
const browserWindow = window;

const rootConfig = {
  key: "root",
  storage,
  whitelist: ["Auth"],
};

const persistedReducer = persistReducer(rootConfig, rootReducer);

const isDevelopment = import.meta.env.VITE_APP_ENV === 'development';

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = [sagaMiddleware];
    if (isDevelopment) {
      middlewares.push(logger); // Add logger only in development
    }
    return getDefaultMiddleware({ serializableCheck: false }).concat(middlewares);
  },
  devTools:
    browserWindow.__REDUX_DEVTOOLS_EXTENSION__ &&
    browserWindow.__REDUX_DEVTOOLS_EXTENSION__(),
});

sagaMiddleware.run(rootSagas);

let persistor = persistStore(store);

export { store, persistor }; 