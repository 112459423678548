import React from "react";
import FeatherIcon from "feather-icons-react";

import sampleAvtar from "../../assets/images/sampleAvtar.png";
import settingsIcon from "../../assets/images/other/ic_settings.png";
import balanceIcon from "../../assets/images/ic_balance_outline.png";
import logoutIcon from "../../assets/images/other/ic_logout.png";
import { LogoutAction } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { useDispatch } from "react-redux";
import AvatarCanvas from "../../views/settings/elements/AvatarCanvas";

const UserMenuElement = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { disconnect,disconnectAsync } = useDisconnect();
  const {
		isConnected
	} = useAccount();

  const handleLogout = () => {
    if(isConnected){
      disconnectAsync().then(() => { 
        localStorage.clear();
        dispatch(LogoutAction(disconnect));  
      });
    }else{
      dispatch(LogoutAction(disconnect));
    }
  };

  return (
    <>
      <div className="userProfile z-10 absolute bg-[#343337] w-[200px] mt-15 text-white text-center mt-[360px] p-[15px] rounded-[10px] right-0">
        <AvatarCanvas
          key={""}
          onlyView={true}
          className="h-20 w-20 bg-[red] mx-auto my-0 rounded-[50%] mx-[auto] flex justify-center items-center "
          id={`avatarCanvas-${""}`}
          layerCode={user.avatar ? user.avatar : sampleAvtar}
          width={80}
          height={80}
        />
        <h5 className="text-[18px] mx-0 my-2.5">{user?.username}</h5>
        <div className="mb-3">
          <span className="bg-[#00e6f4] text-black inline-block px-[30px] py-2.5 rounded-[30px] flex justify-center cursor-pointer">            
            <img
              src={balanceIcon}
              alt="settingsIcon"
              title="settingsIcon"
              className="icon-dual text-[#000] mt-[4px] mr-[5px] text-[26px] font-semibold w-[20px] h-[14px]"
            />
            {user?.balance}
          </span>
        </div>
        <div
          className="settingMenubtn  uppercase px-[5px] py-2 rounded-[30px] mb-2 cursor-pointer flex items-center justify-center"
          style={{ border: "1px solid #00e6f4" }}
          onClick={(e) => {
            navigate(`/settings`);
          }}
        >
          {/* <FeatherIcon icon="settings" className="icon-dual text-[#00e6f4] mr-[15px] text-[26px] font-semibold w-5" /> */}
          <img
            src={settingsIcon}
            alt="settingsIcon"
            title="settingsIcon"
            className="mr-[10px]"
          />
          settings
        </div>
        <div
          className="logoutMenubtn uppercase px-[5px] py-2 rounded-[30px] cursor-pointer flex items-center justify-center"
          style={{ border: "1px solid #00e6f4" }}
          onClick={handleLogout}
        >
          {/* <FeatherIcon icon="log-out" className="icon-dual text-[#00e6f4] mr-[5px] text-[26px] font-semibold w-5" /> */}
          <img
            src={logoutIcon}
            alt="logoutIcon"
            title="logoutIcon"
            className="mr-[10px]"
          />
          LOG OUT
        </div>
      </div>

      <div className="overlayLoginOption"></div>
    </>
  );
};

export default UserMenuElement;
