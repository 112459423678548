import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
//import { ControlBar, Player  } from "video-react";

import gameImg1 from "../../../assets/images/games/samplegameImg.png";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
	formatCmpctNumber,
	//formatGameDownloadNumber
} from "../../../utils/functions";
import { checkGameParticipationAction } from "../../../redux/actions/gameActions";
import { requestAnalyticsEventAction } from "../../../redux/actions/eventsActions";
import loadable from "@loadable/component";

const StarRating = loadable(() => import("../../../components/UI/StarRating"));

const CustomCategoryGameBannerMobile = ({
	isLoggedIn = false,
	featuredUrl,
	gameData,
	screenshots,
	hostUrl,
	averageRatingStar,
	handleParticipationReject,
}) => {
	const [isDiplayScreenshot, setIsDiplayScreenshot] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const checkLoginNrediect = () => {
		if (isLoggedIn) {
			dispatch(
				requestAnalyticsEventAction({
					eventType: "request",
					event: "pageview",
					event_value: `Game_Play_GPage_${gameData.name}`,
					event_parent: "page",
					desc: "Analytics Page View",
				})
			);
			const callbackFun = () => {
				navigate(`/play/${gameData?.id}`);
			};
			dispatch(
				checkGameParticipationAction(
					{ app_id: gameData?.id },
					callbackFun,
					handleParticipationReject
				)
			);
		} else {
			localStorage.setItem("lastPlayReqGameId", gameData?.id);
			navigate(`/login`);
		}
	};

	const updateBannerScreenShot = (index) => {
		setIsDiplayScreenshot(true);
		setActiveIndex(index + 2);
	};

	return (
		<main>
			<div className="overflow-hidden min-h-[100vh] relative">
				{isDiplayScreenshot && screenshots && screenshots.length > 0 ? (
					<div className="flex flex-row justify-center items-center">
						<img
							src={
								screenshots[activeIndex - 2]
									? `${hostUrl}${
											screenshots[activeIndex - 2]
									  }`
									: gameImg1
							}
							alt="gameIcon"
							title=""
							height="500"
							width="300"
							className="absolute top-0  w-full h-full object-cover"
						/>
					</div>
				) : (
					<video  
						autoPlay
						muted 
						playsInline 
						loop
						height="500"   
						className="handle absolute h-full w-full object-cover left-0 top-0"  
					>
						<source src={featuredUrl} type="video/mp4" />
					</video>
					// <Player 
					// 	autoPlay
					// 	muted 
					// 	loop 
					// 	playsInline
					// 	fluid={true}
					// 	preload="auto" 
					// 	src={featuredUrl}
					// 	className="absolute" 
					// >
					// 	<ControlBar disableCompletely={true} /> 
					// </Player>
				)}

				<div className="gamePagwbanner-overlay md:flex block justify-between absolute w-full bottom-0 px-[27px] pb-[60px]">
					<div className="left-text flex-row max-w-[450px] w-full relative flex items-center">
						<div className="icon-title mr-2 h-[75px] w-[75px] customCategory-GameBanner overflow-hidden black-drop-shadow">
							<img
								src={
									gameData?.icon
										? `${hostUrl}${gameData?.icon}`
										: gameImg1
								}
								alt="gameIcon"
								title=""
								className="h-[100%] w-[100%] m-0 "
								height={120}
								width={150}
							/>
						</div>
						<h1 className="text-[32px] text-white black-drop-shadow">
							{gameData?.name}
						</h1>
					</div>
					<div className="pt-[14px]">
						<div className="gamePage-ratingrow text-white items-center  text-[large]">
							<div className="text-[17px] pl-[5px] black-drop-shadow">
								<strong className="pr-1">
									<span className="text-[#00e6f4] ">
										{formatCmpctNumber(
											gameData?.play_count
										)}
									</span>
									{gameData?.play_count ? <span>+</span> : ""}
								</strong>
								PLAYS
							</div>
							<h2 className="flex h-auto mt-[5px] mb-[10px] black-drop-shadow">
								<div className="ratingstar mr-3 startRating">
									<StarRating
										size={`text-xl	`}
										ratings={averageRatingStar}
									/>
								</div>
								<span className="text-[16px]">
									Rating
									<span className="ratingcounter ml-2">
										{averageRatingStar}
										<span> / 5</span>
									</span>
								</span>
							</h2>
						</div>
						<div className="flex py-1 text-white items-center  text-[large]">
							<div className="devname custom-category-devname black-drop-shadow ">
								<strong>Developer :</strong>{" "}
								{gameData?.developer}
							</div>
						</div>
						<button
							className="bg-[#00e6f4] py-[10px] px-[15px]  flex flex-col items-center  rounded-[20px] cursor-pointer w-full mt-3 black-drop-shadow"
							onClick={checkLoginNrediect}
						>
							<div className="flex flex-row items-center ">
								<h1>Play</h1>
								<div>
									<FeatherIcon
										icon="triangle"
										className="rotate-90 fill-[#000] ml-1 mr-0 mt-0.5 mb-0 "
										size={10}
									/>
								</div>
							</div>
						</button>
					</div>
					<div className="right-text rounded-[15px] flex-column relative mt-7 black-drop-shadow">
						<ul className="slider-img flex gap-2 screenshots">
							<li
								className={`${
									activeIndex === 0 ? `active` : ``
								}`}
								onClick={() => {
									setIsDiplayScreenshot(false);
									setActiveIndex(0);
								}}
							>
								<img
									src={
										gameData?.image
											? `${hostUrl}${gameData?.image}`
											: gameImg1
									}
									alt="game-icon"
									title=""
								/>
							</li>
							{screenshots &&
								screenshots.length > 0 &&
								screenshots.map((ss, i) => {
									return (
										<li
											onClick={() =>
												updateBannerScreenShot(i)
											}
											className={`${
												activeIndex === i + 2
													? `active`
													: ``
											}`}
											key={`screenshot_${i}`}
										>
											<img
												src={
													ss
														? `${hostUrl}${ss}`
														: gameImg1
												}
												alt="game-screenshot"
												title=""
												width={90}
											/>
										</li>
									);
								})}
						</ul>
					</div>
				</div>
			</div>
		</main>
	);
};

export default CustomCategoryGameBannerMobile;
