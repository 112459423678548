import { UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR, RECENTLY_PLAYED_REQUEST, RECENTLY_PLAYED_SUCCESS, RECENTLY_PLAYED_ERROR, vAKEDO_PRODUCTS_LIST_REQUEST, vAKEDO_PRODUCTS_LIST_SUCCESS, vAKEDO_PRODUCTS_LIST_FAILED, PAYMENT_CHECKOUT_SESSION_REQUEST, PAYMENT_CHECKOUT_SESSION_SUCCESS, PAYMENT_CHECKOUT_SESSION_FAILED, STRIPE_PAYMENT_CONFIRM_REQUEST, STRIPE_PAYMENT_CONFIRM_SUCCESS, STRIPE_PAYMENT_CONFIRM_FAILED } from "../actions/settingsActions";

const initialState = {
	loading: false,
	playedGameLoader: false,
	recentlyPlayedData: [],
	vakedoProductList:[], //used for topup balance option selection
	paymentLoader:false, // payment checkout loader 
	paymentCheckoutRes:{}, // payment checkout response
	paymentConfirmLoader:false,
	paymentConfirmData:{}

};

export const SettingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_USER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case UPDATE_USER_ERROR:
			return {
				...state,
				loading: false,
			};
		case RECENTLY_PLAYED_REQUEST:
			return {
				...state,
				playedGameLoader: true,
			};
		case RECENTLY_PLAYED_SUCCESS:
			return {
				...state,
				playedGameLoader: false,
				recentlyPlayedData: action?.payload,
			};
		case RECENTLY_PLAYED_ERROR:
			return {
				...state,
				playedGameLoader: false,
			};
		case vAKEDO_PRODUCTS_LIST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case vAKEDO_PRODUCTS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				vakedoProductList:action?.payload
			};
		case vAKEDO_PRODUCTS_LIST_FAILED:
			return {
				...state,
				loading: false,
			};
		case PAYMENT_CHECKOUT_SESSION_REQUEST:
			return {
				...state,
				loading: true,
				paymentLoader:true,
				paymentCheckoutRes:{}
			};
		case PAYMENT_CHECKOUT_SESSION_SUCCESS:
			return {
				...state,
				loading: false,
				paymentLoader:false,
				paymentCheckoutRes: action?.payload
			};
		case PAYMENT_CHECKOUT_SESSION_FAILED:
			return {
				...state,
				loading: false,
				paymentLoader:false
			};
		case STRIPE_PAYMENT_CONFIRM_REQUEST:
			return {
				...state,
				paymentConfirmLoader: true
			};
		case STRIPE_PAYMENT_CONFIRM_SUCCESS:
			return {
				...state,
				paymentConfirmLoader: false,
				paymentConfirmData:action?.payload
			};
		case STRIPE_PAYMENT_CONFIRM_FAILED:
			return {
				...state,
				paymentConfirmLoader: false,
				paymentConfirmData: {}
			};
		default:
			return state;
	}
};
