import React from "react";

import AuthContainer from "../containers/AuthContainer";
import FullscreenContainer from "../containers/FullscreenContainer";
import MinimalLayoutContainer from "../containers/MinimalLayoutContainer";
import LayoutContainer from "../containers/LayoutContainer";
import GameDetailContainer from "../containers/GameDetailContainer";
import CustomCategoryGameDetailContainer from "../containers/CustomCategoryGameDetailContainer";

import Homepage from "../views/homepage/Homepage";
import NotFound from "../views/common/NotFound";
import Privacy from "../views/common/Privacy";
import Terms from "../views/common/Terms";
import FAQs from "../views/common/FAQs";
import ViewAllGames from "../views/homepage/ViewAllGames";
import CategoryGamesListing from "../views/categories/CategoryGamesListing";
import CustomCategoryGamesListing from "../views/categories/CustomCategoryGamesListing";
import Login from "../views/login/Login";
import Settings from "../views/settings/Settings";

import Dashboard from "../views/dashboard";
import CategoryAllGames from "../views/categories/CategoryAllGames";
import DiscordCallback from "../views/login/loginOptionElements/DiscordCallback";
import Play from "../views/play/Play";
import GameDetailPage from "../views/Game/GameDetailPage";
import GameDetailPageOptimized from "../views/Game/GameDetailPageOptimized";
import GameMightlike from "../views/Game/elements/GameMightlike";
import MoreFromDev from "../views/Game/elements/MoreFromDev";
import HomepagetTemp from "../views/homepage/HomepagetTemp";
import CustomCategoryGameDetailPage from "../views/Game/CustomCategoryGameDetailPage";
import loadable from "@loadable/component";

const Leaderboards = loadable(() => import("../views/Game/elements/Leaderboards"));
const CustomCategoryLeaderboards = loadable(() => import("../views/Game/elements/CustomCategoryLeaderboards"));

//WITHOUT IN LAYOUT
export const AuthRoutes = [
	{
		path: "/",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <Homepage />,
			},
		],
	},
	{
		path: "/test-page",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <HomepagetTemp />,
			},
		],
	},
	{
		path: "/discord/logincallback",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <DiscordCallback />,
			},
		],
	}, 
	{
		path: "/privacy",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <Privacy />,
			},
		],
	},
	{
		path: "/terms",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <Terms />,
			},
		],
	},
	{
		path: "/faqs",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <FAQs />,
			},
		],
	},
	{
		path: "/custom-cat-game-details/:gameId",
		element: <CustomCategoryGameDetailContainer />,
		children: [
			{
				index: true,
				element: <CustomCategoryGameDetailPage />,
			},
		],
	},
	{
		path: "/details/:gameId",
		element: <GameDetailContainer />,
		children: [
			{
				index: true,
				element: <GameDetailPage />,
			},
		],
	},
	{
		path: "/details-optimized/:gameId",
		element: <GameDetailContainer />,
		children: [
			{
				index: true,
				element: <GameDetailPageOptimized />,
			},
		],
	},
];

//LOGGED IN LAYOUT
export const Routes = [
	{
		path: "/dashboard",
		title: "Dashboard",
		element: <LayoutContainer />,
		permission: [],
		children: [
			{
				index: true,
				element: <Dashboard />,
			},
		],
	}, 
];

export const MinimalHeaderRoute = [
	{
		path: "/view-all/:sectionId",
		element: <MinimalLayoutContainer />,
		children: [
			{
				index: true,
				element: <ViewAllGames />,
			},
		],
	},
	{
		path: "/leaderboards-custom-category/:gameIdUrl",
		element: <CustomCategoryGameDetailContainer />,
		children: [
			{
				index: true,
				element: <CustomCategoryLeaderboards />,
			},
		],
	},
	{
		path: "/leaderboards/:gameIdUrl",
		element: <MinimalLayoutContainer />,
		children: [
			{
				index: true,
				element: <Leaderboards />,
			},
		],
	},
	{
		path: "/mightlike",
		element: <MinimalLayoutContainer />,
		children: [
			{
				index: true,
				element: <GameMightlike />,
			},
		],
	},
	{
		path: "/dev/:devId",
		element: <MinimalLayoutContainer />,
		children: [
			{
				index: true,
				element: <MoreFromDev />,
			},
		],
	},
	{
		path: "/category-custom/:categoryId/:authTokenAssad/:language?",
		element: <CustomCategoryGameDetailContainer />,
		children: [
			{
				index: true,
				element: <CustomCategoryGamesListing />,
			},
		],
	},
	{
		path: "/category/:categoryid",
		element: <MinimalLayoutContainer />,
		children: [
			{
				index: true,
				element: <CategoryGamesListing />,
			},
		],
	},
	{
		path: "/category/:categoryid/games",
		element: <MinimalLayoutContainer />,
		children: [
			{
				index: true,
				element: <CategoryAllGames />,
			},
		],
	},
	{
		path: "/settings",
		element: <AuthContainer />,
		//permission: ['settings'],
		children: [
			{
				index: true,
				element: <Settings />,
			},
		],
	},
];

//OTHER COMMON LAYOUT
export const FullScreenLayoutRoute = [
	{
		path: "/login",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <Login />,
			},
		],
	},
	{
		path: "/play/:gameId",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <Play />,
			},
		],
	},
	{
		path: "*",
		element: <NotFound />,
	},
];
