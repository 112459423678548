import { call, put } from "redux-saga/effects";

import axios from "../../config/axiosConfig";
import { GET_USER_FOUND,LOGOUT_SUCCESS,GET_USER_NOT_FOUND,LOGOUT_ERROR,FETCH_BALANCE_SUCCESS,FETCH_BALANCE_FAILED, CONNECT_SUCCESS, CONNECT_FAILED, TELEGRAM_SIGNIN_FAILED, TELEGRAM_SIGNIN_SUCCESS } from "../actions/authActions";
import { TOAST_SHOW } from "../actions/toastActions";
import { removeCookie, setCookie } from "../../utils/functions";
import { requestAnalyticsEventAction } from "../actions/eventsActions";

export function* LoginSaga(action) { 
	try {
		const response = yield call(axios.post, `social_login`, action.payload);

		if (response?.status === 200) {
			localStorage.setItem("token", response?.data?.accessToken);
			const responseLogin = yield call(axios.get, `login`);
			if (responseLogin?.status === 200) {
				yield put({
					type: GET_USER_FOUND,
					payload: responseLogin?.data,
					token: response?.data?.accessToken
						? response?.data?.accessToken
						: `testLogin-${action?.payload?.loginType}`,
				});
				//yield put({ type: TOAST_SHOW,payload: { message: "Login Successfully!", severity: "Success",show: true} });

				/**Start: Set cookie code used to logout user after some specific time */
				const expirationTime = new Date();
			    //expirationTime.setTime(expirationTime.getTime() + (2 * 60 * 1000)); // 5 minutes in milliseconds 
				expirationTime.setTime(expirationTime.getTime() + (1 * 24 * 60 * 60 * 1000)); // 1 day in milliseconds 
				setCookie('sessionExists',true,expirationTime) //name,value,expirationtime
				/**End: Set cookie code used to logout user after some specific time */
				
				/*----------START: UTMS Analytics-------- */
				let userType = responseLogin?.data?.user?.type;
				if(userType==='Registered'){
					yield put(requestAnalyticsEventAction({ eventType:"request", event:"pageview", event_value:"User_Connect_New",  event_parent:"page", desc:"Fires whenever a user is created in the system for the first time" })); 
				}else{
					yield put(requestAnalyticsEventAction({ eventType:"request", event:"pageview", event_value:"User_Connect_Existing",  event_parent:"page", desc:"Fires whenever a user logs in the system NOT for the first time" })); 
				}
				/*----------END: UTMS Analytics-------- */
				yield put(requestAnalyticsEventAction({ eventType:"request", event:"pageview", event_value:"WalletConnected",  event_parent:"page", desc:"WalletConnected" }));

				yield put(requestAnalyticsEventAction(
					{ eventType:"request", event:"pageview", event_value:"Wallet_Connect_Confirmed_" + userType,  event_parent:"page", desc:"Analytics Page View" }
				)); 

				if(action?.payload?.loginType ==='discord'){
					window.location.replace('/')
				}
				
			}
		}
	} catch (e) {
		yield put({
			type: TOAST_SHOW,
			payload: { message: "Something Went wrong please try again", severity: "Danger", show: true},
		});
		yield put({ type: GET_USER_NOT_FOUND });
	}
}
 

export function* LogoutSaga(action) {
	try {
		const response = yield call(axios.get, "logout");

		if (response?.status === 200) {
			yield put({ type: LOGOUT_SUCCESS });
			/**Start: Remove cookie on logout */
			removeCookie('sessionExists');
			/**End: Remove cookie on logout */

			if(action?.successcallback){ //To disconnect web3login 
				let successcallback = action?.successcallback;
				successcallback();
			}
			//yield put({ type: TOAST_SHOW, payload: { message: "Logout Successfully!", severity: "Success", show: true } });
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} });
		yield put({ type: LOGOUT_ERROR });
	}
}

 
  

export function* fetchBalanceSaga(action) {  
	try {
		const response = yield call(axios.get, `/api/balance?t=${Date.now()}`); 
		if (response?.status === 200) { 
			yield put({ type:FETCH_BALANCE_SUCCESS, payload: response?.data?.balance }); 
		} 
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true, } });    
		yield put({ type: FETCH_BALANCE_FAILED });
	}
}

export function* ConnectSaga(action) {
	try { 
		const response = yield call(axios.post, `connect`, action.payload);  

		if (response?.status === 200) {
			localStorage.setItem("token", response?.data?.accessToken);
			yield put({
				type: CONNECT_SUCCESS,
				payload: response?.data,
				token: response?.data?.accessToken
					? response?.data?.accessToken
					: `testLogin-${action?.payload?.wallet_type}`,
			});

			/**Start: Set cookie code used to logout user after some specific time */
			const expirationTime = new Date();
			//expirationTime.setTime(expirationTime.getTime() + (2 * 60 * 1000)); // 5 minutes in milliseconds 
			expirationTime.setTime(expirationTime.getTime() + (1 * 24 * 60 * 60 * 1000)); // 1 day in milliseconds 
			setCookie('sessionExists',true,expirationTime) //name,value,expirationtime
			/**End: Set cookie code used to logout user after some specific time */
			yield put({ type: TOAST_SHOW,payload: { message: "Login Successfully!", severity: "Success",show: true} }); 

			if(action?.successCallback){
				let successCallbackHandle = action?.successCallback;
				successCallbackHandle();
			}
			 
			/*----------START: UTMS Analytics-------- */
			let userType = response?.data?.type; 
			if(userType==='Registered'){
				yield put(requestAnalyticsEventAction({ eventType:"request", event:"pageview", event_value:"User_Connect_New",  event_parent:"page", desc:"Fires whenever a user is created in the system for the first time" })); 
			}else{
				yield put(requestAnalyticsEventAction({ eventType:"request", event:"pageview", event_value:"User_Connect_Existing",  event_parent:"page", desc:"Fires whenever a user logs in the system NOT for the first time" })); 
			}
			/*----------END: UTMS Analytics-------- */
			yield put(requestAnalyticsEventAction({ eventType:"request", event:"pageview", event_value:"WalletConnected",  event_parent:"page", desc:"WalletConnected" })); 

			yield put(requestAnalyticsEventAction(
				{ eventType:"request", event:"pageview", event_value:"Wallet_Connect_Confirmed_" + userType,  event_parent:"page", desc:"Analytics Page View" }
			)); 
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} });
		yield put({ type: CONNECT_FAILED });
	}
}

export function* TelegramSignSaga(action) {
	try { 
		const response = yield call(axios.post, `tg_login`,action.payload);  
		if (response?.status === 200) {
			localStorage.setItem("token", response?.data?.accessToken);  
			const responseLogin = yield call(axios.get, `login`);
			if (responseLogin?.status === 200) {
				yield put({
					type: TELEGRAM_SIGNIN_SUCCESS,
					payload: responseLogin?.data,
					token: response?.data?.accessToken ,
				});
 
				/**Start: Set cookie code used to logout user after some specific time */
				const expirationTime = new Date();
			    //expirationTime.setTime(expirationTime.getTime() + (2 * 60 * 1000)); // 5 minutes in milliseconds 
				expirationTime.setTime(expirationTime.getTime() + (1 * 24 * 60 * 60 * 1000)); // 1 day in milliseconds 
				setCookie('sessionExists',true,expirationTime) //name,value,expirationtime
				/**End: Set cookie code used to logout user after some specific time */
				
				/*----------START: UTMS Analytics-------- */
				let userType = responseLogin?.data?.user?.type;
				if(userType==='Registered'){
					yield put(requestAnalyticsEventAction({ eventType:"request", event:"pageview", event_value:"User_Connect_New",  event_parent:"page", desc:"Fires whenever a user is created in the system for the first time" })); 
				}else{
					yield put(requestAnalyticsEventAction({ eventType:"request", event:"pageview", event_value:"User_Connect_Existing",  event_parent:"page", desc:"Fires whenever a user logs in the system NOT for the first time" })); 
				}
				/*----------END: UTMS Analytics-------- */ 
			}
		}
	} catch (e) {
		console.log(`369---->err telegram`,e)
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} });
		yield put({ type: TELEGRAM_SIGNIN_FAILED });
	}
}
 