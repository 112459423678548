import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosConfig";
//import { TOAST_SHOW } from "../actions/toastActions";

import { ANALYTICS_EVENT_FAILED,ANALYTICS_EVENT_SUCCESS } from "../actions/eventsActions"; 
 
import Analytics from "../../utils/analytics";

export function* triggerAnalyticsEventsSaga({ payload, successcallback,failcallback }) { 
	//console.log(payload);
	if (payload.event_value) {
		Analytics.trackPage(payload.event_value);
	}

	const url = `/api/events`; 
	try {
	  const { data, status } = yield call(axios.post, url, payload);
	  if (status === 200) {
		yield put({ type: ANALYTICS_EVENT_SUCCESS, payload: data});
		if (successcallback) successcallback();
	  }
	} catch {
	  //yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true, }});
	  yield put({ type: ANALYTICS_EVENT_FAILED });
	  if (failcallback) failcallback(); 
	}
}