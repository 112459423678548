export const HOMEPAGE_DATA_REQUEST = "HOMEPAGE_DATA_REQUEST";
export const HOMEPAGE_DATA_SUCCESS = "HOMEPAGE_DATA_SUCCESS";
export const HOMEPAGE_DATA_ERROR = "HOMEPAGE_DATA_ERROR";
export const HOMEPAGE_DATA_RESET = "HOMEPAGE_DATA_RESET";


export const GET_SECTION_GAMES_REQUEST = "GET_SECTION_GAMES_REQUEST";
export const GET_SECTION_GAMES_SUCCESS = "GET_SECTION_GAMES_SUCCESS";
export const GET_SECTION_GAMES_ERROR = "GET_SECTION_GAMES_ERROR";

export const ALL_CATEGORY_DATA_LOADED = "ALL_CATEGORY_DATA_LOADED"; //Used to restrict Categorywise API call multiple time

export const LoadHomDataAction = () => {
	return { type: HOMEPAGE_DATA_REQUEST };
};

export const GetSectionGamesAction = (data) => {
	return { type: GET_SECTION_GAMES_REQUEST, payload: data };
};

// updateFlagAllcategoryLoaded --- Used to restrict Categorywise API call multiple time
export const updateFlagAllcategoryLoaded = () => {
	return { type: ALL_CATEGORY_DATA_LOADED };
};


export const resetHomeDataAction = () => {
	return { type: HOMEPAGE_DATA_RESET };
}; 