import axios from "axios";

const apiBaseUrl =`${import.meta.env.VITE_APP_ASSAD_BASE_API_URL}`;

const withAuthInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

withAuthInstance.interceptors.request.use(
  (config) => {
    const token =
    localStorage.getItem("token") 

    if (token) {
      //config.headers.Authorization = token;
      config.headers["x-access-token"] = token;
    } else {
      //localStorage.clear(); // use this if needed. this will remove all items from localstorage
      delete withAuthInstance.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => console.error(error)
);

export default withAuthInstance;
