import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosConfig";

import { PAYMENT_CHECKOUT_SESSION_FAILED, PAYMENT_CHECKOUT_SESSION_SUCCESS, RECENTLY_PLAYED_ERROR, RECENTLY_PLAYED_SUCCESS, STRIPE_PAYMENT_CONFIRM_FAILED, STRIPE_PAYMENT_CONFIRM_SUCCESS, UPDATE_USER_ERROR, UPDATE_USER_SUCCESS, vAKEDO_PRODUCTS_LIST_FAILED, vAKEDO_PRODUCTS_LIST_SUCCESS } from "../actions/settingsActions";
import { TOAST_SHOW } from "../actions/toastActions";

export function* UpdateUserSaga(action) {
	try {
		const response = yield call( axios.post, `api/update_user`, action.payload);

		if (response?.status === 200) {
			if (action?.callbackFun) {
				let callBackFunction = action?.callbackFun;
				callBackFunction(response?.data);
			}
			yield put({ type: UPDATE_USER_SUCCESS });
			yield put({ type: TOAST_SHOW, payload: { message: "User Updated Successfully!", severity: "Success", show: true }});
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again",severity: "Danger",show: true }});
		yield put({ type: UPDATE_USER_ERROR });
	}
}

export function* fetchRecentlyPlayedSaga(action) {
	try {
		const response = yield call(axios.get, `api/recently_played`);

		if (response?.status === 200) {
			let gameData = response?.data?.items;
			if (gameData && gameData.length <= 0) {
				gameData = [];
			}
			response.data.items = gameData;

			yield put({
				type: RECENTLY_PLAYED_SUCCESS,
				payload: response?.data,
			});
		}
	} catch (e) {
		yield put({
			type: TOAST_SHOW,
			payload: {
				message: "Something Went wrong please try again",
				severity: "Danger",
				show: true,
			},
		});
		yield put({ type: RECENTLY_PLAYED_ERROR });
	}
}

export function* fetchVAkedoProductsListSaga(action) {
	try {
		const response = yield call( axios.get, `purchase/vakedo_products_list?t=${Date.now()}`);
		if (response?.status === 200) { 
			yield put({ type: vAKEDO_PRODUCTS_LIST_SUCCESS,payload: response?.data }); 
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true}});
		yield put({ type: vAKEDO_PRODUCTS_LIST_FAILED });
	}
}

export function* createCheckoutSessionSaga(action) {
	let callbackFun;
	if(action?.callbackFun){
		callbackFun = action?.callbackFun; 
	}
	try {
 		const response = yield call(axios.post,`stripe/create-checkout-session`,action.payload);

		if (response?.status === 200) { 
			yield put({ type: PAYMENT_CHECKOUT_SESSION_SUCCESS,payload: response?.data });  
			callbackFun(true,response); 
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true}});
		yield put({ type: PAYMENT_CHECKOUT_SESSION_FAILED }); 
		callbackFun(false,e); 
	}
}

export function* stripeCallbackSaga(action) {
	try {
 		const response = yield call(axios.post,`stripe/create-checkout-session`,action.payload);

		if (response?.status === 200) { 
			yield put({ type: STRIPE_PAYMENT_CONFIRM_SUCCESS,payload: response?.data });  
 		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true}});
		yield put({ type: STRIPE_PAYMENT_CONFIRM_FAILED }); 
 	}
}