import React from 'react'
import { Outlet } from "react-router-dom";

const FullscreenContainer = () => { 
  return ( 
    <div className="blank_layout_wrapper">
        <Outlet />
    </div> 
  )
}

export default FullscreenContainer