import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; 
import { Outlet } from "react-router-dom"; 
import { clearMessageAction, getPopupMessageAction } from "../redux/actions/assad_arcade/AssadMessageActions";
import { useDispatch, useSelector } from "react-redux";
import CustomCategoryPopup from "../components/CustomCategory/CustomCategoryPopup";

const CustomCategoryGameDetailContainer = () => {
	const navigate = useNavigate(); 
	const dispatch = useDispatch();


	const [showPopup, setShowPopup] = useState(false);
	const [messageData, setMessageData] = useState({});

	const MessageState = useSelector((state) => state.AssadMessage);
	const { message } = MessageState;

	const { authTokenAssad } = useParams();

	 

	if(!localStorage.getItem("token") && authTokenAssad){ 
		localStorage.setItem("token",authTokenAssad); 
		localStorage.setItem("isFromCustomCategory",true);
	}

	// const goBack=()=>{
    //     navigate(-1);
    // }

	const onModalClose = () => {
		dispatch(clearMessageAction());
		setShowPopup(false);
		setMessageData({});
	}

	useEffect(() => {
		if (message && message.length > 0) {
			setMessageData(message[0]);
			setShowPopup(true);
		} else {
			setMessageData({});
			setShowPopup(false);
		}
	}, [message]);

	return (
		<>
			<div className="layout_wrapper gameDetailContainer customCategory">  
				<div className="panel_wrp">
					<Outlet />
				</div>

				{/* Pop Up For Custom Category */}
				{
					showPopup && (
						<CustomCategoryPopup
							isModalOpen={showPopup}
							closeModal={onModalClose}
							modalData={messageData}
						/>
					)
				}
			</div>
		</>
	);
};

export default CustomCategoryGameDetailContainer;