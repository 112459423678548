export const GET_POPUP_MESSAGE_REQUEST ='GET_POPUP_MESSAGE_REQUEST';
export const GET_POPUP_MESSAGE_SUCCESS ='GET_POPUP_MESSAGE_SUCCESS';
export const GET_POPUP_MESSAGE_FAILED ='GET_POPUP_MESSAGE_FAILED';

export const CLEAR_POPUP_MESSAGE_REQUEST ='CLEAR_POPUP_MESSAGE_REQUEST';


export const MESSAGE_READ_REQUEST ='MESSAGE_READ_REQUEST';
export const MESSAGE_READ_SUCCESS ='MESSAGE_READ_SUCCESS';
export const MESSAGE_READ_FAILED ='MESSAGE_READ_FAILED';

export const ADD_ASSAD_COIN_REQUEST = "ADD_ASSAD_COIN_REQUEST";
 

export const getPopupMessageAction = () => {
	return { type: GET_POPUP_MESSAGE_REQUEST };
};

export const clearMessageAction = () => {
	return { type: CLEAR_POPUP_MESSAGE_REQUEST };
};
 
export const readMessageAction = (data,successcallback) => {
	return { type: MESSAGE_READ_REQUEST, payload: data,successcallback: successcallback };
};