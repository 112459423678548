import React, { useRef, useEffect } from "react";
import { ControlBar, Player, Shortcut } from "video-react";
import "video-react/dist/video-react.css";

const GameDetailVideoPlayer = ({ url }) => {
    const playerRef = useRef(null);

    useEffect(() => {
        const videoElement = playerRef.current?.video.video;
        if (videoElement) {
            // Apply object-fit cover to ensure it fills the container and crops as needed
            videoElement.style.objectFit = "cover";
            videoElement.style.width = "110%";
            videoElement.style.height = "110%";
        }
    }, []);

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '110%',
                height: '110%',
                overflow: 'hidden',
                backgroundColor: 'black', // Optional fallback color
            }}
        >
            <Player
                ref={playerRef}
                autoPlay
                muted
                loop
                src={url}
                fluid={true} // Prevents responsive resizing; use fixed size with full screen
                playsInline
                preload="auto"
                style={{
                    width: '110%',
                    height: '110%',
                }}
            >
                <ControlBar disableCompletely={true} />
                <Shortcut clickable={false} dblclickable={false} />
            </Player>
        </div>
    );
};

export default React.memo(GameDetailVideoPlayer);
