import { GET_ALL_USER_MESSAGES_ERROR, GET_ALL_USER_MESSAGES_REQUEST, GET_ALL_USER_MESSAGES_SUCCESS, GET_USER_MESSAGES_ERROR, GET_USER_MESSAGES_REQUEST, GET_USER_MESSAGES_SUCCESS, SET_USER_MESSAGE_ERROR, SET_USER_MESSAGE_REQUEST, SET_USER_MESSAGE_SUCCESS, USER_MESSAGE_READ_ERROR, USER_MESSAGE_READ_REQUEST, USER_MESSAGE_READ_SUCCESS } from "../actions/messageActions";

const initialState = {
	loading: false,
	userMessage: {},
	userMessagesList: [],
	allUserMessagesList: [],
	showMessagePopup: false,
	popupMessage: null
}

export const MessageReducer = (state=initialState,action) =>{
	switch(action.type){
		case SET_USER_MESSAGE_REQUEST:
			return {
				...state,
				loading: true
			};
		case SET_USER_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				userMessage: action?.payload
			}
		case SET_USER_MESSAGE_ERROR:
			return {
				...state,
				loading: false
			}
		case USER_MESSAGE_READ_REQUEST:
			return {
				...state,
				loading: true
			}
		case USER_MESSAGE_READ_SUCCESS:
			return {
				...state,
				loading: false,
				showMessagePopup:false,
				popupMessage:null,
			}
		case USER_MESSAGE_READ_ERROR:
			return {
				...state,
				showMessagePopup:false,
				loading: false
			}
		case GET_USER_MESSAGES_REQUEST:
			return {
				...state,
				loading:true
			}
		case GET_USER_MESSAGES_SUCCESS: 
			let messages = action?.payload;
			let popuMsg=null;
			let showMessagePopup = false;
			if(messages && messages.length > 0){
				popuMsg = messages[0];
				showMessagePopup = true;
			}
			return {
				...state,
				loading: false,
				userMessagesList: action?.payload,
				showMessagePopup:showMessagePopup,
				popupMessage:popuMsg,
			}
		case GET_USER_MESSAGES_ERROR:
			return {
				...state,
				loading: false
			}
		case GET_ALL_USER_MESSAGES_REQUEST:
			return {
				...state,
				loading: true	
			}
		case GET_ALL_USER_MESSAGES_SUCCESS:
			return {
				...state,
				loading: false,
				allUserMessagesList: action?.payload
			}
		case GET_ALL_USER_MESSAGES_ERROR:
			return {
				...state,
				loading: false
			} 
		default:
			return state; 
	}
}

