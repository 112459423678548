import React  from "react"; 
import { Modal } from "react-bootstrap"; 
import { IoIosClose } from "react-icons/io"; 
 
const PaymentSuccessPopup = ({onPopupClose,...props}) => {     
	
	return (
		<>
			<Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered className="messageModel" >
				<div>
					<Modal.Body>
						<div>
							<button className="closeMessageModel" onClick={()=>onPopupClose()}> <IoIosClose /> </button> 
							<h1>{`Payment successfully done `}</h1> 	 
						</div>
					</Modal.Body>
				</div>
			</Modal>
		</>
	);
};

export default PaymentSuccessPopup;
