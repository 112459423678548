import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BsArrowDown } from "react-icons/bs";

import gameImg1 from "../../../assets/images/games/samplegameImg.png";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import participationIcon from "../../../assets/images/logo_vector.png";

const StarRating = loadable(() => import("../../../components/UI/StarRating"));

import {
	getPlayCountText,
} from "../../../utils/functions";
import { checkGameParticipationAction } from "../../../redux/actions/gameActions";
import GameDetailVideoPlayer from "../../../components/videoPlayer";
import { requestAnalyticsEventAction } from "../../../redux/actions/eventsActions";
import loadable from "@loadable/component";

const GameBanner = ({
	isLoggedIn = false,
	featuredUrl,
	gameData,
	screenshots,
	hostUrl,
	averageRatingStar,
	handleParticipationReject,
}) => {
	const [isDiplayScreenshot, setIsDiplayScreenshot] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const checkLoginNrediect = () => {
		if (isLoggedIn) {
			dispatch(
				requestAnalyticsEventAction({
					eventType: "request",
					event: "pageview",
					event_value: `Game_Play_GPage_${gameData?.name}`,
					event_parent: "page",
					desc: "Analytics Page View",
				})
			);
			const callbackFun = () => {
				navigate(`/play/${gameData?.id}`);
			};
			dispatch(
				checkGameParticipationAction(
					{ app_id: gameData?.id },
					callbackFun,
					handleParticipationReject
				)
			);
		} else {
			dispatch(
				requestAnalyticsEventAction({
					eventType: "request",
					event: "pageview",
					event_value: `Connect_Click_GPage_${gameData?.name}`,
					event_parent: "page",
					desc: "Analytics Page View",
				})
			);
			localStorage.setItem("lastPlayReqGameId", gameData?.id);
			navigate(`/login`);
		}
	};

	const updateBannerScreenShot = (index) => {
		setIsDiplayScreenshot(true);
		setActiveIndex(index + 2);
	};

	//console.log(gameData);

	return (
		<main>
			<div className="relative w-full h-screen overflow-hidden">
				{isDiplayScreenshot && screenshots && screenshots.length > 0 ? (
					<div className="flex flex-row justify-center items-center">
						<img
							src={
								screenshots[activeIndex - 2]
									? `${hostUrl}${screenshots[activeIndex - 2]}`
									: gameImg1
							}
							alt="gameIcon"
							title=""
							className="absolute top-0 w-full h-full object-cover"
						/>
					</div>
				) : (
					<GameDetailVideoPlayer url={featuredUrl} />
				)}

				<div className="gardiant-gamepagebanner" />

				{/* Updated with padding for spacing */}
				<div className="gamePagwbanner-overlay md:flex block justify-between absolute w-full bottom-0 p-6 md:p-20">
					<div className="left-text flex-row max-w-[450px] w-full relative">
						<div className="icon-title flex py-1 items-center gap-2">
							<img
								src={gameData?.icon ? `${hostUrl}${gameData?.icon}` : gameImg1}
								alt="gameIcon"
								className="h-[100%] w-[100%]"
								height={120}
								width={150}
							/>
							<h1 className="text-[42px]"> {gameData?.name} </h1>
						</div>
						<div className="gamePage-ratingrow flex py-1 text-white items-center text-[large]">
							<h2 className="flex pl-[5px] whitespace-nowrap">
								<strong className="pr-1">
									{getPlayCountText(gameData?.play_count)}{" "}									
								</strong>
								DOWNLOADS |{" "}
								<span className="ratingstar ml-1 mr-2 startRating">
									<StarRating size={`text-xl`} ratings={averageRatingStar} />
								</span>
								Rating
								<span className="ratingcounter ml-2">
									{averageRatingStar}
									<span> / 5</span>
								</span>
							</h2>
						</div>
						<div className="flex py-1 text-white items-center text-sm -mt-2">
							<div className="devname">
								<strong>Developer:</strong> {gameData?.developer}
							</div>
						</div>
					</div>
					<div className="right-text rounded-[15px] flex-column relative py-2">
						<button
							className="bg-[#00e6f4] w-[280px] py-[8px] px-[15px] flex flex-col items-center rounded-[20px] cursor-pointer"
							onClick={checkLoginNrediect}
						>
							<div className="flex flex-row items-center gap-5">
								<span className="flex flex-row items-center uppercase">
									Play
									<FeatherIcon
										icon="triangle"
										className="rotate-90 fill-[#000] ml-1 mr-0 mb-0"
										size={10}
									/>
								</span>
								{gameData?.user?.id != '' && gameData?.participation !== 0 && (
									<span
										className="gameParticipationSection flex items-center text-[11px] bg-[#ffff] text-[#000] rounded-[20px] gap-2"
										style={{ padding: "6px 12px" }}
									>
										<img
											src={participationIcon}
											alt="gameIcon"
											width={20}
											height={20}
											style={{ borderRadius: "50%" }}
										/>
										{gameData?.participation}
									</span>
								)}
							</div>
						</button>

						<div className="flex justify-end w-full mt-4">
							<ul className="slider-img flex gap-2 w-full max-w-[280px]">
								<li
									className={`${activeIndex === 0 ? `active` : ``} flex-1`}
									onClick={() => {
										setIsDiplayScreenshot(false);
										setActiveIndex(0);
									}}
								>
									<img
										src={gameData?.image ? `${hostUrl}${gameData?.image}` : gameImg1}
										alt="game-icon"
										className="w-full h-full object-cover rounded-md"
									/>
								</li>
								{screenshots &&
									screenshots.length > 0 &&
									screenshots.map((ss, i) => {
										return (
											<li
												onClick={() => updateBannerScreenShot(i)}
												className={`${activeIndex === i + 2 ? `active` : ``} flex-1`}
												key={`screenshot_${i}`}
											>
												<img
													src={ss ? `${hostUrl}${ss}` : gameImg1}
													alt="game-screenshot"
													className="w-full h-full object-cover rounded-md"
												/>
											</li>
										);
									})}
							</ul>
						</div>

					</div>

					<div className="absolute text-[#00e6f4] -translate-y-2/4 left-2/4 bottom-[10px] z-[1]">
						<BsArrowDown style={{ color: "#00e6f4" }} size={30} />
					</div>
					
				</div>
			</div>
		</main>
	);
};

export default GameBanner;
