import React from "react";
//import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import loadable from '@loadable/component'

import gameImg1 from "../../assets/images/games/samplegameImg.png";
import { getCloudFrontSourcePath } from "../../utils/functions";

const GameCard = loadable(() => import('../../components/UI/GameCard'))

const CategoryAllGames = () => {
	const CategoryState = useSelector((state) => state.Category);
	//const { categoryid } = useParams();
	const { categoryViewDetails } = CategoryState;

	let gameData = categoryViewDetails?.apps ? categoryViewDetails?.apps : [];

	let cloudFrontSourcePath = getCloudFrontSourcePath();

	return (
		<section className="viewAllGame px-0 py-[30px]">
			<div className="container">
				<div className="text-center">
					<h2 className="text-[#fff] text-[28px] tracking-widest mb-4">
						{categoryViewDetails?.name}
					</h2>
				</div>
				<div className="gameCards">
					{gameData.map((game, index) => <GameCard keyIndex={index} img={ game?.icon ? `${cloudFrontSourcePath}${game?.icon}` : gameImg1 } game={game} key={`category_allgame_${index}`} subtitle={categoryViewDetails?.name} /> )}
				</div>
			</div>
		</section>
	);
};

export default React.memo(CategoryAllGames);