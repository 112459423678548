export const SET_USER_MESSAGE_REQUEST = 'SET_USER_MESSAGE_REQUEST';
export const SET_USER_MESSAGE_SUCCESS = 'SET_USER_MESSAGE_SUCCESS';
export const SET_USER_MESSAGE_ERROR = 'SET_USER_MESSAGE_ERROR';

export const USER_MESSAGE_READ_REQUEST = 'USER_MESSAGE_READ_REQUEST';
export const USER_MESSAGE_READ_SUCCESS = 'USER_MESSAGE_READ_SUCCESS';
export const USER_MESSAGE_READ_ERROR = 'USER_MESSAGE_READ_ERROR'; 

export const GET_USER_MESSAGES_REQUEST = 'GET_USER_MESSAGES_REQUEST';
export const GET_USER_MESSAGES_SUCCESS = 'GET_USER_MESSAGES_SUCCESS';
export const GET_USER_MESSAGES_ERROR = 'GET_USER_MESSAGES_ERROR';

export const GET_ALL_USER_MESSAGES_REQUEST = 'GET_ALL_USER_MESSAGES_REQUEST';
export const GET_ALL_USER_MESSAGES_SUCCESS = 'GET_ALL_USER_MESSAGES_SUCCESS';
export const GET_ALL_USER_MESSAGES_ERROR = 'GET_ALL_USER_MESSAGES_ERROR';

 

export const setUserMessage = (data)=>{
    return {type:SET_USER_MESSAGE_REQUEST,payload:data}
}

export const setUserMessageRead = (data)=>{
    return {type:USER_MESSAGE_READ_REQUEST,payload:data}
}

export const getUserMessages = (data={}) =>{
    return { type:GET_USER_MESSAGES_REQUEST,payload:data }
}

export const getAllUserMessages = (data={}) =>{
    return { type:GET_ALL_USER_MESSAGES_REQUEST,payload:data } 
}