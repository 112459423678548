import { SDK_GET_GAME_TOKEN_REQUEST,SDK_GET_GAME_TOKEN_SUCCESS,SDK_GET_GAME_TOKEN_FAILED, SDK_INIT_JS_FAILED,SDK_INIT_JS_SUCCESS,SDK_INIT_JS_REQUEST } from "../actions/gameSdkActions";

const initialState = {
	loading: false,
	token:null,
	gameId:'',
	initJsResponse:{},
};

export const GameSdkReducer = (state = initialState, action) => { 
	switch (action.type) {
		case SDK_GET_GAME_TOKEN_REQUEST: 
			return {
				...state,
				loading: true 
			};
		case SDK_GET_GAME_TOKEN_SUCCESS:   
			return {
				...state,
				loading: false, 
				gameId: action?.gameId,
				token: action?.payload
			};
		case SDK_GET_GAME_TOKEN_FAILED:
			return {
				...state,
				loading: false, 
			};
		case SDK_INIT_JS_REQUEST: 
			return {
				...state,
				loading: true,
				initJsResponse:{}
			};
		case SDK_INIT_JS_SUCCESS:   
			return {
				...state,
				loading: false, 
				initJsResponse:action?.payload
			};
		case SDK_INIT_JS_FAILED:
			return {
				...state,
				loading: false, 
			};
		default:
			return state;
	}
};
